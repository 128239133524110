const getTheme = () => {
    const theme = localStorage.getItem("ein_theme") || {
        name: "default",
        rootClassName: "default"
    }

    // console.log(theme);
    return theme;
};

export default getTheme;
