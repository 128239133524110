import { memo, useEffect } from "react";
import { cl, getRandomInt } from "../../../../shed";
import BuiSelect from "../../../../ui/bui/select/Select";


export const Name = memo(({ options, value, handleChange, first, second }) => {

    // console.log("First: value:", value);
    // console.log("------- NAME --------")
    // const randomValue = useRef(!value && options[getRandomInt(0, options.length-1)].value);

    // const NAME = `${first?"FIRST ":second?"SECOND ":""}NAME:`;

    /* useEffect(() => {
        console.log(NAME, value, "\n   with options:", options.map(o => o.value));
    }); */

    useEffect(() => {
        if (!value || !options.some(o => o.value === value)) {
            // console.log(`${NAME} set random value from options:`, options.map(o=>o.value));
            handleChange(
                options[getRandomInt(0, options.length-1)].value
            );
        }
    }, [handleChange, value, options]);

    if (!options.some(o => o.value === value)) {
        // console.log(`${NAME} options doesn't contain value`, value);
    }

    if (!value || !options.some(o => o.value === value)) return null;

    return (<>
        <BuiSelect className={cl({ first, second })} data={{ list: options, selected: value }} {...{ handleChange }} />
        </>
    )
});
