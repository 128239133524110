import { useCallback, useEffect, useMemo, useState } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { getJSON, getKey, getTable } from "../api";
import { Sometime } from "../features/common/Sometime";
import { Counter, Reaction } from "../features/foam/Like";
import { useDescriptors } from "../features/foam/useBubbles";
import { useUser } from "../features/common/users";
import { Scribble } from "../features/scribbles/Scribble";
import { times } from "../lang";
import { convertDatesToISOStrings, entities2unicode, filterObject, filterTree, findDescendants, objType, say, str2val } from "../shed";
import { useS } from "../store/store";
import Radio, { BlobRadio } from "../ui/Radio";
import Spinner from "../ui/Spinner";
import { fetchTextFromHTMLFile } from "./fetchHTML";
import { getAccessKey } from "./session";
import PopupSelect from "../ui/bui/select/PopupSelect";

import "./tests.scss";

const TestHTMLFetch = () => {
    const [res, set] = useState();

    useEffect(() => {
        const file = {
            path: "/html/authors/romochka/test",
            name: "fil-poe.html",
            preserve: ["title", "date"],
        };
        fetchTextFromHTMLFile(file).then((res) => {
            let { date } = res;
            if (date && /^\d\d[^\d]\d\d[^\d]\d\d\d\d$/.test(date)) {
                date = date.replace(
                    /^(\d\d)[^\d](\d\d)[^\d](\d\d\d\d)$/,
                    "$3-$2-$1"
                );
            }
            set(date ? { ...res, date } : res);
        });
    }, []);

    if (!res) return <div>...</div>;

    return <div>{JSON.stringify(res, null, 4)}</div>;
};

const Testkey = () => {
    useEffect(() => {
        const access_name = sessionStorage.getItem("access_name");
        const access_key = sessionStorage.getItem("access_key");
        const session_id = sessionStorage.getItem("session_id");

        getKey({
            session_id,
            auth: true,
            access_name,
            access_key,
        })
            .then((response) => {
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return <div>key</div>;
};

const Testradio = () => {
    const options = [
        { value: 1, checked: false, label: "viens" },
        { value: 2, checked: false, label: "divi" },
        { value: 3, checked: true, label: "trīs" },
        { value: 4, checked: false, label: "četri" },
    ];

    const onChange = (value) => {
        console.log(value);
    };

    return (
        <div style={{ padding: "40px" }}>
            <BlobRadio {...{ options, onChange }} />
        </div>
    );
};

const Tests0 = () => {
    const c = (val) => {
        const obj = str2val(val);
        console.log("value:", obj, "\ntype:", objType(obj));
        return obj;
    };

    c();
    c("aaa");
    c("77.77");
    c("[\"hello\", true, \"77\"]");
    c("{\"id\": \"45943859034\", \"n\": 809 }");

    const t = new Date().toISOString();

    console.log(t);

    c(t);

    console.log(new Date(c(t)));

    return <div>......</div>;
};

const countById = (bubbles) => {

    return bubbles.reduce((obj, bubble) => ({
        ...obj,
        [bubble.bubble_id]: obj[bubble.bubble_id]
        ? obj[bubble.bubble_id] + 1
        : 1
    }), {});

};

const QTest = () => {

    const bubbles = useQuery("foam",
        () => getTable({
            table: "public_foam",
            access_key: getAccessKey()
        })
    );

    const labels = useQuery("labels",
        () => getJSON("/json/labels.json")
        .then(r=>Object.entries(r.ru.bubbles).reduce((obj, [key, value]) => ({
            ...obj, [key]: say(value)
        }), {}))
    );

    const descriptors = useDescriptors({});

    const status = [bubbles, labels, descriptors].find(f=>f.status!=="success")?.status||"success";

    console.log(status);

    if (status !== "success") return (
        <div>loading...</div>
    );

    const types = {
        irrelevant: "нерелевантный",
        approve: "одобрительный",
        disapprove: "неодобрительный",
        offence: "экстремистский"
    };

    console.log(bubbles.data);

    console.log(descriptors.data);

    console.log(labels.data);

    const genericBubbles = bubbles.data
    .filter(bubble => descriptors.data.find(d => d.id===bubble.bubble_id));

    console.log(genericBubbles);

    const ids = countById(genericBubbles);

    console.log(ids);

    const stats = Object.entries(ids).map(([id, count]) => {
        const descriptor = descriptors.data.find(d => d.id===id);
        return {
            name: descriptor.name,
            count,
            type: types[descriptor.type],
        }
    }).sort((a, b) => b.count - a.count);

    console.log(stats);

    return (
        <div>
            <h1>Топ-10 оставленных комментариев</h1>
            <table><tbody>
                <tr>
                    <th>Комментарий</th>
                    <th>тип</th>
                    <th></th> 
                </tr>
                {stats.slice(0, 10).map((entry, i) => (
                    <tr key={i}>
                        <td>{entities2unicode(entry.name.ru)}</td>
                        <td>{entry.type}</td>
                        <td>{entry.count}</td>
                    </tr>
                ))}
            
        </tbody></table></div>
    )
};

const QTests = () => {

    const qc = new QueryClient();

    return (
        <QueryClientProvider client={qc}>
            <QTest />
        </QueryClientProvider>
    )
}



const TestReactions = () => {

    const user_id = localStorage.getItem("ein_user");

    // const session = user_id && sessionStorage.getItem(user_id);

    const removeUser = () => { localStorage.removeItem("ein_user") };
    const removeSession = () => { if (user_id) sessionStorage.removeItem(user_id) };

    return (
        <div>
            <div>Something you may like</div>
            <div className="reactions">
                <Reaction {...{ type: "like", object_id: "6771031c-f177-4f31-859a-bcceb23e0aa5", object_desc: "just testing" }} />
                <Counter {...{ type: "like", object_id: "6771031c-f177-4f31-859a-bcceb23e0aa5" }} />
                <Reaction {...{ type: "dislike", object_id: "6771031c-f177-4f31-859a-bcceb23e0aa5", object_desc: "just testing" }} />
                <Counter {...{ type: "dislike", object_id: "6771031c-f177-4f31-859a-bcceb23e0aa5" }} />
            </div>
            <button onClick={removeUser}>Clear user cookie</button><br />
            <button onClick={removeSession}>Clear session cookie</button>
        </div>
    )
};

const Tests99 = () => {

    const y = [
        { a: new Date() },
        { b: [new Date(), new Date(), { x: new Date() }] },
    ]

    console.log("dates:", y);

    const x = convertDatesToISOStrings(y);

    console.log("converted:", x);

    return (
        <div>test</div>
    );
};

const Tests98 = () => {
    return (
        <Sometime chance={2 / 3}>
            <Scribble name="boring-song" />
        </Sometime>
    )
}


const Tests77 = () => {


    const obj0 = {
        x: 7,
        test: "zzz",
        a: {
            z: 1, x: 7,
            aa: { x: 7 },
            bb: {
                x: 8,
                eee: { x: 7, etest: { some: "chuchu" } }
            }
        },
        b: [{ x: 1, element: { e: "a", subelement: { z: "u", subsub: { t: -8, x: 7 } } } }, { x: 7, element: { x: 1, value: "www" } }, { x: 9, sub: { x: 7, test: "aaa" } }]
    }

    const obj = {
        entity: "x",
        values: [1, 2, 3],
        entities: [
            {
                entity: "y",
                values: [2, 2, 0],
                entities: [
                    { entity: "z", values: [3, 5, 7] },
                    { entity: "x", values: [5, 8, 9] }
                 ]
            }
        ]
    };

    const test = filterTree(obj, "entities", obj=>["y", "z"].includes(obj.entity));

    console.log("-------------------------");
    console.log(test);

    return (
        <div>tests</div>
    )
}


const Tests78 = () => {

    const obj = [
        {
            a: "one",
            b: {
                a: "two",
                b: {
                    a: "three",
                    b: [{ a: "four" }, { a: "five", b: { a: "six" }}, { a: "seven" }]
                }
            }
        },
        {
            a: "John",
            b: [{ a: "Carl" }, { a: "Friedrich", b: { a: "Jeronimo" } }]
        }
    ];

    console.log(findDescendants(obj, "b", "a"));

    return (
        <div>tests</div>
    )
};

const list = [
    { value: "govno", label: "💩" },
    { value: "blyat", label: "💃" },
    { value: "bueee", label: "🤮" },
];

const Tests = () => {

    const [selected, set] = useState("govno");

    const data = useMemo(() => ({
        selected,
        list
    }), [selected]);

    const handleChange = (value) => {
        console.log(value);
        set(value);
    }

    return (
        <div className="tests" style={{ marginTop: "20em", marginBottom: "100em" }}>
            ну допустим, предположим<br/>
            что это за <PopupSelect {...{ data, handleChange, size: 4 }} /> такое<br />
            и откуда оно тут взялось, интересно
        </div>
    )
}

export default Tests;
