import { cl } from "../../shed";
import "./reactions.scss";

const reactions = {
    zhopa: {
        contour: (
            <g className="contour">
                <path d="M14.429 5.945s8.173.627 8.701 8.103c.277 3.933-2.275 8.72-9.21 8.393-6.184-.292-9.18-6.866-9.18-6.866"></path>
                <path d="M22.696 4.13c2.703.414 5.768 2.632 6.593 6.102 1.085 4.56-1.497 8.214-4.017 9.454-2.337 1.15-4.851 1.015-4.851 1.015M6.289 9.252C3.729 9.082.6 11.147 1.509 15c.348 1.474 1.18 3.421 2.587 5.266 1.262 1.655 2.114 2.512 4.054 4.18 0 0-2.946.544-3.118 1.909-.098.777.698 1.25 1.57 1.25 0 0 1.176.401 3.307 1.468 1.291.646 2.643 1.815 5.33.812 1.834-.685 1.88-2.807.072-4.219-1.326-1.036-2.73-3.393-2.73-3.393M16.417 22.305c.007.402.029 1.291 1.7 1.901 1.065.388 1.776.83 3.236 1.712 1.128.682 1.958 1.748 4.411.832 1.673-.625 1.981-2.355.381-3.705-.476-.401-.9-1.716-.963-2.69M4.811 3.542S4.449 9.148 9.77 13.587M19.678 4.956s-.977 1.057-.521 2.475M22.335 1.519s.888 2.018 2.535 3.304"></path>
            </g>
        ),
        fill: (
            <g>
                <path
                    fill="url(#_Radial5)"
                    d="M4.078 20.266c1.262 1.655 2.114 2.512 4.054 4.18 0 0-2.946.544-3.118 1.909-.098.777.698 1.25 1.57 1.25 0 0 1.175.401 3.307 1.468 1.291.646 2.643 1.815 5.33.812 1.834-.685 1.88-2.807.072-4.219-1.326-1.036-2.73-3.393-2.73-3.393-.688-.285-1.862-.547-2.781-1.216-2.103-1.53-4.904-2.062-5.704-.791z"
                ></path>
                <path
                    fill="url(#_Radial6)"
                    d="M16.399 22.305c.047.399.029 1.291 1.7 1.901 1.064.388 1.776.83 3.236 1.712 1.128.682 1.958 1.748 4.411.832 1.673-.625 1.981-2.355.381-3.705-.476-.401-.985-1.974-.97-3.262 0 0-1.298.648-2.877.904-.63.102-1.551.076-1.989.034a9.4 9.4 0 01-1.912 1.02c-.805.322-1.384.496-1.98.564z"
                ></path>
                <path
                    fill="url(#_Radial7)"
                    d="M20.925 4.26c.918-.327 4.786-.032 6.93 2.995 1.848 2.608 2.278 5.826.61 9.199-1.674 3.384-5.468 4.476-8.157 4.244 2.359-1.857 3.405-5.711 2.623-8.155-.89-2.779-2.385-4.089-3.696-4.954-.456-1.237-.153-2.673 1.69-3.329z"
                ></path>
                <path
                    fill="url(#_Radial8)"
                    d="M14.411 5.945c1.321.069 8.373 1.512 8.701 8.103.195 3.938-2.473 8.656-9.21 8.393-5.354-.209-8.601-5.45-9.181-6.866-1.411-3.448 5.486-9.851 9.69-9.63z"
                ></path>
            </g>
        ),
        defs: (
            <defs>
                <radialGradient
                    id="_Radial5"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-9.52771 -8.56443 8.80551 -9.09867 15.242 28.645)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        offset="0"
                        stopColor="var(--reactions-zhopa-fill)"
                    ></stop>
                    <stop
                        offset="0.49"
                        stopColor="var(--reactions-zhopa-fill)"
                        stopOpacity="0.74"
                    ></stop>
                    <stop
                        offset="1"
                        stopColor="var(--reactions-zhopa-fill)"
                        stopOpacity="0"
                    ></stop>
                </radialGradient>
                <radialGradient
                    id="_Radial6"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-7.76242 -6.27046 7.40073 -5.48964 24.729 25.161)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        offset="0"
                        stopColor="var(--reactions-zhopa-fill)"
                    ></stop>
                    <stop
                        offset="0.49"
                        stopColor="var(--reactions-zhopa-fill)"
                        stopOpacity="0.74"
                    ></stop>
                    <stop
                        offset="1"
                        stopColor="var(--reactions-zhopa-fill)"
                        stopOpacity="0"
                    ></stop>
                </radialGradient>
                <radialGradient
                    id="_Radial7"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-3.65572 -13.1675 11.1884 -11.4807 24.92 18.12)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        offset="0"
                        stopColor="var(--reactions-zhopa-fill)"
                    ></stop>
                    <stop
                        offset="0.49"
                        stopColor="var(--reactions-zhopa-fill)"
                        stopOpacity="0.74"
                    ></stop>
                    <stop
                        offset="1"
                        stopColor="var(--reactions-zhopa-fill)"
                        stopOpacity="0"
                    ></stop>
                </radialGradient>
                <radialGradient
                    id="_Radial8"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="scale(-14.66759) rotate(36.167 1.174 -2.796)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        offset="0"
                        stopColor="var(--reactions-zhopa-fill)"
                    ></stop>
                    <stop
                        offset="0.49"
                        stopColor="var(--reactions-zhopa-fill)"
                        stopOpacity="0.74"
                    ></stop>
                    <stop
                        offset="1"
                        stopColor="var(--reactions-zhopa-fill)"
                        stopOpacity="0"
                    ></stop>
                </radialGradient>
            </defs>
        ),

        day: {
            contour: (
                <g id="contour-day" fill="none" stroke="#000" strokeWidth="1.1">
                    <path d="M14.429 5.945s8.173.627 8.701 8.103c.277 3.933-2.275 8.72-9.21 8.393-6.184-.292-9.18-6.866-9.18-6.866"></path>
                    <path d="M22.696 4.13c2.703.414 5.768 2.632 6.593 6.102 1.085 4.56-1.497 8.214-4.017 9.454-2.337 1.15-4.851 1.015-4.851 1.015M6.289 9.252C3.729 9.082.6 11.147 1.509 15c.348 1.474 1.18 3.421 2.587 5.266 1.262 1.655 2.114 2.512 4.054 4.18 0 0-2.946.544-3.118 1.909-.098.777.698 1.25 1.57 1.25 0 0 1.176.401 3.307 1.468 1.291.646 2.643 1.815 5.33.812 1.834-.685 1.88-2.807.072-4.219-1.326-1.036-2.73-3.393-2.73-3.393M16.417 22.305c.007.402.029 1.291 1.7 1.901 1.065.388 1.776.83 3.236 1.712 1.128.682 1.958 1.748 4.411.832 1.673-.625 1.981-2.355.381-3.705-.476-.401-.9-1.716-.963-2.69M4.811 3.542S4.449 9.148 9.77 13.587M19.678 4.956s-.977 1.057-.521 2.475M22.335 1.519s.888 2.018 2.535 3.304"></path>
                </g>
            ),
            selected: {
                fill: (
                    <g id="fill-day">
                        <path
                            fill="url(#_Radial1)"
                            d="M4.096 20.266c1.262 1.655 2.114 2.512 4.054 4.18 0 0-2.946.544-3.118 1.909-.098.777.698 1.25 1.57 1.25 0 0 1.176.401 3.307 1.468 1.291.646 2.643 1.815 5.33.812 1.834-.685 1.88-2.807.072-4.219-1.326-1.036-2.73-3.393-2.73-3.393-.687-.285-1.862-.547-2.781-1.216-2.103-1.53-4.904-2.062-5.704-.791z"
                        ></path>
                        <path
                            fill="url(#_Radial2)"
                            d="M16.417 22.305c.047.399.029 1.291 1.7 1.901 1.065.388 1.776.83 3.236 1.712 1.128.682 1.958 1.748 4.411.832 1.673-.625 1.981-2.355.381-3.705-.476-.401-.985-1.974-.97-3.262 0 0-1.298.648-2.877.904-.629.102-1.55.076-1.989.034a9.4 9.4 0 01-1.912 1.02c-.804.322-1.384.496-1.98.564z"
                        ></path>
                        <path
                            fill="url(#_Radial3)"
                            d="M20.943 4.26c.918-.327 4.786-.032 6.93 2.995 1.848 2.608 2.279 5.826.61 9.199-1.674 3.384-5.468 4.476-8.157 4.244 2.359-1.857 3.405-5.711 2.623-8.155-.889-2.779-2.385-4.089-3.696-4.954-.456-1.237-.153-2.673 1.69-3.329z"
                        ></path>
                        <path
                            fill="url(#_Radial4)"
                            d="M14.429 5.945c1.321.069 8.374 1.512 8.701 8.103.195 3.938-2.473 8.656-9.21 8.393-5.354-.209-8.601-5.45-9.18-6.866-1.412-3.448 5.486-9.851 9.689-9.63z"
                        ></path>
                    </g>
                ),
                defs: (
                    <defs>
                        <radialGradient
                            id="_Radial1"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientTransform="matrix(-6.75635 -7.45596 7.52224 -6.57024 13.742 28.79)"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop offset="0" stopColor="#FFBDDD"></stop>
                            <stop
                                offset="0.43"
                                stopColor="#FFBDDD"
                                stopOpacity="0.69"
                            ></stop>
                            <stop
                                offset="1"
                                stopColor="#FFBDDD"
                                stopOpacity="0"
                            ></stop>
                        </radialGradient>
                        <radialGradient
                            id="_Radial2"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientTransform="matrix(-7.51394 -2.37303 2.28889 -7.58262 24.938 24.966)"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop offset="0" stopColor="#FFBDDD"></stop>
                            <stop
                                offset="0.43"
                                stopColor="#FFBDDD"
                                stopOpacity="0.69"
                            ></stop>
                            <stop
                                offset="1"
                                stopColor="#FFBDDD"
                                stopOpacity="0"
                            ></stop>
                        </radialGradient>
                        <radialGradient
                            id="_Radial3"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientTransform="matrix(.1378 -10.9248 11.6744 -6.42178 24.661 17.771)"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop offset="0" stopColor="#FFBDDD"></stop>
                            <stop
                                offset="0.43"
                                stopColor="#FFBDDD"
                                stopOpacity="0.69"
                            ></stop>
                            <stop
                                offset="1"
                                stopColor="#FFBDDD"
                                stopOpacity="0"
                            ></stop>
                        </radialGradient>
                        <radialGradient
                            id="_Radial4"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientTransform="rotate(-146.029 13.211 5.842) scale(13.42927)"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop offset="0" stopColor="#FFBDDD"></stop>
                            <stop
                                offset="0.43"
                                stopColor="#FFBDDD"
                                stopOpacity="0.69"
                            ></stop>
                            <stop
                                offset="1"
                                stopColor="#FFBDDD"
                                stopOpacity="0"
                            ></stop>
                        </radialGradient>
                    </defs>
                ),
            },
        },
        night: {
            contour: (
                <g
                    id="contour-night"
                    fill="none"
                    stroke="#999"
                    strokeWidth="1.1"
                >
                    <path d="M14.411 5.945s8.173.627 8.701 8.103c.277 3.933-2.276 8.72-9.21 8.393-6.184-.292-9.181-6.866-9.181-6.866"></path>
                    <path d="M22.678 4.13c2.703.414 5.768 2.632 6.593 6.102 1.085 4.56-1.497 8.214-4.017 9.454-2.337 1.15-4.851 1.015-4.851 1.015M6.271 9.252C3.711 9.082.582 11.147 1.491 15c.348 1.474 1.18 3.421 2.587 5.266 1.262 1.655 2.114 2.512 4.054 4.18 0 0-2.946.544-3.118 1.909-.098.777.698 1.25 1.57 1.25 0 0 1.175.401 3.307 1.468 1.291.646 2.643 1.815 5.33.812 1.834-.685 1.88-2.807.072-4.219-1.326-1.036-2.73-3.393-2.73-3.393M16.399 22.305c.006.402.029 1.291 1.7 1.901 1.064.388 1.776.83 3.236 1.712 1.128.682 1.958 1.748 4.411.832 1.673-.625 1.981-2.355.381-3.705-.476-.401-.9-1.716-.963-2.69M4.793 3.542s-.362 5.606 4.959 10.045M19.66 4.956s-.978 1.057-.522 2.475M22.317 1.519s.888 2.018 2.534 3.304"></path>
                </g>
            ),
            selected: {
                contour: (
                    <g
                        id="contour-night-selected"
                        fill="none"
                        stroke="var(--reactions-zhopa-contour)"
                        strokeWidth="1.1"
                    >
                        <path d="M14.411 5.945s8.173.627 8.701 8.103c.277 3.933-2.276 8.72-9.21 8.393-6.184-.292-9.181-6.866-9.181-6.866"></path>
                        <path d="M22.678 4.13c2.703.414 5.768 2.632 6.593 6.102 1.085 4.56-1.497 8.214-4.017 9.454-2.337 1.15-4.851 1.015-4.851 1.015M6.271 9.252C3.711 9.082.582 11.147 1.491 15c.348 1.474 1.18 3.421 2.587 5.266 1.262 1.655 2.114 2.512 4.054 4.18 0 0-2.946.544-3.118 1.909-.098.777.698 1.25 1.57 1.25 0 0 1.175.401 3.307 1.468 1.291.646 2.643 1.815 5.33.812 1.834-.685 1.88-2.807.072-4.219-1.326-1.036-2.73-3.393-2.73-3.393M16.399 22.305c.006.402.029 1.291 1.7 1.901 1.064.388 1.776.83 3.236 1.712 1.128.682 1.958 1.748 4.411.832 1.673-.625 1.981-2.355.381-3.705-.476-.401-.9-1.716-.963-2.69M4.793 3.542s-.362 5.606 4.959 10.045M19.66 4.956s-.978 1.057-.522 2.475M22.317 1.519s.888 2.018 2.534 3.304"></path>
                    </g>
                ),
                fill: (
                    <g id="fill-night">
                        <path
                            fill="url(#_Radial5)"
                            d="M4.078 20.266c1.262 1.655 2.114 2.512 4.054 4.18 0 0-2.946.544-3.118 1.909-.098.777.698 1.25 1.57 1.25 0 0 1.175.401 3.307 1.468 1.291.646 2.643 1.815 5.33.812 1.834-.685 1.88-2.807.072-4.219-1.326-1.036-2.73-3.393-2.73-3.393-.688-.285-1.862-.547-2.781-1.216-2.103-1.53-4.904-2.062-5.704-.791z"
                        ></path>
                        <path
                            fill="url(#_Radial6)"
                            d="M16.399 22.305c.047.399.029 1.291 1.7 1.901 1.064.388 1.776.83 3.236 1.712 1.128.682 1.958 1.748 4.411.832 1.673-.625 1.981-2.355.381-3.705-.476-.401-.985-1.974-.97-3.262 0 0-1.298.648-2.877.904-.63.102-1.551.076-1.989.034a9.4 9.4 0 01-1.912 1.02c-.805.322-1.384.496-1.98.564z"
                        ></path>
                        <path
                            fill="url(#_Radial7)"
                            d="M20.925 4.26c.918-.327 4.786-.032 6.93 2.995 1.848 2.608 2.278 5.826.61 9.199-1.674 3.384-5.468 4.476-8.157 4.244 2.359-1.857 3.405-5.711 2.623-8.155-.89-2.779-2.385-4.089-3.696-4.954-.456-1.237-.153-2.673 1.69-3.329z"
                        ></path>
                        <path
                            fill="url(#_Radial8)"
                            d="M14.411 5.945c1.321.069 8.373 1.512 8.701 8.103.195 3.938-2.473 8.656-9.21 8.393-5.354-.209-8.601-5.45-9.181-6.866-1.411-3.448 5.486-9.851 9.69-9.63z"
                        ></path>
                    </g>
                ),
                defs: (
                    <defs>
                        <radialGradient
                            id="_Radial5"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientTransform="matrix(-9.52771 -8.56443 8.80551 -9.09867 15.242 28.645)"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop
                                offset="0"
                                stopColor="var(--reactions-zhopa-fill)"
                            ></stop>
                            <stop
                                offset="0.49"
                                stopColor="var(--reactions-zhopa-fill)"
                                stopOpacity="0.74"
                            ></stop>
                            <stop
                                offset="1"
                                stopColor="var(--reactions-zhopa-fill)"
                                stopOpacity="0"
                            ></stop>
                        </radialGradient>
                        <radialGradient
                            id="_Radial6"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientTransform="matrix(-7.76242 -6.27046 7.40073 -5.48964 24.729 25.161)"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop
                                offset="0"
                                stopColor="var(--reactions-zhopa-fill)"
                            ></stop>
                            <stop
                                offset="0.49"
                                stopColor="var(--reactions-zhopa-fill)"
                                stopOpacity="0.74"
                            ></stop>
                            <stop
                                offset="1"
                                stopColor="var(--reactions-zhopa-fill)"
                                stopOpacity="0"
                            ></stop>
                        </radialGradient>
                        <radialGradient
                            id="_Radial7"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientTransform="matrix(-3.65572 -13.1675 11.1884 -11.4807 24.92 18.12)"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop
                                offset="0"
                                stopColor="var(--reactions-zhopa-fill)"
                            ></stop>
                            <stop
                                offset="0.49"
                                stopColor="var(--reactions-zhopa-fill)"
                                stopOpacity="0.74"
                            ></stop>
                            <stop
                                offset="1"
                                stopColor="var(--reactions-zhopa-fill)"
                                stopOpacity="0"
                            ></stop>
                        </radialGradient>
                        <radialGradient
                            id="_Radial8"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientTransform="scale(-14.66759) rotate(36.167 1.174 -2.796)"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop
                                offset="0"
                                stopColor="var(--reactions-zhopa-fill)"
                            ></stop>
                            <stop
                                offset="0.49"
                                stopColor="var(--reactions-zhopa-fill)"
                                stopOpacity="0.74"
                            ></stop>
                            <stop
                                offset="1"
                                stopColor="var(--reactions-zhopa-fill)"
                                stopOpacity="0"
                            ></stop>
                        </radialGradient>
                    </defs>
                ),
            },
        },
    },

    roza: {
        contour: (
            <g className="contour">
                <g className="stebli">
                    <path d="M16.404 30.881c-.012-1.039-.426-3.736-.541-4.771-.208-1.871-.216-4.433.007-6.281"></path>
                    <path d="M17.055 29.052c.59-.148 1.966-.998 2.185-1.295"></path>
                    <path d="M26.9 24.279c.187-.826.158-1.686.158-1.686-.244-.093-.762-.179-1.346-.169-1.614.027-2.988.48-4.31 1.35-.768.506-1.354 1.063-1.789 1.903-.275.53-.375 1.181-.348 1.744 1.501 1.447 4.486 1.77 6.081.266.993-.935 1.262-2.115 1.554-3.408z"></path>
                    <path d="M15.782 30.309c-.412-.426-2.098-1.645-2.098-1.645"></path>
                    <path d="M4.571 21.346c-.014.673.023 1.848.405 2.956.729 2.11 2.16 3.739 4.199 4.682.787.364 1.712.664 2.59.538.654-.093 1.604-.323 2.166-.659.129-.639.225-1.497.121-2.149-.49-3.072-3.258-4.616-6.069-5.195-1.129-.233-2.276-.416-3.412-.173z"></path>
                </g>
                <g className="lepestki">
                    <path d="M23.058 14.776c-1.996.351-4.099 2.786-7.274 2.586-2.371-.15-4.064-1.599-6.101-2.557-1.025-.481-1.929-.731-2.627-.616"></path>
                    <path d="M8.109 10.432c.776.979 2.379 1.533 4.029 2.001 1.101.311 1.978.787 2.827 1.551.578.52 1.366 1.201 1.515 1.996"></path>
                    <path d="M16.348 16.994c.373-3.735 3.324-2.956 6.517-5.961"></path>
                    <path d="M13.84 7.518c-.626-.34-1.03-.472-1.749-.362-1.071.163-1.532.779-1.438 1.704a2.418 2.418 0 001.586 2.024"></path>
                    <path d="M16.975 7.078c.713-.99 2.77-2.498 3.917-.579.838 1.403-.085 3.539-.711 4.169"></path>
                    <path d="M14.142 9.593c1.04-1.469 2.148-1.454 2.723-1.13.585.328.702.756.299 1.341-.11.161-1.187 1.186-2.401.769"></path>
                    <path d="M12.996 6.861c.091-.861.944-2.201 2.302-2.459 1.769-.337 2.565.158 3.223 1.133"></path>
                    <path d="M13.969 13.108c1.371-1.248 3.16-1.905 4.925-1.483"></path>
                    <path d="M16.249 20.471c3.953 1.713 7.878-1.963 9.666-3.957.863-.962 1.366-2.09 1.355-3.106-.013-1.16-.468-2.118-1.197-2.971"></path>
                    <path d="M18.05 19.024c-.442.028-2.312.651-2.918.827-1.018.295-1.538.526-2.613.503-1.49-.031-2.86-.882-3.65-1.335-2.064-1.184-4.637-2.545-4.964-5.174-.153-1.228.674-2.858 1.417-3.78"></path>
                    <path d="M21.409 15.302c1.604-2.322 3.505-2.718 4.834-5.213.663-1.244 1.489-2.907.272-4.654-.507-.729-1.273-1.661-2.865-1.163"></path>
                    <path d="M8.715 14.144c-1.008-1.888-3.512-3.219-3.619-5.03-.241-4.066 2.492-6.244 5.23-5.841"></path>
                    <path d="M23.104 8.799c1.159-2.364.729-4.426-.453-5.685-.787-.84-2.008-1.828-4-1.28-.976.269-2.002 1.278-2.706 2.418"></path>
                    <path d="M8.801 8.772c-.267-.944-.113-2.507.565-4.003.754-1.663 1.985-2.628 3.323-2.962 2.034-.507 3.298.606 3.954 1.143"></path>
                </g>
            </g>
        ),
        fill: (
            <g>
                <path
                    fill="url(#_Radial1)"
                    d="M26.056 10.42s.126-.216.187-.331c.663-1.244 1.489-2.907.272-4.654-.507-.729-1.483-1.604-3.075-1.106a4.106 4.106 0 00-.789-1.215c-.787-.84-2.008-1.828-4-1.28-.626.173-1.272.649-1.841 1.269-.656-.537-2.087-1.803-4.121-1.296-.866.216-1.687.703-2.363 1.466-2.738-.403-5.471 1.775-5.23 5.841.019.319.112.624.259.919-.743.922-1.603 2.584-1.45 3.812.327 2.629 2.9 3.99 4.964 5.174.79.453 2.16 1.304 3.65 1.335 1.075.023 1.384-.146 2.402-.441.096.042 1.52.638 1.616.673 3.855 1.42 7.634-2.126 9.378-4.072.863-.962 1.366-2.09 1.355-3.106-.013-1.16-.485-2.135-1.214-2.988z"
                ></path>
                <path
                    fill="url(#_Radial2)"
                    d="M4.571 21.346c-.014.673.023 1.848.405 2.956.729 2.11 2.16 3.739 4.199 4.682.787.364 1.712.664 2.59.538.654-.093 1.604-.323 2.166-.659.129-.639.225-1.497.121-2.149-.49-3.072-3.258-4.616-6.069-5.195-1.129-.233-2.276-.416-3.412-.173z"
                ></path>
                <path
                    fill="url(#_Radial3)"
                    d="M26.9 24.279c.187-.826.158-1.686.158-1.686-.244-.093-.762-.179-1.346-.169-1.614.027-2.988.48-4.31 1.35-.768.506-1.354 1.063-1.789 1.903-.275.53-.375 1.181-.348 1.744 1.501 1.447 4.486 1.77 6.081.266.993-.935 1.262-2.115 1.554-3.408z"
                ></path>
            </g>
        ),
        defs: (
            <defs>
                <radialGradient
                    id="_Radial1"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(145.45 6.169 7.363) scale(11.57542)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        offset="0"
                        stopColor="var(--reactions-roza-fill-lepestki)"
                    ></stop>
                    <stop
                        offset="0.52"
                        stopColor="var(--reactions-roza-fill-lepestki)"
                        stopOpacity="0.76"
                    ></stop>
                    <stop
                        offset="1"
                        stopColor="var(--reactions-roza-fill-lepestki)"
                        stopOpacity="0"
                    ></stop>
                </radialGradient>
                <radialGradient
                    id="_Radial2"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(-8.17724 -6.61011 6.84622 -7.18881 12.844 28.221)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        offset="0"
                        stopColor="var(--reactions-roza-fill-stebli)"
                    ></stop>
                    <stop
                        offset="0.49"
                        stopColor="var(--reactions-roza-fill-stebli)"
                        stopOpacity="0.74"
                    ></stop>
                    <stop
                        offset="1"
                        stopColor="var(--reactions-roza-fill-stebli)"
                        stopOpacity="0"
                    ></stop>
                </radialGradient>
                <radialGradient
                    id="_Radial3"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="matrix(4.67263 -3.30301 3.03024 4.28674 21.508 26.596)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        offset="0"
                        stopColor="var(--reactions-roza-fill-stebli)"
                    ></stop>
                    <stop
                        offset="0.5"
                        stopColor="var(--reactions-roza-fill-stebli)"
                        stopOpacity="0.75"
                    ></stop>
                    <stop
                        offset="1"
                        stopColor="var(--reactions-roza-fill-stebli)"
                        stopOpacity="0"
                    ></stop>
                </radialGradient>
            </defs>
        ),
    },

    day: {
        contour: (
            <g id="contour-day" fill="none" stroke="#000" strokeWidth="1.1">
                <path d="M16.404 30.881c-.012-1.039-.426-3.736-.541-4.771-.208-1.871-.216-4.433.007-6.281"></path>
                <path d="M17.055 29.052c.59-.148 1.966-.998 2.185-1.295"></path>
                <path d="M26.9 24.279c.187-.826.158-1.686.158-1.686-.244-.093-.762-.179-1.346-.169-1.614.027-2.988.48-4.31 1.35-.768.506-1.354 1.063-1.789 1.903-.275.53-.375 1.181-.348 1.744 1.501 1.447 4.486 1.77 6.081.266.993-.935 1.262-2.115 1.554-3.408z"></path>
                <path d="M15.782 30.309c-.412-.426-2.098-1.645-2.098-1.645"></path>
                <path d="M4.571 21.346c-.014.673.023 1.848.405 2.956.729 2.11 2.16 3.739 4.199 4.682.787.364 1.712.664 2.59.538.654-.093 1.604-.323 2.166-.659.129-.639.225-1.497.121-2.149-.49-3.072-3.258-4.616-6.069-5.195-1.129-.233-2.276-.416-3.412-.173z"></path>
                <path d="M23.058 14.776c-1.996.351-4.099 2.786-7.274 2.586-2.371-.15-4.064-1.599-6.101-2.557-1.025-.481-1.929-.731-2.627-.616"></path>
                <path d="M8.109 10.432c.776.979 2.379 1.533 4.029 2.001 1.101.311 1.978.787 2.827 1.551.578.52 1.366 1.201 1.515 1.996"></path>
                <path d="M16.348 16.994c.373-3.735 3.324-2.956 6.517-5.961"></path>
                <path d="M13.84 7.518c-.626-.34-1.03-.472-1.749-.362-1.071.163-1.532.779-1.438 1.704a2.418 2.418 0 001.586 2.024"></path>
                <path d="M16.975 7.078c.713-.99 2.77-2.498 3.917-.579.838 1.403-.085 3.539-.711 4.169"></path>
                <path d="M14.142 9.593c1.04-1.469 2.148-1.454 2.723-1.13.585.328.702.756.299 1.341-.11.161-1.187 1.186-2.401.769"></path>
                <path d="M12.996 6.861c.091-.861.944-2.201 2.302-2.459 1.769-.337 2.565.158 3.223 1.133"></path>
                <path d="M13.969 13.108c1.371-1.248 3.16-1.905 4.925-1.483"></path>
                <path d="M16.249 20.471c3.953 1.713 7.878-1.963 9.666-3.957.863-.962 1.366-2.09 1.355-3.106-.013-1.16-.468-2.118-1.197-2.971"></path>
                <path d="M18.05 19.024c-.442.028-2.312.651-2.918.827-1.018.295-1.538.526-2.613.503-1.49-.031-2.86-.882-3.65-1.335-2.064-1.184-4.637-2.545-4.964-5.174-.153-1.228.674-2.858 1.417-3.78"></path>
                <path d="M21.409 15.302c1.604-2.322 3.505-2.718 4.834-5.213.663-1.244 1.489-2.907.272-4.654-.507-.729-1.273-1.661-2.865-1.163"></path>
                <path d="M8.715 14.144c-1.008-1.888-3.512-3.219-3.619-5.03-.241-4.066 2.492-6.244 5.23-5.841"></path>
                <path d="M23.104 8.799c1.159-2.364.729-4.426-.453-5.685-.787-.84-2.008-1.828-4-1.28-.976.269-2.002 1.278-2.706 2.418"></path>
                <path d="M8.801 8.772c-.267-.944-.113-2.507.565-4.003.754-1.663 1.985-2.628 3.323-2.962 2.034-.507 3.298.606 3.954 1.143"></path>
            </g>
        ),
        selected: {
            fill: (
                <g id="fill-day">
                    <path
                        fill="url(#_Radial4)"
                        d="M26.056 10.42s.126-.216.187-.331c.663-1.244 1.489-2.907.272-4.654-.507-.729-1.483-1.604-3.075-1.106a4.106 4.106 0 00-.789-1.215c-.787-.84-2.008-1.828-4-1.28-.626.173-1.272.649-1.841 1.269-.656-.537-2.087-1.803-4.121-1.296-.866.216-1.687.703-2.363 1.466-2.738-.403-5.471 1.775-5.23 5.841.019.319.112.624.259.919-.743.922-1.603 2.584-1.45 3.812.327 2.629 2.9 3.99 4.964 5.174.79.453 2.16 1.304 3.65 1.335 1.075.023 1.384-.146 2.402-.441.096.042 1.52.638 1.616.673 3.855 1.42 7.634-2.126 9.378-4.072.863-.962 1.366-2.09 1.355-3.106-.013-1.16-.485-2.135-1.214-2.988z"
                    ></path>
                    <path
                        fill="url(#_Radial5)"
                        d="M4.571 21.346c-.014.673.023 1.848.405 2.956.729 2.11 2.16 3.739 4.199 4.682.787.364 1.712.664 2.59.538.654-.093 1.604-.323 2.166-.659.129-.639.225-1.497.121-2.149-.49-3.072-3.258-4.616-6.069-5.195-1.129-.233-2.276-.416-3.412-.173z"
                    ></path>
                    <path
                        fill="url(#_Radial6)"
                        d="M26.9 24.279c.187-.826.158-1.686.158-1.686-.244-.093-.762-.179-1.346-.169-1.614.027-2.988.48-4.31 1.35-.768.506-1.354 1.063-1.789 1.903-.275.53-.375 1.181-.348 1.744 1.501 1.447 4.486 1.77 6.081.266.993-.935 1.262-2.115 1.554-3.408z"
                    ></path>
                </g>
            ),
            defs: (
                <defs>
                    <radialGradient
                        id="_Radial4"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientTransform="rotate(145.45 6.169 7.363) scale(11.57542)"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0" stopColor="#FFA87E"></stop>
                        <stop
                            offset="0.44"
                            stopColor="#FFA87E"
                            stopOpacity="0.7"
                        ></stop>
                        <stop
                            offset="1"
                            stopColor="#FFA87E"
                            stopOpacity="0"
                        ></stop>
                    </radialGradient>
                    <radialGradient
                        id="_Radial5"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientTransform="rotate(-139.237 10.527 11.432) scale(7.70848)"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0" stopColor="#4FB652"></stop>
                        <stop
                            offset="0.58"
                            stopColor="#4FB652"
                            stopOpacity="0.31"
                        ></stop>
                        <stop
                            offset="1"
                            stopColor="#4FB652"
                            stopOpacity="0"
                        ></stop>
                    </radialGradient>
                    <radialGradient
                        id="_Radial6"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientTransform="matrix(4.67263 -3.30301 3.03024 4.28674 21.508 26.596)"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0" stopColor="#4FB652"></stop>
                        <stop
                            offset="1"
                            stopColor="#4FB652"
                            stopOpacity="0"
                        ></stop>
                    </radialGradient>
                </defs>
            ),
        },
    },
    night: {
        contour: (
            <g id="contour-night" fill="none" stroke="#999" strokeWidth="1.1">
                <path d="M16.404 30.881c-.012-1.039-.426-3.736-.541-4.771-.208-1.871-.216-4.433.007-6.281"></path>
                <path d="M17.055 29.052c.59-.148 1.966-.998 2.185-1.295"></path>
                <path d="M26.9 24.279c.187-.826.158-1.686.158-1.686-.244-.093-.762-.179-1.346-.169-1.614.027-2.988.48-4.31 1.35-.768.506-1.354 1.063-1.789 1.903-.275.53-.375 1.181-.348 1.744 1.501 1.447 4.486 1.77 6.081.266.993-.935 1.262-2.115 1.554-3.408z"></path>
                <path d="M15.782 30.309c-.412-.426-2.098-1.645-2.098-1.645"></path>
                <path d="M4.571 21.346c-.014.673.023 1.848.405 2.956.729 2.11 2.16 3.739 4.199 4.682.787.364 1.712.664 2.59.538.654-.093 1.604-.323 2.166-.659.129-.639.225-1.497.121-2.149-.49-3.072-3.258-4.616-6.069-5.195-1.129-.233-2.276-.416-3.412-.173z"></path>
                <path d="M23.058 14.776c-1.996.351-4.099 2.786-7.274 2.586-2.371-.15-4.064-1.599-6.101-2.557-1.025-.481-1.929-.731-2.627-.616"></path>
                <path d="M8.109 10.432c.776.979 2.379 1.533 4.029 2.001 1.101.311 1.978.787 2.827 1.551.578.52 1.366 1.201 1.515 1.996"></path>
                <path d="M16.348 16.994c.373-3.735 3.324-2.956 6.517-5.961"></path>
                <path d="M13.84 7.518c-.626-.34-1.03-.472-1.749-.362-1.071.163-1.532.779-1.438 1.704a2.418 2.418 0 001.586 2.024"></path>
                <path d="M16.975 7.078c.713-.99 2.77-2.498 3.917-.579.838 1.403-.085 3.539-.711 4.169"></path>
                <path d="M14.142 9.593c1.04-1.469 2.148-1.454 2.723-1.13.585.328.702.756.299 1.341-.11.161-1.187 1.186-2.401.769"></path>
                <path d="M12.996 6.861c.091-.861.944-2.201 2.302-2.459 1.769-.337 2.565.158 3.223 1.133"></path>
                <path d="M13.969 13.108c1.371-1.248 3.16-1.905 4.925-1.483"></path>
                <path d="M16.249 20.471c3.953 1.713 7.878-1.963 9.666-3.957.863-.962 1.366-2.09 1.355-3.106-.013-1.16-.468-2.118-1.197-2.971"></path>
                <path d="M18.05 19.024c-.442.028-2.312.651-2.918.827-1.018.295-1.538.526-2.613.503-1.49-.031-2.86-.882-3.65-1.335-2.064-1.184-4.637-2.545-4.964-5.174-.153-1.228.674-2.858 1.417-3.78"></path>
                <path d="M21.409 15.302c1.604-2.322 3.505-2.718 4.834-5.213.663-1.244 1.489-2.907.272-4.654-.507-.729-1.273-1.661-2.865-1.163"></path>
                <path d="M8.715 14.144c-1.008-1.888-3.512-3.219-3.619-5.03-.241-4.066 2.492-6.244 5.23-5.841"></path>
                <path d="M23.104 8.799c1.159-2.364.729-4.426-.453-5.685-.787-.84-2.008-1.828-4-1.28-.976.269-2.002 1.278-2.706 2.418"></path>
                <path d="M8.801 8.772c-.267-.944-.113-2.507.565-4.003.754-1.663 1.985-2.628 3.323-2.962 2.034-.507 3.298.606 3.954 1.143"></path>
            </g>
        ),
        selected: {
            contour: (
                <g id="contour-night-selected" fill="none" strokeWidth="1.1">
                    <path
                        stroke="#4F793F"
                        d="M16.404 30.881c-.012-1.039-.426-3.736-.541-4.771-.208-1.871-.216-4.433.007-6.281"
                    ></path>
                    <path
                        stroke="#4F793F"
                        d="M17.055 29.052c.59-.148 1.966-.998 2.185-1.295"
                    ></path>
                    <path
                        stroke="#4F793F"
                        d="M26.9 24.279c.187-.826.158-1.686.158-1.686-.244-.093-.762-.179-1.346-.169-1.614.027-2.988.48-4.31 1.35-.768.506-1.354 1.063-1.789 1.903-.275.53-.375 1.181-.348 1.744 1.501 1.447 4.486 1.77 6.081.266.993-.935 1.262-2.115 1.554-3.408z"
                    ></path>
                    <path
                        stroke="#4F793F"
                        d="M15.782 30.309c-.412-.426-2.098-1.645-2.098-1.645"
                    ></path>
                    <path
                        stroke="#4F793F"
                        d="M4.571 21.346c-.014.673.023 1.848.405 2.956.729 2.11 2.16 3.739 4.199 4.682.787.364 1.712.664 2.59.538.654-.093 1.604-.323 2.166-.659.129-.639.225-1.497.121-2.149-.49-3.072-3.258-4.616-6.069-5.195-1.129-.233-2.276-.416-3.412-.173z"
                    ></path>
                    <path
                        stroke="#B96E59"
                        d="M23.058 14.776c-1.996.351-4.099 2.786-7.274 2.586-2.371-.15-4.064-1.599-6.101-2.557-1.025-.481-1.929-.731-2.627-.616"
                    ></path>
                    <path
                        stroke="#B96E59"
                        d="M8.109 10.432c.776.979 2.379 1.533 4.029 2.001 1.101.311 1.978.787 2.827 1.551.578.52 1.366 1.201 1.515 1.996"
                    ></path>
                    <path
                        stroke="#B96E59"
                        d="M16.348 16.994c.373-3.735 3.324-2.956 6.517-5.961"
                    ></path>
                    <path
                        stroke="#B96E59"
                        d="M13.84 7.518c-.626-.34-1.03-.472-1.749-.362-1.071.163-1.532.779-1.438 1.704a2.418 2.418 0 001.586 2.024"
                    ></path>
                    <path
                        stroke="#B96E59"
                        d="M16.975 7.078c.713-.99 2.77-2.498 3.917-.579.838 1.403-.085 3.539-.711 4.169"
                    ></path>
                    <path
                        stroke="#B96E59"
                        d="M14.142 9.593c1.04-1.469 2.148-1.454 2.723-1.13.585.328.702.756.299 1.341-.11.161-1.187 1.186-2.401.769"
                    ></path>
                    <path
                        stroke="#B96E59"
                        d="M12.996 6.861c.091-.861.944-2.201 2.302-2.459 1.769-.337 2.565.158 3.223 1.133"
                    ></path>
                    <path
                        stroke="#B96E59"
                        d="M13.969 13.108c1.371-1.248 3.16-1.905 4.925-1.483"
                    ></path>
                    <path
                        stroke="#B96E59"
                        d="M16.249 20.471c3.953 1.713 7.878-1.963 9.666-3.957.863-.962 1.366-2.09 1.355-3.106-.013-1.16-.468-2.118-1.197-2.971"
                    ></path>
                    <path
                        stroke="#B96E59"
                        d="M18.05 19.024c-.442.028-2.312.651-2.918.827-1.018.295-1.538.526-2.613.503-1.49-.031-2.86-.882-3.65-1.335-2.064-1.184-4.637-2.545-4.964-5.174-.153-1.228.674-2.858 1.417-3.78"
                    ></path>
                    <path
                        stroke="#B96E59"
                        d="M21.409 15.302c1.604-2.322 3.505-2.718 4.834-5.213.663-1.244 1.489-2.907.272-4.654-.507-.729-1.273-1.661-2.865-1.163"
                    ></path>
                    <path
                        stroke="#B96E59"
                        d="M8.715 14.144c-1.008-1.888-3.512-3.219-3.619-5.03-.241-4.066 2.492-6.244 5.23-5.841"
                    ></path>
                    <path
                        stroke="#B96E59"
                        d="M23.104 8.799c1.159-2.364.729-4.426-.453-5.685-.787-.84-2.008-1.828-4-1.28-.976.269-2.002 1.278-2.706 2.418"
                    ></path>
                    <path
                        stroke="#B96E59"
                        d="M8.801 8.772c-.267-.944-.113-2.507.565-4.003.754-1.663 1.985-2.628 3.323-2.962 2.034-.507 3.298.606 3.954 1.143"
                    ></path>
                </g>
            ),
            fill: (
                <g id="fill-night">
                    <path
                        fill="url(#_Radial1)"
                        d="M26.056 10.42s.126-.216.187-.331c.663-1.244 1.489-2.907.272-4.654-.507-.729-1.483-1.604-3.075-1.106a4.106 4.106 0 00-.789-1.215c-.787-.84-2.008-1.828-4-1.28-.626.173-1.272.649-1.841 1.269-.656-.537-2.087-1.803-4.121-1.296-.866.216-1.687.703-2.363 1.466-2.738-.403-5.471 1.775-5.23 5.841.019.319.112.624.259.919-.743.922-1.603 2.584-1.45 3.812.327 2.629 2.9 3.99 4.964 5.174.79.453 2.16 1.304 3.65 1.335 1.075.023 1.384-.146 2.402-.441.096.042 1.52.638 1.616.673 3.855 1.42 7.634-2.126 9.378-4.072.863-.962 1.366-2.09 1.355-3.106-.013-1.16-.485-2.135-1.214-2.988z"
                    ></path>
                    <path
                        fill="url(#_Radial2)"
                        d="M4.571 21.346c-.014.673.023 1.848.405 2.956.729 2.11 2.16 3.739 4.199 4.682.787.364 1.712.664 2.59.538.654-.093 1.604-.323 2.166-.659.129-.639.225-1.497.121-2.149-.49-3.072-3.258-4.616-6.069-5.195-1.129-.233-2.276-.416-3.412-.173z"
                    ></path>
                    <path
                        fill="url(#_Radial3)"
                        d="M26.9 24.279c.187-.826.158-1.686.158-1.686-.244-.093-.762-.179-1.346-.169-1.614.027-2.988.48-4.31 1.35-.768.506-1.354 1.063-1.789 1.903-.275.53-.375 1.181-.348 1.744 1.501 1.447 4.486 1.77 6.081.266.993-.935 1.262-2.115 1.554-3.408z"
                    ></path>
                </g>
            ),
            defs: (
                <defs>
                    <radialGradient
                        id="_Radial1"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientTransform="rotate(145.45 6.169 7.363) scale(11.57542)"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop
                            offset="0"
                            stopColor="var(--reactions-roza-fill-lepestki)"
                        ></stop>
                        <stop
                            offset="0.52"
                            stopColor="var(--reactions-roza-fill-lepestki)"
                            stopOpacity="0.76"
                        ></stop>
                        <stop
                            offset="1"
                            stopColor="var(--reactions-roza-fill-lepestki)"
                            stopOpacity="0"
                        ></stop>
                    </radialGradient>
                    <radialGradient
                        id="_Radial2"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientTransform="matrix(-8.17724 -6.61011 6.84622 -7.18881 12.844 28.221)"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop
                            offset="0"
                            stopColor="var(--reactions-roza-fill-stebli)"
                        ></stop>
                        <stop
                            offset="0.49"
                            stopColor="var(--reactions-roza-fill-stebli)"
                            stopOpacity="0.74"
                        ></stop>
                        <stop
                            offset="1"
                            stopColor="var(--reactions-roza-fill-stebli)"
                            stopOpacity="0"
                        ></stop>
                    </radialGradient>
                    <radialGradient
                        id="_Radial3"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientTransform="matrix(4.67263 -3.30301 3.03024 4.28674 21.508 26.596)"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop
                            offset="0"
                            stopColor="var(--reactions-roza-fill-stebli)"
                        ></stop>
                        <stop
                            offset="0.5"
                            stopColor="var(--reactions-roza-fill-stebli)"
                            stopOpacity="0.75"
                        ></stop>
                        <stop
                            offset="1"
                            stopColor="var(--reactions-roza-fill-stebli)"
                            stopOpacity="0"
                        ></stop>
                    </radialGradient>
                </defs>
            ),
        },
    },
};

export const Icon = ({ type, selected }) => {

    const icon = { like: "roza", dislike: "zhopa" }[type];
    const title = { like: "оценка «роза»", dislike: "оценка «жопа»" }[type];

    const { contour, fill, defs } = reactions[icon];

    return (
        <svg className={cl("icon", icon, { selected })} viewBox="0 0 32 32">
            { selected && fill }
            { contour }
            { selected && defs }
            <title>{title}</title>
        </svg>
    )
};
