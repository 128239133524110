import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { times } from "../../lang";
import { cl, entities2unicode } from "../../shed";
import { Emoji } from "./emoji";
import { useS, useSet, useSlice, useSliceSet } from "../../store/store";
import { Lang } from "../reader/Page";
import { useBubbles } from "./useBubbles";

const Message = ({ children }) => {
    return (
        <div className="msg">
            <svg viewBox="0 0 100 100">
                <polygon points="100,0 100,50 20,50" />
            </svg>
            {children}
        </div>
    );
};

export const countByType = (bubbles) => (
    bubbles.reduce((counter, bubble) => ({
        ...counter,
        [bubble.type]: (counter[bubble.type]||0) + bubble.count
    }), {})
);

export const groupByCount = (bubbles) => (
    bubbles.reduce((arr, next) => {
        const index = arr.findIndex(
            (b) => b.bubble_id === next.bubble_id
        );
        let created = new Date(next.created);
        if (index > -1) {
            const existing = arr[index];
            const count = (existing.count || 1) + 1;
            const existingDate = new Date(existing.created);
            if (existingDate > created) created = existingDate;
            arr[index] = { ...existing, count, created };
        } else {
            arr.push({...next, count: next.count || 1, created });
        }
        return arr;
    }, [])
);



const Bubbles = ({ object_id, text }) => {

    const lang = useContext(Lang);

    const labels = useS(`labels._said.${lang}.bubbles`);
    // useSelector(state => state.labels.value._said[lang].bubbles);


    const { status, data: bubbles } = useBubbles({ object_id, text });

    // console.log(object_id);

    const [node, setNode] = useState();
    const setCommentsAnchor = useSliceSet(lang, "commentsAnchor");
    const element = useSlice("elements", "comments");

    useEffect(() => {
        if (node) {
            setCommentsAnchor(node);
        }
    }, [node, element, setCommentsAnchor, lang]);

    if (status !== "success") return <div>...</div>;

    // console.log(bubbles);

    return (
        <>
        <div ref={node=>setNode(node)} style={{
            position:"relative", top:"20px",
            zIndex: -1, visibility: "hidden"
        }}>x</div>
        <h1>{labels.comments}</h1>

        {
            bubbles.length > 3 && <div className="counter">{(()=>{
                const counter = countByType(bubbles);
                return Object.entries(counter).map(([key, val], i) => (
                    <div className="count" key={i}>
                        <span className="label">{labels[`counter ${key}`]}</span>
                        <span className="number">{val}</span>
                    </div>
                ))
            })()}</div>
        }

        {
            bubbles.length === 0
            ? <h2>{labels["no comments"]}</h2>
            : <div className={cl("bubbles")}>
                {bubbles
                    .map((bubble) => {
                        // console.log(bubble);
                        return (<div className="bubble" key={bubble.id}>
                            <Emoji emotion={bubble.type} />
                            <Message>
                                {entities2unicode(bubble.label[lang])}
                                {bubble.count > 1 && (
                                    <div className="count">
                                        {times(bubble.count, lang)}
                                    </div>
                                )}
                            </Message>
                        </div>)
                    })
                }
            </div>
        }
        </>
    );
};


export default Bubbles;
