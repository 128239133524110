import { useCallback } from "react";
import { useState } from "react";
import { generateId } from "../shed";

export const getAccessKey = () => {
  return localStorage.getItem("ein_key");
};

export const setSessionKey = () => {
  const key = generateId();
  sessionStorage.setItem("ein_session", key);
};

export const getSessionKey = () => {
  return sessionStorage.getItem("ein_session");
};

const Auth = () => {
  const [value, setValue] = useState(localStorage.getItem("ein_key"));

  const onInput = useCallback(({ target }) => {
    setValue(target.value);
  }, []);

  const onClick = useCallback(() => {
    localStorage.setItem("ein_key", value);
  }, [value]);

  return (
    <div className="auth">
      <input defaultValue={value} {...{ onInput }} />
      <button {...{ onClick }}>Save</button>
    </div>
  );
};

export default Auth;
