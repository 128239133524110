import { useEffect } from "react";
import { findChild } from "../../../../shed";
import { useSlice, useSliceSet } from "../../../../store/store";
import { createPersonEntity } from "./persons";


export const Known = ({ user, names }) => {

    const person = useSlice("post", "person");
    const setPerson = useSliceSet("post", "person");

    // const prompt = useLabel("feed.known person prompt");

    // console.log("Known: person:", person);

    useEffect(() => {

        if (person) return;

        const { first, second, first_entity, second_entity, params } = user;

        // console.log(names);

        const firstEntity = findChild(names, obj=>obj.entity===first_entity);
        const secondEntity = findChild(names, obj=>obj.entity===second_entity);

        const entity = createPersonEntity(firstEntity, secondEntity, params);

        const personReady = {
            first,
            second,
            first_entity,
            second_entity,
            params,
            entity
        };

        // console.log("Known: person ready:", personReady);

        setPerson(personReady);

    }, [user, names, person, setPerson]);

    /* return (
        <div className="known person">
            <div>{ prompt }</div>
            <div>{ user.first }</div>
            <div>{ user.second }</div>
        </div>
    ) */

    return null;
};