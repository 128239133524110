import { useCallback, useEffect, useState } from "react";
import "./Select.scss";
import { cl } from "../../../shed";
import { usePrevious } from "react-use";
import { MemoizedItem } from "./Item";


const LineSelect = ({ data, handleChange, className }) => {
    // console.log("Select render");

    // console.log(id);

    const { list, selected: defaultSelected } = data;

    const [selected, setSelected] = useState(defaultSelected);
    const prev = usePrevious(selected);

    const handleItemClick = useCallback(
        (value) => () => {
            // console.log(`${value} clicked`);
            setSelected(value);
        },
        []
    );

    useEffect(() => {
        if (selected !== prev) handleChange(selected);
    }, [selected, prev, handleChange]);


    return (
        <div className={cl("bui line select", className)}>
            { list.map((item, i) => (
                <MemoizedItem
                    key={i}
                    {...{ item }}
                    selected={item.value === selected}
                    onClick={handleItemClick}
                />
            )) }
        </div>
    );
};

export default LineSelect;
