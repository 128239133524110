import { Link } from "react-router-dom"
import { dev } from "../../shed";

const Image = ({ src }) => {
    return (
        <div className="ill" style={{ backgroundImage: `url(${src})` }}>
            <div className="shadow">&nbsp;</div>
        </div>
    )
};

const Item = ({ item, lang, title, root }) => {

    return (
        <div className="item">
            <Link to={`${root}/${item.url}`}>
            <Image src={`/assets${root}/${item.image}`} />
            <h1>
                {
                    lang==="both"
                    ? <>{title.ru}<br />{title.lv}</>
                    : title[lang]
                }
            </h1>
            </Link>
        </div>
    )
};

const TOC = ({ content, lang }) => {

    // console.log(content);

    const { toc, texts, root } = content;

    // console.log(lang);

    return (
        <div className="toc">{ toc
            .slice(0).reverse()
            .filter(item => item.public||dev())
            .map((item, i) => {

            const title = texts.filter(t => t.object_desc===item.text)
            .reduce((obj, next) => ({...obj, [next.lang]: next.title }),{});

            // console.log(item);

            return <Item key={i} {...{ title, lang, item, root }} />


        }) }</div>
    )
};


export default TOC;
