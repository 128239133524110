import { memo, useMemo } from "react";
import { cl, entities2unicode, filterTree, findChild, findDescendants, mutateTree } from "../../../shed";
import { useS, useSlice } from "../../../store/store";
import { parse } from "../express";
import { getEntityValuesAndForm, getParams, template2text } from "../lang";
import { rx } from "../rx";
import { useLabel } from "../useLabel";
import { Items, Item } from "./Menu2Items";

const Prompt = ({ prompt }) => {

    // console.log(`Prompt:`, prompt);

    const post = useS("post");

    const label = prompt.label || prompt;

    const text = template2text(post, label);

    return (
        <h1>{text}</h1>
    )
};

export const Level = memo(({ level }) => {

    // console.log(`%cLevel:`, "font-weight:bold");
    // console.log(`%c${level.name}`, "font-size: 18px");
    // console.log(`level:`, level);

    const { filter, dataKey, key, promptKey } = level;

    // const status = useSlice("post", "status");
    // console.log(`%cLevel: post status: ${status}`, "color:violet");

    const data = useSlice("post", "data");

    // console.log(`%cLevel post data:`, "color:violet", data);

    const filterEntity = useSlice("post", filter?.key);

    const prompt = useLabel(promptKey, true);

    const entityNames = useMemo(() => {

        if (!data) return [];

        if (filter && !filterEntity) return [];

        if (filterEntity) {
            // console.log(`Items: filter entityNames by ${filter.key}===${filterEntity.entity}`);
            return data.templates.reduce((entities, templateGroup) => {

                if (!templateGroup[filter.dataKey]) {
                    return entities;
                }
                if (!templateGroup[filter.dataKey]
                .map(entityName=>entityName.replace(/=/,""))
                .includes(filterEntity.entity)) {
                    return entities
                }

                const templateGroupEntities = templateGroup[dataKey]
                ? templateGroup[dataKey].filter(entity => !entities.includes(entity))
                : [];
                return [...entities, ...templateGroupEntities];
            }, []);
        }
        return data.templates.reduce((entities, templateGroup) => {
            const templateGroupEntities = templateGroup[dataKey]
            ? templateGroup[dataKey].filter(entity => !entities.includes(entity))
            : [];
            return [...entities, ...templateGroupEntities];
        }, []);

    }, [data, dataKey, filterEntity, filter]);

    // console.log(`Level: entityNames:`, entityNames);

    if (filter && !filterEntity) return null;

    if (!data) return null;

    const props = { key };
    if (prompt?.params) props.params = prompt.params;

    const tree = mutateTree(
        filterTree(data[dataKey], "entities", obj=>!obj.disabled),
        "entities",
        obj => {
            if (entityNames.includes(obj.entity)) {
                return obj => mutateTree(
                    obj, "entities",
                    () => child => ({...child, ...props, view: "link" })
                );
            }
            if (entityNames.includes(`=${obj.entity}`)) {
                return obj => {
                    // const { entities, ...solo } = obj;
                    return {...obj, ...props, view: "link" };
                }
            }
            if (findDescendants(obj, "entities", "entity").some(n =>
                entityNames.includes(n) || entityNames.includes(`=${n}`)
            )) {
                return obj => ({...obj, ...props, view: "label" });
            }
            return obj => ({...obj, ...props, view: null });
        }
    );

    // console.log(`mutated tree:`, tree);

    return (
        <div className={cl("level", dataKey)}>
            { prompt && <Prompt {...{ prompt }} /> }
            {
                Array.isArray(tree)
                ? <Items items={tree} />
                : <Item item={tree} />
            }
        </div>
    )
});
