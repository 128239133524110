import { useRef } from "react";
import { useQuery } from "react-query";
import { fetchHTML } from "../../cms/fetchHTML";
import "./author.scss";

const useCSS = ({ css, random }) => {
    const res = useQuery(["css", css], async () => {
        // console.log("fetch css?", random);
        const response = await fetch(`${css}?${random}`);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        return response.text();
    }, {
        enabled: !!css
    });
    return res;
};

const Author = ({ nick }) => {
    const { status, data: author } = useQuery(
        ["author", nick],
        () => fetchHTML(`/html/authors/${nick}/${nick}.html`),
        { refetchOnWindowFocus: false }
    );

    const random = useRef(Math.random());

    const { isSuccess: cssLoaded, data: cssContent } = useCSS({ css: author?.css, random: random.current });

    if (status !== "success" || (author.css && !cssLoaded)) return null;

    // console.log(cssLoaded);

    // if (cssLoaded) console.log(cssContent);

    // console.log(author);

    // console.log("render css?", random.current);

    return (
        <>
            {author.css && (
                <link
                    rel="stylesheet"
                    href={`${author.css}?${random.current}`}
                />
            )}
            <div className="content">{author.data}</div>
        </>
    );
};

export default Author;
