import { useQuery } from "react-query";
import { getJSON, getTable } from "../../api";
import { getAccessKey } from "../../cms/session";
// import { entities2unicode, say } from "../../shed";

import "./charts.scss";
import { LittleStar } from "./graphics";
import { Chart } from "./Chart";
import { Count, Name, Pos } from "./List";
import { Icon } from "../foam/reactions";

const head = (<>
    <div className="pos">
        <LittleStar />
    </div>
    <div className="icon">
        <Icon type="dislike" />
    </div>
    <div className="icon">
        <Icon type="like" />
    </div>
    <div className="name">ТЕКСТУАЛ</div>
</>);

const list = (entries) => (
    entries.map((entry, i) => (
        <div key={i} className="entry">
            <Pos num={i + 1} />
            <div className="count like">{entry.asses}</div>
            <div className="count like">{entry.roses}</div>
            <Name name={entry.name} />
        </div>
    ))
);

const Top10Zhop = () => {
    const bubbles = useQuery(["charts", "foam"], () =>
        getTable({
            table: "public_foam",
            access_key: getAccessKey(),
        }).then(response => response.filter(r => /^copyright/.test(r.object_desc))),
        { refetchOnWindowFocus: false }
    );

    const descriptors = useQuery(["charts", "copyrights"],
        () => getJSON("/json/copyright.json"),
        { refetchOnWindowFocus: false }
    );

    const status =
        [bubbles, descriptors].find((f) => f.status !== "success")
            ?.status || "success";

    if (status !== "success") return null;

    // console.log(descriptors.data);

    // console.log(bubbles.data);

    const entries = descriptors.data.map(copyright => {

        const asses = bubbles.data.filter(bubble => (
            bubble.object_id === copyright.id &&
            bubble.bubble_id === "7c5ada47-9059-489a-8b3d-c1499e7ae461"
        )).length;

        const roses = bubbles.data.filter(bubble => (
            bubble.object_id === copyright.id &&
            bubble.bubble_id === "b6747fce-eef2-4c11-8f62-b5da52fefaa2"
        )).length;

        return {
            name: copyright.ru,
            asses, roses
        }

    }).sort((a, b) => (b.asses + b.roses) - (a.asses + a.roses));

    // console.log(entries);

    return (
        <Chart {...{ label: "Копирайты", head, entries: list(entries), legend, n: 20 }} />
    );
};

const legend = (<>
    <div>
        <div>
            <Icon type="dislike" />
            <span>неодобрительная оценка (или наоборот, кому как)</span>
        </div>
        <div>
            <Icon type="like" />
            <span>одобрительная оценка (ну или нет)</span>
        </div>
    </div>
</>);


export default Top10Zhop;
