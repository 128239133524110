import { isEqual } from "lodash";
import { memo, useEffect, useMemo } from "react";
import { useQueries, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import { getTable, getTableS } from "../../api";
import { fetchHTML } from "../../cms/fetchHTML";
import { getAccessKey } from "../../cms/session";
import { single, useTraceUpdate } from "../../shed";
import { useToc } from "../queries/queries";
import Reader from "../reader/Reader";
import { simpleActions as a } from "../slicer/asyncSlicer";


const ContentTOC = ({ root, toc }) => {

    // console.log(root, toc);

    const texts = toc.map(item => item.text);

    const { status, data } = useQuery(["texts", root],
    ()=> getTableS({
        table: "public_texts",
        what: ["object_desc", "title", "lang"],
        access_key: getAccessKey()
    }).then(response =>
        response.filter(item => item.object_desc && texts.includes(item.object_desc))),
    { refetchOnWindowFocus: false });

    if (status !== "success") return null;

    // console.log(data);

    const content = {
        root, toc, texts: data
    }

    return (
        <Reader {...{ content }} />
    )
};

const Page = ({ root, page, toc }) => {

    const [author_html_folder_name, work_html_folder_name, html_file_name] =
        page.text.split("/");

    // console.log(html_folder_name, html_file_name);

    const author = useQuery(
        ["author", page.text],
        () =>
            getTable({
                table: "public_authors",
                where: { html_folder_name: author_html_folder_name },
                access_key: getAccessKey(),
            }).then((response) => single(response)),
        { refetchOnWindowFocus: false }
    );

    const work = useQuery(
        ["work", page.text],
        () =>
            getTable({
                table: "public_works",
                where: {
                    html_folder_name: work_html_folder_name,
                    author_id: author.data.id,
                },
                access_key: getAccessKey(),
            }).then((response) => single(response)),
        {
            enabled: author.status === "success",
            refetchOnWindowFocus: false,
        }
    );

    const texts = useQuery(
        ["texts", page.text],
        () => {
            const request = {
                table: "public_texts",
                where: {
                    html_file_name,
                    work_id: work.data.id,
                    author_id: author.data.id,
                },
                access_key: getAccessKey(),
            };
            // console.log(request);
            return getTable(request).then((response) => {
                if (!Array.isArray(response) && response.length !== 2) {
                    throw new Error("should be array of 2");
                }
                return response;
            });
        },
        {
            enabled: work.status === "success",
            refetchOnWindowFocus: false,
        }
    );

    // useTraceUpdate({ page, author, work }, "Page");

    if (author.status !== "success" || work.status !== "success" || texts.status !== "success") {
        return null;
    }

    return (<>
        <Content
            {...{
                author: author.data,
                work: work.data,
                texts: texts.data,
                toc, root }}
        />
    </>);
};

const Content = memo(({ author, work, texts, toc, root }) => {

    /* useEffect(()=>{
        console.log("Content rendered");
    }); */
    const dispatch = useDispatch();
    const css = useSelector(state => state.css.value, isEqual);

    useEffect(() => {

        const href = `/html/authors/${author.html_folder_name}/${work.html_folder_name}/${work.html_folder_name}.css`;

        // console.log(css);

        dispatch(a.css.update({ href }));

    }, [author.html_folder_name, work.html_folder_name, dispatch]);
    
    // console.log(css);

    const html = useQueries(
        texts.map((text) => {
            const pathname = `/html/authors/${author.html_folder_name}/${work.html_folder_name}/${text.lang}/${text.html_file_name}`;
            // console.log(text.title);

            return {
                queryKey: ["html", pathname],
                queryFn: () =>
                    fetchHTML(pathname, { id: text.id, lang: text.lang, title: text.title }),
                refetchOnWindowFocus: false,
            };
        })
    );

    if (html.some(file => file.status !== "success")) return <div>...</div>;

    if (!css) return <div>...</div>;

    // console.log(html);

    const toc_text = `${author.html_folder_name}/${work.html_folder_name}/${texts[0].html_file_name}`;

    const itemInToc = toc.find(item => item.text === toc_text);

    const url =  `https://ein.romochka.lv${root}/${itemInToc.url}`;
    const description = itemInToc.description;
    const image = `https://ein.romochka.lv/assets${root}/${itemInToc.image}`;

    const content = html.map(response=>response.data).reduce((content, page) => ({
        ...content,
        [page.lang]: page,
        toc, toc_text, root, url, description, image
    }), {});

    // console.log(content);

    return <Reader {...{content}} />;
}, (prev, next) => isEqual(prev, next));

const Lesen = ({ root, toc }) => {
    // console.log("Lesen");

    const lastPage = toc[0].url;

    const urlPattern =
        ":" +
        lastPage
            .split("/")
            .map((_, i) => "param" + (i + 1))
            .join("/:");

    const match = useRouteMatch(`${root}/${urlPattern}`);

    // console.log("match:", match);

    const page = useMemo(()=>(match
        ? toc.find((page) => page.url === Object.values(match.params).join("/"))
        : toc[0].url), [toc, match]);

    // console.log("root:", root, "page:", page, "toc:", toc);

    return (
        <>
            <Route exact path={root}>
                <Redirect to={`${root}/${lastPage}`} />
            </Route>
            <Route exact path={`${root}/---`}>
                <ContentTOC {...{ root, toc }} />
            </Route>
            <Route exact path={`${root}/${urlPattern}`}>
                {page ? <Page {...{ root, page, toc }} /> : <div>no such page in TOC</div>}
            </Route>
        </>
    );
};

const Load = ({ root, tocFile }) => {
    const toc = useToc(tocFile);
    if (toc.status !== "success") return <div>...</div>;
    return <Lesen {...{ root, toc: toc.data }} />;
};

export default Load;
