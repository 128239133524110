import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getJSON } from "../../../../api";
import { cl, findChild, generateId, getRandomInt } from "../../../../shed";
import { useS, useSlice, useSliceSet } from "../../../../store/store";
import { getParams, valueToTemplate } from "../../lang";
import { useLabel } from "../../useLabel";
import { findIndex, uniq } from "lodash";
import "./persons.scss";
import { Name } from "./Name";
import { Known } from "./Known";
import { Dialog } from "../../../../ui/bui/dialog/Dialog";

export const useNames = (enabled=true) => {
    return useQuery("names",
        () => getJSON("/json/feed/names.json"),
        { refetchOnWindowFocus: false, enabled }
    );
};

const mapNames = nameEntity => {
    // console.log(`mapNames: name:`, nameEntity);
    return (nameEntity.values.map((value, i, array) => {
        // console.log(value);
        const { declension, ...params } = value;
        let label = (Array.isArray(declension)
        ? declension[0] :
        declension);

        let name = label;

        if (nameEntity.values.length > 1) {
            const names = nameEntity.values.map(value => (
                Array.isArray(value.declension)
                ? value.declension[0] : declension
            ));
            if (uniq(names).length !== names.length) {
                label += ` ${["🕺", "💃"][params.gender]}`;
            }
        }
        
        const template = valueToTemplate(nameEntity.entity, params);
        return (
            {
                label,
                name,
                value: template,
                entity: nameEntity,
            }
        )
    }))
};

export const createPersonEntity = (first, second, params) => {
    const entity = `${first.entity} ${second.entity}`;

    const firstValue = first.values.find(value => valueFits(value, params));
    const secondValue = second.values.find(value => valueFits(value, params));

    const firstDeclension = firstValue.declension;
    const secondDeclension = secondValue.declension;

    let merged;

    if (Array.isArray(firstDeclension)) {
        if (Array.isArray(secondDeclension)) {
            merged = firstDeclension.map((first, i) => (
                `${first} ${secondDeclension[i]}`
            ));
        } else {
            merged = firstDeclension.map((first) => (
                `${first} ${secondDeclension}`
            ));
        }
    } else {
        if (Array.isArray(secondDeclension)) {
            merged = secondDeclension.map((second) => (
                `${firstDeclension} ${second}`
            ));
        } else {
            merged = `${firstDeclension} ${secondDeclension}`;
        }
    }

    const values = [
        {
            declension: merged,
            ...params
        }
    ];

    return {
        id: generateId(),
        entity,
        values,
    }
};

const valueFits = (value, params) => Object.entries(params).every(([key, val]) => (
    value[key] === val ||
    !value.hasOwnProperty(key)
));

const Editor = memo(({ names }) => {

    const [first, setFirst] = useState();
    const [second, setSecond] = useState();
    const prompt = useLabel("feed.person editor prompt");

    // console.log(names);

    const firstNames = useMemo(() => ([
        ...names.first.map(n=>({...n, type: "first"})),
        ...names.title.map(n=>({...n, type: "title"}))
    ].filter(n=>n.id && !n.disabled)), [names]);

    const secondNames = useMemo(() => ([
        ...names.second.map(n=>({...n, type: "second"})),
        ...names.last.map(n=>({...n, type: "last"}))
    ].filter(n=>n.id && !n.disabled)), [names]);

    const firstOptions = useMemo(() => {
        // console.log("EDITOR: create firstOptions");
        const mapped = firstNames.map(mapNames).flat(1);
        // console.log("firstName mapped with entities", mapped);
        return mapped;
    }, [firstNames]);

    const secondOptions = useMemo(() => {

        if (!first) return;
        // console.log("---------- create secondOptions -----------");
        // console.log("EDITOR: create secondOptions: first:", first);

        const type = firstNames.find(n => n.entity === first.split(":")[0]).type;
        // console.log("type of first name:", type);
        const params = getParams(first);
        // console.log("params of first name:", params);
        // console.log(secondNames);
        const filterValue = value => (
            valueFits(value, params)
        );

        const fits = secondNames.filter(name => (
            ((type === "title" && name.type === "last") ||
            (type === "first")) &&
            name.values.some(filterValue)
        )).map(name => ({
            ...name, values: name.values.filter(filterValue)
        }));

        /* .map(name => (
            {...name,
                values: (() => {
                    const values = name.values.filter(filterValue);
                    if (params.hasOwnProperty("gender")) return values;
                    if (values.every(value => value.hasOwnProperty("gender"))) return values;
                    const noGenderValues = values
                    .filter(value => !value.hasOwnProperty("gender"));
                    const genderValues = values
                    .filter(value => value.hasOwnProperty("gender"));
                    const usedGenders = genderValues
                    .map(value => value.gender);
                    const missedGenders = [0, 1]
                    .filter(g => !usedGenders.includes(g));

                    const newGenderValues = [];
                    missedGenders.forEach(gender => {
                        noGenderValues.forEach(value => {
                            newGenderValues.push({
                                ...value, gender
                            })
                        });
                    });

                    return [...genderValues, ...newGenderValues];

                })()
            }
        )); */

        // console.log(fits);

        const mapped = fits.map(mapNames).flat(1);
        // console.log("mapped with entities:", mapped);

        // console.log("EDITOR: created secondOptions:", mapped.map(item => item.value));

        // console.log("---------- secondOptions done -----------");

        return mapped;

    }, [first, firstNames, secondNames]);

    const handleFirstChange = useCallback((value) => {
        if (!value) return;
        // console.log(value);
        // console.log("EDITOR: handleFirstChange: set first to:", value);
        setFirst(value);
        // setSecond(null);
    }, []);

    const handleSecondChange = useCallback((value) => {
        // console.log("EDITOR: handleSecondChange: set second to:", value);
        if (!value) return;

        setSecond(value);
    }, []);

    const setPerson = useSliceSet("post", "person");

    useEffect(() => {
        if (first && second && firstOptions && secondOptions) {
            // console.log("EDITOR: current second:", second);
            if (!secondOptions.some(o => o.value === second)) {
                // console.log("EDITOR: secondOptions doesn't contain second:", second);
                return;
            }
            // console.log(`EDITOR: set person to ${first}/${second}`);

            // console.log("======== SET PERSON ============");

            const firstOption = firstOptions.find(o => o.value===first);
            const secondOption = secondOptions.find(o => o.value===second);
            const params = {...getParams(first), ...getParams(second)};
            const entity = createPersonEntity(firstOption.entity, secondOption.entity, params);

            // console.log("new person entity:", entity);

            const person = {
                first: firstOption.name,
                second: secondOption.name,
                first_entity: firstOption.entity.entity,
                second_entity: secondOption.entity.entity,
                params,
                entity
            };

            // console.log("Editor: set person");
            setPerson(person);
        }
    }, [first, second, firstOptions, secondOptions, setPerson]);

    // console.log("Editor: firstOptions:", firstOptions);
    // console.log("Editor: first:", first);

    // if (!(first && second)) return null;

    const personLabel = useMemo(()=> {
        if (firstOptions && secondOptions) {
            const firstOption = firstOptions.find(o => o.value===first);
            const secondOption = secondOptions.find(o => o.value===second);
            const firstLabel = firstOption ? firstOption.label : "";
            const secondLabel = secondOption ? secondOption.label : "";
            return `${firstLabel} ${secondLabel}`;
        } else { return "" }
    }, [first, second, firstOptions, secondOptions]);

    return (
        <div className="personEditor">

            <h2>{prompt}</h2>

            <Dialog {...{ prompt: personLabel }}>

                <Name first {...{ options: firstOptions, handleChange: handleFirstChange, value: first }} />
                { secondOptions && <>
                    <Name second {...{ options: secondOptions, handleChange: handleSecondChange, value: second}} /></>
                }

            </Dialog>

        </div>
    )
    
});

export const Person = memo(() => {

    const user = useS("user");

    const { status, data: names } = useNames();

    if (status !== "success") return null;

    // console.log("Person: user:", user);

    if (user && user.first && user.second) {
        // return null;
        return <Known {...{ user, names }} />
    }

    return (
        <Editor {...{ names }} />
    )
});
