
import { createContext, memo, useEffect } from "react";
import { useQuery } from "react-query";
import { useS, useSlice, useSliceSet } from "../../store/store";
import { Counter, Reaction } from "../foam/Like";
import "./feed.scss";
import NewPost from "./create/NewPost";
import { getPosts } from "./posts";
import { getEntityValuesAndForm } from "./lang";
import { cl, generateId } from "../../shed";
import { Emoji } from "../foam/emoji";
import { Icon } from "../foam/reactions";
import { DeleteButton } from "./delete/DeleteButton";

export const Lang = createContext();


const Likes = ({ postId, your }) => {

    return (
        <div className="likes">
            { postId ? <>
            <Reaction {...{ type: "dislike", object_id: postId, object_desc: "post zhopa", readOnly: your }} />
            <Counter {...{ type: "dislike", object_id: postId, placeholder: `0` }} />
            <Reaction {...{ type: "like", object_id: postId, object_desc: "post roza", readOnly: your }} />
            <Counter {...{ type: "like", object_id: postId, placeholder: `0` }} /></>
            : <>
            <button className="like" disabled><Icon type="dislike" /></button>
            <div className="counter">0</div>
            <button className="like" disabled><Icon type="like" /></button>
            <div className="counter">0</div>
            </>
            }
        </div>
    )
};

export const Post = ({ post, fake }) => {

    // console.log(post);
    const user_id = useSlice("user", "id");

    const person = getEntityValuesAndForm(post.expression_state.defs.person, { declension: 0 }).form

    const your = user_id === post.user_id;

    return (
        <div className={cl({ your }, "post")}>
            <div className="header">
                <div className="person">
                    <Emoji emotion="neutral" />
                    { person }
                </div>
                { !fake && your && <DeleteButton key={post.id} {...{ postId: post.id }} /> }
                <Time date={post.updated || post.created} />
            </div>
            <div className="text">{ post.text }</div>
            <Likes postId={!fake && post.id} {...{ your }} />
        </div>
    )
}

const Time = ({ date }) => {

    const time = `${ ("0" + date.getHours()).slice(-2) }:${ ("0" + date.getMinutes()).slice(-2) }`;

    return (
        <div className="time">{time}</div>
    )
};

const Posts = memo(() => {

    const { status, data: posts } = useQuery(["posts", "last", 20],
        () => getPosts({
            limit: 20,
            order: { updated: "desc", created: "desc" }
        }));
    if (status !== "success") return null;

    // console.log("Posts: posts:", posts);

    return (
        <div className="posts">
            { posts.map((post, i) => (
                <Post key={i} {...{ post }} />
            )) }
        </div>
    )
});

const Feed = memo(() => {

    const user = useS("user");

    // const post = useS("post");

    // if (user && user.first) console.log(`Feed: user ${user.first} ${user.second}`);

    /* useEffect(() => {
        return () => {
            console.log(`%cFeed unmount`, "color:violet");
        }
    }, []); */

    return (
        <Lang.Provider value="ru">
            <div className="feed">
                <NewPost />
                { user && user.first && user.second && <Posts />}
            </div>
        </Lang.Provider>
    )
});

export default Feed;
