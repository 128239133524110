import { createContext, useMemo } from "react";
import { useSlice } from "../../store/store";
import { cl } from "../../shed";
import Foam from "../foam/Foam";
import { Featured } from "../common/Featured";

// let id;

export const Lang = createContext();

const Page = ({ lang, content: spread, left, right, visible }) => {
    
    const content = useMemo(() => (spread[lang]), [spread, lang]);

    const commentsElement = useSlice("elements", "comments");

    const sessionLang = sessionStorage.getItem("lesen lang");


    return (
        <Lang.Provider value={lang}>
            <div className={cl("page", { left, right, invisible: !visible })} lang={lang}>
                <div className="content">{content.data}</div>
                <Foam {...{ object_id: content.id, text: spread.toc_text }} />
                {
                    lang === "ru" && <Featured />
                }
            </div>
            
        </Lang.Provider>
    )
};

export default Page;