import { configureStore } from "@reduxjs/toolkit";
import authorsReducer from "../cms/authorsSlice";
import worksReducer from "../cms/worksSlice";
import textsReducer from "../cms/textsSlice";

import { simpleReducers, slices } from "../features/slicer/asyncSlicer";

const reducer = {
    authors: authorsReducer,
    works: worksReducer,
    texts: textsReducer,
};

slices.forEach(slice => {
    reducer[slice.name] = simpleReducers[`${slice.name}Reducer`];
});

const store = configureStore({ reducer });

export default store;
