
export const getExtraReducers = (thunks, adapter, method="setAll") => {
    const extraReducers = thunks.reduce((obj, fn) => ({
        ...obj,
        [fn.pending]: state => {
            state.status = "loading";
        },
        [fn.fulfilled]: (state, action) => {
            state.status = "succeeded";
            adapter[method](state, action.payload);
        },
        [fn.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        }
    }), {});
    return extraReducers;
};
