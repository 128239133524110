import { isEqual } from "lodash";
import { useCallback, useEffect } from "react";
import { useIsMutating, useMutation, useQuery, useQueryClient } from "react-query";
import { useDeepCompareEffect, usePrevious } from "react-use";
import { getSession, getTable, post2server } from "../../api";
import { getAccessKey } from "../../cms/session";
import { convertDatesToISOStrings, convertStringsToValues, dev, generateId, objType, str2val, stringifyValues } from "../../shed";
import { useS, useSet } from "../../store/store";

export const cookieUserId = {
    get: () => localStorage.getItem("ein_user"),
    set: (id) => localStorage.setItem("ein_user", id),
    remove: () => localStorage.removeItem("ein_user"),
};

export const sessionUser = {
    get: (id) => sessionStorage.getItem(id),
    set: (id, date=(new Date())) => {
        const last_session = objType(date) === "string" ? date : date.toISOString();
        sessionStorage.setItem(id, last_session);
    },
    remove: (id) => sessionStorage.removeItem(id),
}

const createUser = (data) => {
    const request = {
        command: "insert",
        table: "public_users",
        access_key: getAccessKey(),
        data: convertDatesToISOStrings(stringifyValues(data)),
    };
    // return Promise.resolve({});
    return post2server(request);
};

const deleteUser = (data) => {
    const request = {
        command: "delete",
        table: "public_users",
        access_key: getAccessKey(),
        data
    };
    // return Promise.resolve({});
    return post2server(request);
};

export const updateUser = (data) => {

    const stringified = stringifyValues(data);

    // console.log("data stringified:", stringified);

    const request = {
        command: "update",
        table: "public_users",
        access_key: getAccessKey(),
        data: convertDatesToISOStrings(stringified),
    };
    // console.log(request);
    
    return post2server(request);
};

export const getUserById = (id) => (
    getTable({
        table: "public_users",
        where: { id },
        access_key: getAccessKey()
    })
    .then(data => {
        // console.log(data);
        if (data.length===0) return;
        return convertStringsToValues(data[0]);
    })
);

export const getUser = (newValues) => {

    const user_id = localStorage.getItem("ein_user");
    const session = sessionStorage.getItem(user_id);

    if (!user_id) {
        // console.log("no user cookie found");
        const id = generateId();
        const created = new Date().toISOString();
        const last_session = new Date().toISOString();
        // console.log("create user with id", id);
        
        console.log("no user found, create a new one");
        return (
            createUser({
                id, created, last_session, dev: dev(),
                type: "auto", access_key: generateId(), ...(newValues || {})
            })
            .then(response => {
                // console.log(`response from createUser`);
                cookieUserId.set(id);
                sessionUser.set(id, last_session);
                return response;
            })
        )
    }

    return (
        getTable({
            table: "public_users", access_key: getAccessKey(), where: { id: user_id }
        })
        .then(res => {
            // console.log(res);
            const last_session = new Date().toISOString();
            if (res.length === 0) {
                // console.log(`no user for cookie`);
                const id = user_id;
                const created = new Date().toISOString();
                console.log("re-create user with id", id);
                return (
                    createUser({ 
                        id, created, last_session, dev: dev(),
                        type: "auto", access_key: generateId(), ...(newValues || {})
                    })
                    .then(response => {
                        // console.log(`response from createUser`);
                        sessionUser.set(id, last_session);
                        return response;
                    })
                )
            }
            const user = res[0];

            let updateValues;

            if (!session) {
                // create truthy object, set last_session later
                updateValues = { };
            }

            if (newValues) {
                Object.entries(newValues).forEach(([key, val]) => {
                    if (
                        !user.hasOwnProperty(key) ||
                        (user[key] !== val && str2val(user[key]) !== val)
                    ) {
                        if (!updateValues) updateValues = {};
                        updateValues[key] = val;
                    }
                });
            }

            if (updateValues) {
                if (updateValues.hasOwnProperty("id")) console.log("existing user's updated columns should not contain id, it will be ignored");
                return (
                    updateUser({...updateValues, last_session, id: user.id }, "getUser")
                    .then(response => {
                        // console.log(`response from updateUser`);
                        sessionUser.set(user_id, last_session);
                        return response;
                    })                
                )
            }

            return user;
        })
    );
};


export const useUserFromCookie = () => {

    // if no user slice, read id from cookie, check the db
    // if db.user exists, set it to slice and refresh db.last_session

    // const qc = useQueryClient();

    const cookie_user_id = cookieUserId.get();

    const session = useCallback(() => sessionStorage.getItem(cookie_user_id), [cookie_user_id]);

    const setUser = useSet("user");

    const user = useS("user");

    const userQuery = useQuery("ein_user", () => getUserById(cookie_user_id),
    {
        refetchOnWindowFocus: false,
        enabled: !!cookie_user_id,
        // onSuccess: (data) => { console.log("ein_user data fetched", data); }
    });

    // const isMutating = useIsMutating({ mutationKey: ["user", "update"] });

    const mutationUpdate = useMutation(
        updateUser,
        {
            // mutationKey: ["user", "update"],
            onSuccess: (response, request) => {
                sessionStorage.setItem(cookie_user_id, request.last_session);
            }
        }
    );
    useDeepCompareEffect(() => {
        if (cookie_user_id && userQuery.status==="success" && userQuery.data && !session() && mutationUpdate.isIdle) {
            // console.log("users exists, no session");
            const last_session = new Date().toISOString();
            sessionStorage.setItem(cookie_user_id, last_session);
            // console.log("create session for id", user_id, "value:", last_session);
            // console.log("data to mutate:", user.data);

            mutationUpdate.mutate({ id: userQuery.data.id, last_session });
        }
    }, [mutationUpdate, cookie_user_id, cookie_user_id, userQuery.data, userQuery.status, session]);

    // console.log("status:", user.status, "\ndata:", user.data);

    useDeepCompareEffect(() => {
        if (userQuery.status==="success") {
            if (userQuery.data) {
                // console.log("userQuery got data", userQuery.data);
                if (!user) {
                    // console.log(`no user, set user to data`);
                    setUser(userQuery.data);
                    return;
                }
                if (user.id !== userQuery.data.id) {
                    // console.log("id differs, replace user with data");
                    setUser(userQuery.data);
                    return;
                }
                if (user.remove) return;
                if (isEqual(user, userQuery.data)) return;
                // console.log("same id, merge user with data");
                setUser({...user, ...userQuery.data });
            } else {
                // console.log("no userQuery data");
                // console.log(user);
                /* if (user) {
                    console.log("refetch");
                    qc.fetchQuery("ein_user", getUserById(user.id));
                } */
            }
        }
    }, [userQuery, setUser, user]);

};
