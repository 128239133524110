import { memo, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getJSON } from "../../api";
import { cl } from "../../shed";
import "./scribbles.scss";

let tid;

export const Scribble = memo(({ name }) => {

    const [scribble, set] = useState();

    useEffect(() => {
        getJSON("/json/scribbles.json")
        .then(response => {
            set(response.find(s => s.entity === name));
        })
    }, [name]);

    if (!scribble) return null;

    const { viewBox, strokeWidth, paths } = scribble;

    return (
        <div className={cl("scribble", name)}>
            <svg className="scribble" viewBox={viewBox}>
                <g className="paths" style={{ strokeWidth }}>
                    { paths.map((path, i) => (
                        <path key={i} d={path} />
                    )) }
                </g>
            </svg>
        </div>
    )
})

export const ScribbleOn = memo(({ name, className, onElement, delta, angle, width, height }) => {

    const { status, data: scribble } = useQuery(["scribble", name],
    () => getJSON("/json/scribbles.json")
    .then(response => {
        // console.log(response);
        return response.find(s => s.entity === name)
    }),
    { refetchOnWindowFocus: false });

    const [show, set] = useState(false);

    useEffect(()=>{
        clearTimeout(tid);
        tid = setTimeout(() => {
            console.log("show scribble");
            set(true);
        }, 500);
        return () => { clearTimeout(tid); }
    });

    if (status !== "success") return null;

    if (!show) return null;

    const rect = onElement.getBoundingClientRect();

    let { x, y } = rect;
    x += window.scrollX;
    y += window.scrollY;
    if (delta) {
        x += delta.x;
        y += delta.y;
    }

    console.log(x, y);

    const style = {
        position: "absolute",
        left: x, top: y,
        zIndex: 200,
        width, height,
    };
    if (angle) {
        style.transform = `rotate(${angle}deg)`;
        style.transformOrigin = "top left";
    }

    console.log(style);

    const { viewBox, strokeWidth, paths } = scribble;

    return (
        <div className={cl("scribble", className)} {...{ style }}>
            <svg className="scribble" viewBox={viewBox}>
                <g className="paths" style={{ strokeWidth }}>
                    { paths.map((path, i) => (
                        <path key={i} d={path} />
                    )) }
                </g>
            </svg>
        </div>
    )
});