import { memo } from "react";
import useStore, { useS, useSlice } from "../../../store/store";
import { Post } from "../Feed";


export const Preview = memo(() => {

    const postReady = useS("post");
    const user_id = useSlice("user", "id");

    /* const post = {
        expression_state: { defs: { person } }
    }; */

    // console.log("Preview: postReady:", postReady);

    const { expressionId } = postReady;

    const text = useStore(state => state[expressionId] && state[expressionId].text);



    const post = {
        id: postReady.id,
        text, user_id,
        created: new Date(),
        expression_state: { defs: { person: postReady.person.entity } }
    }

    // console.log("Preview: post:", post);


    return (
        <div className="preview">
            <h2>как это будет выглядеть в&nbsp;ленте:</h2>
            <Post fake {...{ post }} />
        </div>
    )
});
