import { memo } from "react";
import { useSlice } from "../../../store/store";
import "./Menu.scss";
import { Level } from "./Menu2Level";


const levels = [
    {
        name: "emotions", key: "emotion", dataKey: "emotions",
        promptKey: "feed.what"
    },
    {
        name: "subjects", key: "subject", dataKey: "subjects",
        promptKey: "feed.emotion cause",
        filter: { key: "emotion", dataKey: "emotions" }
    },
    // { name: "subjects", key: "subject", dataKey: "subjects", prompt: "feed.emotions cause" },
];

export const Menu = memo(() => {

    // const data = useSlice("post", "data");

    // console.log(data);

    // const status = useSlice("post", "status");

    // console.log("Menu: post status:", status);

    return (
        <div className="menu">
            { levels.map((level, i) => {
                return <Level key={i} {...{ level }} />
            }) }
        </div>
    )
});
