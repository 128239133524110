import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./app/store";
import getTheme from "./theme";
import "./index.css";

import { simpleFetchers as f, simpleActions as a } from "./features/slicer/asyncSlicer";
// import { setSessionKey } from "./cms/session";

export const required = ["config", "labels"];

required.map(slice => f[slice]).forEach(action => { store.dispatch(action()) });

store.dispatch(a.random.update({ css: Math.random() }));

document.documentElement.classList.add(getTheme().rootClassName);

// setSessionKey();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
