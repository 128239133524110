import { entities2unicode, objType } from "../../shed";
import { parse } from "./express";
import { rx } from "./rx";

export const getEntityValuesAndForm = (entity, params) => {

    // console.log(entity);
    // console.log(params);

    const values = entity.values.find(v =>
        Object.entries(params).every(([key, val]) => (
            Array.isArray(v[key])
            ? v[key].length-1 >= val
            : objType(v[key])==="string" // only one form exists
            ? true
            :v[key] === val
        ))
    );

    if (!values) {
        // console.log(values);
        return null;
    } else {
        // console.log(values);
    }

    const key = Object.keys(values).find(key => (
        Array.isArray(values[key]) || objType(values[key])==="string"
    ));

    const form = objType(values[key])==="string" ? values[key] : values[key][params[key]];

    const res = { values, form }

    // console.log(res);

    return res;

};

/* export const findEntityByTemplate = (entities, template) => {
    const [entityName, paramsTemplate] = template.split(":");
    if (!paramsTemplate) return entities.find(e => e.entity === entityName);
    const params = getParams(paramsTemplate);
    const entity = entities.find(e => e.entity===entityName);
    if (!entity) return null;
    return {  }
    
    
    (
        Object.entries(params).every(([key, val]) => )
    ))
}; */

export const getParams = (template) => {

    if (!template) {
        console.log(`Warning: getParams template is falsy`, template);
        return {};
    }

    const test = {
        "c": "count",
        "d": "declension",
        "g": "gender"
    };
    const rx = new RegExp(`[${Object.keys(test).join("")}]\\d+`, "g");

    // console.log(rx);

    const matches = template.match(rx);

    if (!matches) return {};

    return matches.reduce((obj, s) => (
        {
            ...obj,
            [test[s[0]]]: parseInt(s.substr(1))
        }
    ), {})

};

export const getDefValue = (def, paramsTemplate) => {

    const params = getParams(paramsTemplate);

    // console.log(params);

    const { values, form } = getEntityValuesAndForm(def, params);

    return { value: form, params: {...values, ...params } };
}

export const valueToTemplate = (entity, params) => {
    const paramsCode = (
        Object.entries(params).map(([key, val]) => (
            key[0] + val
        )).join("")
    );
    return entity + (paramsCode ? `:${paramsCode}` : "");
};

export const template2text = (dataObj, template) => {

    const text = /\[[^\]]+\]/.test(template)
    ? (()=>{
        const { components, template: parsedTemplate } = parse(template);
        // console.log("parse:");
        // console.log(`components:`, components);
        // console.log(`template:`, parsedTemplate);

        const res = parsedTemplate.map(str => {
            if (/^%C\d+$/.test(str)) {
                const template = components.find(c=>c.name===str.substr(1)).template;
                const { value: { name, params } } = rx.getObject(template.replace(/[\[\]]/g, ""));
                // console.log(name);
                if (dataObj[name]) {
                    return getEntityValuesAndForm(dataObj[name], getParams(params)).form;
                }
                return template;
            }
            return str;
        });

        // console.log(res);

        return entities2unicode(res.join(""));
    })()
    : entities2unicode(template);

    return text;

};