import { entities2unicode } from "../../shed";
import { Emoji } from "../foam/emoji";
import { BigRedStar } from "./graphics";

export const Pos = ({ num }) => {
    return <div className="pos">{
        num===1
        ? (<>
            <BigRedStar />
            <div className="first"><span>{num}</span></div>
        </>)
        : (
            <>
            <svg className="round">
                <circle cx="50%" cy="50%" r="40%" />
            </svg>
            <div>{num}</div>
        </>
        )
    }</div>;
};

export const Count = ({ count }) => {
    return <div className="count">{count}</div>;
};

export const Name = ({ name }) => {
    return <div className="name">{entities2unicode(name)}</div>;
};

export const Type = ({ type, title }) => {
    return (
        <div className="type" title={title}>
            <Emoji emotion={type} />
        </div>
    );
};
