
function Image() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 220 157"
      className="featured"
    >
      <path fill="none" d="M0 0H220V157H0z"></path>
      <clipPath id="_clip1">
        <path d="M0 0H220V157H0z"></path>
      </clipPath>
      <g clipPath="url(#_clip1)">
        <path fill="none" d="M83 157V47h137V0H0v157h83z"></path>
        <clipPath id="_clip2">
          <path d="M83 157V47h137V0H0v157h83z"></path>
        </clipPath>
        <g clipPath="url(#_clip2)">
          <text
            x="800.357"
            y="672.951"
            fill="#A81F65"
            fontFamily="'Inter-ExtraBold', 'Inter'"
            fontSize="43.547"
            fontWeight="800"
            transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(-99.584 800.358 672.953)"
          >
            Н
          </text>
          <path className="outline"
            d="M801.057 672.951c0 .938.76 1.698 1.697 1.698h7.655c.937 0 1.697-.76 1.697-1.698v-11.029h9.085v11.029c0 .938.76 1.698 1.697 1.698h7.64c.937 0 1.697-.76 1.697-1.698v-31.67c0-.938-.76-1.698-1.697-1.698h-7.64c-.937 0-1.697.76-1.697 1.698v11.014h-9.085v-11.014c0-.938-.76-1.698-1.697-1.698h-7.655c-.937 0-1.697.76-1.697 1.698v31.67zm1.697 0h7.655v-12.727h12.479v12.727h7.64v-31.67h-7.64v12.711h-12.479v-12.711h-7.655v31.67z"
            transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(-99.584 800.358 672.953)"
          ></path>
          <text
            x="795.471"
            y="643.741"
            fill="#952470"
            fontFamily="'Inter-ExtraBold', 'Inter'"
            fontSize="42.096"
            fontWeight="800"
            transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(-87.649 795.471 643.742)"
          >
            о
          </text>
          <path className="outline"
            d="M808.506 645.872c4.136 0 7.442-1.41 9.731-3.789 2.275-2.365 3.567-5.721 3.567-9.748 0-4.035-1.292-7.394-3.567-9.761-2.289-2.381-5.594-3.791-9.731-3.791s-7.443 1.41-9.732 3.791c-2.274 2.367-3.566 5.726-3.566 9.761 0 4.027 1.292 7.383 3.567 9.748 2.289 2.379 5.594 3.789 9.731 3.789zm0-1.698c7.22 0 11.6-4.768 11.6-11.839 0-7.086-4.38-11.854-11.6-11.854s-11.6 4.768-11.6 11.854c0 7.071 4.38 11.839 11.6 11.839zm.045-5.516c-2.706 0-4.201-2.571-4.201-6.368 0-3.812 1.495-6.398 4.201-6.398 2.616 0 4.111 2.586 4.111 6.398 0 3.797-1.495 6.368-4.111 6.368zm0-1.697c-.924 0-1.556-.587-1.949-1.476-.378-.855-.554-1.946-.554-3.195 0-1.256.176-2.354.555-3.215.393-.893 1.023-1.485 1.948-1.485.695 0 1.224.373 1.596.97.563.903.817 2.199.817 3.73 0 1.523-.253 2.81-.815 3.706-.373.595-.903.965-1.598.965z"
            transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(-87.649 795.471 643.742)"
          ></path>
          <text
            x="796.33"
            y="619.321"
            fill="#6B2875"
            fontFamily="'Inter-ExtraBold', 'Inter'"
            fontSize="40.644"
            fontWeight="800"
            transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(-76.131 796.333 619.323)"
          >
            в
          </text>
          <path className="outline"
            d="M796.87 619.321c0 .938.76 1.698 1.698 1.698h11.055c7.159 0 10.805-3.016 10.805-7.788 0-2.233-1.076-4.419-3.374-5.773 1.337-1.146 2.018-2.641 2.018-4.273 0-4.783-3.998-7.73-10.935-7.73h-9.569c-.938 0-1.698.759-1.698 1.697v22.169zm1.698 0h11.055c5.889 0 9.108-2.165 9.108-6.09 0-2.728-2.194-5.182-6.697-5.427 3.406-.578 5.34-2.324 5.34-4.619 0-3.94-3.522-6.033-9.237-6.033h-9.569v22.169zm6.567-5.109v-4.171h4.488c1.487 0 2.411.823 2.411 2.165 0 1.256-.924 2.006-2.411 2.006h-4.488zm0-7.996v-3.825h3.002c1.631 0 2.641.722 2.641 1.891 0 1.212-.924 1.934-2.454 1.934h-3.189zm1.697 6.299v-.777h2.791c.392 0 .713.115.713.468 0 .311-.344.309-.713.309h-2.791zm0-7.996v-.43h1.305c.285 0 .538.028.752.1.084.028.192.013.192.093 0 .078-.057.123-.13.15a1.864 1.864 0 01-.627.087h-1.492z"
            transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(-76.131 796.333 619.323)"
          ></path>
          <text
            x="801.476"
            y="597.824"
            fill="#532C69"
            fontFamily="'Inter-ExtraBold', 'Inter'"
            fontSize="39.192"
            fontWeight="800"
            transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(-64.792 801.474 597.822)"
          >
            а
          </text>
          <path className="outline"
            d="M805.336 584.726c-2.654 1.231-4.403 3.455-4.403 7.169 0 2.148.634 3.844 1.682 5.12 1.53 1.861 4.012 2.868 7.044 2.868 1.957 0 3.586-.419 4.902-1.215.293.51.843.853 1.473.853h6.402c.937 0 1.697-.76 1.697-1.697v-14.53c0-5.433-4.626-8.824-11.161-8.824-6.837 0-10.583 3.62-11.229 8.255a1.699 1.699 0 001.621 1.931l1.972.07zm10.698 10.499v2.599h6.402v-14.53c0-4.524-4.022-7.126-9.464-7.126-5.748 0-9.005 2.895-9.548 6.791l6.277.223c.292-1.364 1.42-2.199 3.215-2.199 1.67 0 2.728.807 2.728 2.241v.07c0 .617-.316 1.005-1.034 1.276a1.69 1.69 0 01-.469-.556l-.033.002c.107.228.259.422.442.576-.806.29-2.094.443-3.972.608-4.328.362-7.947 1.963-7.947 6.695 0 4.231 2.937 6.291 7.028 6.291 2.752 0 4.72-.987 5.925-2.861l.45-.1zm-4.273-1.493c-1.573 0-2.686-.752-2.686-2.171 0-1.378 1.085-2.213 3.02-2.506 1.266-.18 2.825-.459 3.591-.862v2.032c0 2.087-1.754 3.507-3.925 3.507zm2.212-3.278c-.546.112-1.107.206-1.627.28-.519.079-.953.195-1.28.39-.175.105-.294.239-.294.437 0 .45.49.473.989.473 1.102 0 2.082-.581 2.212-1.58zm-.07-7.445c-.128-.281-.533-.328-.987-.328-.765 0-1.342.205-1.523.739.893-.088 1.608-.179 2.163-.307.111-.025.239-.066.347-.104zm.045.266l-.001.019c0 .249.055.496.161.722l-.027-.13a1.693 1.693 0 01-.133-.611z"
            transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(-64.792 801.474 597.822)"
          ></path>
          <text
            x="811.413"
            y="577.056"
            fill="#532C69"
            fontFamily="'Inter-ExtraBold', 'Inter'"
            fontSize="37.741"
            fontWeight="800"
            transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(-51.795 811.414 577.056)"
          >
            я
          </text>
          <path className="outline"
            d="M823.748 577.056c0 .938.76 1.698 1.697 1.698h6.098c.938 0 1.698-.76 1.698-1.698V556.47c0-.937-.76-1.697-1.698-1.697h-9.422c-6.793 0-10.877 3.72-10.838 8.667-.021 2.529 1.134 4.677 3.269 6.15l-3.479 6.682a1.698 1.698 0 001.506 2.482h6.138c.633 0 1.213-.352 1.505-.913l3.237-6.204h.289v5.419zm1.697 0h6.098V556.47h-9.422c-5.629 0-9.18 2.869-9.14 6.97-.027 2.425 1.38 4.369 3.833 5.481l-4.235 8.135h6.138l3.713-7.116h3.015v7.116zm0-11.271h-3.27c-2.024 0-3.016-1.005-2.989-2.131-.027-1.086.952-2.185 2.935-2.185h3.324v4.316zm-1.697-1.697h-1.573c-.464 0-.839-.063-1.114-.215-.09-.05-.18-.094-.178-.179a1.12 1.12 0 000-.082c-.002-.099.1-.163.208-.227.255-.151.602-.218 1.03-.218h1.627v.921z"
            transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(-51.795 811.414 577.056)"
          ></path>
          <text
            x="830.524"
            y="554.601"
            fill="#8C06FB"
            fontFamily="'Inter-ExtraBold', 'Inter'"
            fontSize="34.838"
            fontWeight="800"
            transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(-34.805 830.524 554.6)"
          >
            в
          </text>
          <path className="outline"
            d="M830.744 554.601c0 .937.76 1.697 1.698 1.697h9.476c6.318 0 9.504-2.706 9.504-6.918 0-1.899-.869-3.77-2.73-4.987 1.041-.999 1.567-2.261 1.567-3.624 0-4.22-3.493-6.868-9.615-6.868h-8.202c-.938 0-1.698.76-1.698 1.697v19.003zm1.698 0h9.476c5.048 0 7.807-1.856 7.807-5.221 0-2.338-1.881-4.441-5.741-4.652 2.92-.495 4.578-1.992 4.578-3.959 0-3.377-3.019-5.171-7.918-5.171h-8.202v19.003zm5.629-4.38v-3.575h3.847c1.275 0 2.066.705 2.066 1.855 0 1.077-.791 1.72-2.066 1.72h-3.847zm4.213-1.751a1.835 1.835 0 01-.366.054h-2.15v-.181h2.15c.172 0 .343-.007.366.127zm-4.213-5.103v-3.278h2.573c1.398 0 2.264.619 2.264 1.621 0 1.039-.792 1.657-2.103 1.657h-2.734z"
            transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(-34.805 830.524 554.6)"
          ></path>
          <text
            x="845.723"
            y="543.848"
            fill="#DC00CD"
            fontFamily="'Inter-ExtraBold', 'Inter'"
            fontSize="33.386"
            fontWeight="800"
            transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(-24.639 845.723 543.847)"
          >
            о
          </text>
          <path className="outline"
            d="M856.061 545.889c3.389 0 6.095-1.163 7.971-3.112 1.862-1.936 2.927-4.679 2.927-7.975 0-3.302-1.065-6.048-2.927-7.985-1.875-1.951-4.581-3.114-7.971-3.114-3.39 0-6.096 1.163-7.971 3.114-1.862 1.937-2.927 4.683-2.927 7.985 0 3.296 1.065 6.039 2.927 7.975 1.876 1.949 4.582 3.112 7.971 3.112zm0-1.697c5.726 0 9.2-3.782 9.2-9.39 0-5.62-3.474-9.402-9.2-9.402-5.726 0-9.2 3.782-9.2 9.402 0 5.608 3.474 9.39 9.2 9.39zm.036-4.375c-2.146 0-3.332-2.039-3.332-5.051 0-3.023 1.186-5.074 3.332-5.074 2.074 0 3.26 2.051 3.26 5.074 0 3.012-1.186 5.051-3.26 5.051zm0-1.698c-.589 0-.975-.395-1.225-.961-.283-.64-.409-1.457-.409-2.392 0-.94.127-1.763.41-2.408.251-.569.635-.969 1.224-.969.556 0 .911.405 1.153.962.281.646.409 1.472.409 2.415 0 .938-.127 1.757-.408 2.399-.241.554-.598.954-1.154.954z"
            transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(-24.639 845.723 543.847)"
          ></path>
          <text
            x="863.357"
            y="535.821"
            fill="#F1C52B"
            fontFamily="'Inter-ExtraBold', 'Inter'"
            fontSize="31.935"
            fontWeight="800"
            transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(-16.157 863.358 535.822)"
          >
            з
          </text>
          <path className="outline"
            d="M868.131 525.078c-.005.05-.007.1-.007.151v3.413c0 .13.014.257.043.378h-3.88a1.699 1.699 0 00-1.696 1.756c.155 4.49 3.926 7.071 9.351 7.071 5.588 0 9.329-2.755 9.329-6.846 0-1.882-.748-3.354-1.911-4.342.979-.946 1.469-2.169 1.469-3.562 0-3.966-3.204-6.619-8.887-6.619-5.059 0-8.847 2.78-8.876 6.89a1.698 1.698 0 001.697 1.71h3.368zm-3.844 5.64c.125 3.595 3.312 5.432 7.655 5.432 4.445 0 7.632-1.894 7.632-5.149 0-2.506-1.894-3.958-4.071-4.082v-.182c2.336-.533 3.629-1.826 3.629-3.64 0-3.119-2.722-4.922-7.19-4.922-3.992 0-7.156 1.962-7.179 5.205h5.285c.011-.884.907-1.508 2.132-1.508 1.236 0 2.053.715 2.053 1.758 0 .918-.794 1.599-2.076 1.599h-2.336v3.413h2.336c1.293 0 2.189.76 2.189 1.849 0 1.191-1.032 1.905-2.415 1.905-1.373 0-2.337-.658-2.359-1.678h-5.285zm6.974-.158c.121.041.433.139.67.139.2 0 .386-.024.547-.083.077-.028.171-.043.171-.125 0-.049-.051-.068-.097-.086a1.04 1.04 0 00-.395-.065h-.93a1.6 1.6 0 01.034.22z"
            transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(-16.157 863.358 535.822)"
          ></path>
          <text
            x="879.473"
            y="530.89"
            fill="#7FBBFD"
            fontFamily="'Inter-ExtraBold', 'Inter'"
            fontSize="29.757"
            fontWeight="800"
            transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(-7.195 879.473 530.884)"
          >
            м
          </text>
          <path className="outline"
            d="M887.639 531.346l.046.127a1.698 1.698 0 001.595 1.114h3.318c.707 0 1.34-.438 1.589-1.1l.285-.76v.163c0 .937.76 1.697 1.697 1.697h4.83c.937 0 1.697-.76 1.697-1.697v-16.231c0-.938-.76-1.698-1.697-1.698h-6.087c-.71 0-1.345.442-1.592 1.107l-2.351 6.342-2.257-6.322a1.697 1.697 0 00-1.599-1.127h-6.002c-.937 0-1.697.76-1.697 1.698v16.231c0 .937.76 1.697 1.697 1.697h4.893c.779 0 1.436-.525 1.635-1.241zm3.3-5.972l-3.826-10.715h-6.002v16.231h4.893v-8.951l3.276 8.951h3.318l3.571-9.511v9.511h4.83v-16.231h-6.087l-3.973 10.715z"
            transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(-7.195 879.473 530.884)"
          ></path>
          <g>
            <text
              x="901.482"
              y="528.225"
              fill="#6568FF"
              fontFamily="'Inter-ExtraBold', 'Inter'"
              fontSize="29.031"
              fontWeight="800"
              transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(1.747 901.49 528.212)"
            >
              о
            </text>
            <path className="outline"
              d="M910.472 530.222c3.016 0 5.421-1.04 7.09-2.775 1.656-1.72 2.607-4.158 2.607-7.088 0-2.935-.951-5.375-2.606-7.096-1.669-1.737-4.075-2.777-7.091-2.777-3.017 0-5.423 1.04-7.092 2.777-1.655 1.721-2.606 4.161-2.606 7.096 0 2.93.951 5.368 2.607 7.088 1.669 1.735 4.075 2.775 7.091 2.775zm0-1.698c4.979 0 8-3.288 8-8.165 0-4.887-3.021-8.175-8-8.175-4.98 0-8.001 3.288-8.001 8.175 0 4.877 3.021 8.165 8.001 8.165zm.03-3.804c-1.866 0-2.896-1.773-2.896-4.392 0-2.629 1.03-4.412 2.896-4.412 1.805 0 2.836 1.783 2.836 4.412 0 2.619-1.031 4.392-2.836 4.392zm0-1.697c-.42 0-.683-.3-.862-.704-.235-.533-.337-1.213-.337-1.991 0-.783.102-1.468.338-2.005.18-.407.44-.71.861-.71.395 0 .629.309.8.703.235.539.338 1.227.338 2.012 0 .78-.103 1.463-.336 1.998-.172.392-.407.697-.802.697z"
              transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(1.747 901.49 528.212)"
            ></path>
          </g>
          <g>
            <text
              x="917.509"
              y="528.318"
              fill="#936DF6"
              fontFamily="'Inter-ExtraBold', 'Inter'"
              fontSize="26.128"
              fontWeight="800"
              transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(10.91 917.506 528.318)"
            >
              ж
            </text>
            <path className="outline"
              d="M925.24 528.365c.025.916.774 1.65 1.696 1.65h4.537c.917 0 1.665-.728 1.696-1.637l.501.822c.308.506.857.815 1.45.815h5.279a1.697 1.697 0 001.436-2.603l-4.114-6.527 4.063-5.851a1.698 1.698 0 00-1.394-2.666h-5.159a1.7 1.7 0 00-1.443.803l-.617.996v-.101c0-.938-.76-1.698-1.698-1.698h-4.537c-.938 0-1.698.76-1.698 1.698v.116l-.627-1.011a1.697 1.697 0 00-1.442-.803h-5.159a1.698 1.698 0 00-1.393 2.667l4.07 5.85-4.121 6.526a1.697 1.697 0 001.435 2.604h5.279c.592 0 1.141-.308 1.449-.813l.511-.837zm6.233-4.238v-1.191h.371l3.276 5.382h5.279l-4.713-7.479 4.704-6.773h-5.159l-3.35 5.4h-.408v-5.4h-4.537v5.4h-.418l-3.349-5.4h-5.159l4.714 6.773-4.723 7.479h5.279l2.84-4.654.322.266a8.571 8.571 0 01-.235-.408l.358-.586h.371v5.382h4.537v-4.022l.156.069a9.398 9.398 0 01-.156-.238z"
              transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(10.91 917.506 528.318)"
            ></path>
          </g>
          <g>
            <text
              x="939.395"
              y="532.792"
              fill="#A3A3A3"
              fontFamily="'Inter-ExtraBold', 'Inter'"
              fontSize="24.677"
              fontWeight="800"
              transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(20.716 939.395 532.793)"
            >
              н
            </text>
            <path className="outline"
              d="M946.508 519.332c0-.938-.76-1.698-1.697-1.698h-4.057c-.938 0-1.698.76-1.698 1.698v13.46c0 .937.76 1.697 1.698 1.697h4.057c.937 0 1.697-.76 1.697-1.697v-3.438h.593v3.438c0 .937.76 1.697 1.697 1.697h4.031c.938 0 1.698-.76 1.698-1.697v-13.46c0-.938-.76-1.698-1.698-1.698h-4.031c-.937 0-1.697.76-1.697 1.698v3.358h-.593v-3.358zm-1.697 0h-4.057v13.46h4.057v-5.136h3.987v5.136h4.031v-13.46h-4.031v5.056h-3.987v-5.056z"
              transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(20.716 939.395 532.793)"
            ></path>
          </g>
          <g>
            <text
              x="952.522"
              y="537.659"
              fill="#FF8181"
              fontFamily="'Inter-ExtraBold', 'Inter'"
              fontSize="24.677"
              fontWeight="800"
              transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(29.208 952.522 537.66)"
            >
              о
            </text>
            <path className="outline"
              d="M960.163 539.611c2.643 0 4.748-.917 6.21-2.437 1.449-1.506 2.288-3.637 2.288-6.201 0-2.569-.839-4.702-2.287-6.209-1.462-1.521-3.568-2.438-6.211-2.438-2.643 0-4.749.917-6.211 2.438-1.448 1.507-2.287 3.64-2.287 6.209 0 2.564.839 4.695 2.288 6.201 1.462 1.52 3.567 2.437 6.21 2.437zm0-1.698c4.233 0 6.8-2.795 6.8-6.94 0-4.154-2.567-6.949-6.8-6.949s-6.8 2.795-6.8 6.949c0 4.145 2.567 6.94 6.8 6.94zm.026-3.233c-1.586 0-2.462-1.508-2.462-3.733 0-2.235.876-3.751 2.462-3.751 1.534 0 2.41 1.516 2.41 3.751 0 2.225-.876 3.733-2.41 3.733zm0-1.698c-.253 0-.392-.203-.5-.446-.188-.425-.265-.969-.265-1.589 0-.625.078-1.174.266-1.602.108-.245.245-.452.499-.452.234 0 .345.211.447.445.187.43.266.981.266 1.609 0 .623-.079 1.169-.265 1.596-.101.231-.215.439-.448.439z"
              transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(29.208 952.522 537.66)"
            ></path>
          </g>
          <g>
            <text
              x="965.023"
              y="544.616"
              fill="#FF4372"
              fontFamily="'Inter-ExtraBold', 'Inter'"
              fontSize="23.225"
              fontWeight="800"
              transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(37.841 965.022 544.615)"
            >
              с
            </text>
            <path className="outline"
              d="M979.109 538.29a1.704 1.704 0 00.683-1.411c-.118-4.059-3-6.794-7.586-6.794-2.52 0-4.524.883-5.916 2.337-1.377 1.439-2.173 3.47-2.173 5.901 0 2.421.791 4.45 2.167 5.889 1.39 1.454 3.396 2.34 5.93 2.34h.001c2.265 0 4.084-.705 5.371-1.862 1.337-1.202 2.135-2.909 2.205-4.981a1.7 1.7 0 00-.682-1.419zm-6.895 6.565c3.563 0 5.774-2.07 5.881-5.204h-3.769c-.149 1.344-.957 2.078-2.062 2.078-1.419 0-2.342-1.196-2.342-3.448 0-2.235.932-3.431 2.342-3.431 1.155 0 1.905.784 2.062 2.079h3.769c-.091-3.118-2.367-5.147-5.889-5.147-3.983 0-6.392 2.656-6.392 6.541 0 3.868 2.392 6.532 6.4 6.532zm1.097-6.565a1.693 1.693 0 01-.67-1.157c-.038-.308-.103-.585-.377-.585-.185 0-.31.124-.399.305-.176.361-.246.843-.246 1.428 0 .594.07 1.082.246 1.447.088.181.214.303.399.303.257 0 .34-.255.375-.567.053-.484.307-.902.672-1.174z"
              transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(37.841 965.022 544.615)"
            ></path>
          </g>
          <g>
            <text
              x="974.731"
              y="552.086"
              fill="#006EFF"
              fontFamily="'Inter-ExtraBold', 'Inter'"
              fontSize="21.774"
              fontWeight="800"
              transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(45.337 974.73 552.085)"
            >
              т
            </text>
            <path className="outline"
              d="M973.629 543.101c0 .938.76 1.698 1.698 1.698h2.052v7.287c0 .937.76 1.697 1.698 1.697h3.587c.938 0 1.698-.76 1.698-1.697v-7.287h2.099c.937 0 1.697-.76 1.697-1.698v-2.892c0-.937-.76-1.697-1.697-1.697h-11.134c-.938 0-1.698.76-1.698 1.697v2.892zm1.698 0h3.75v8.985h3.587v-8.985h3.797v-2.892h-11.134v2.892z"
              transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(45.337 974.73 552.085)"
            ></path>
          </g>
          <g>
            <text
              x="982.741"
              y="560.091"
              fill="#767676"
              fontFamily="'Inter-ExtraBold', 'Inter'"
              fontSize="20.322"
              fontWeight="800"
              transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(52.756 982.742 560.091)"
            >
              ь
            </text>
            <path className="outline"
              d="M988.898 550.513v-1.507c0-.937-.76-1.697-1.697-1.697h-3.342c-.937 0-1.697.76-1.697 1.697v11.085c0 .937.76 1.697 1.697 1.697h5.434c4.205 0 6.571-2.303 6.591-5.591v-.02c-.011-1.773-.71-3.204-1.932-4.206-1.097-.899-2.671-1.458-4.659-1.458h-.395zm-1.697 1.697v-3.204h-3.342v11.085h5.434c3.046 0 4.879-1.523 4.893-3.904-.014-2.439-1.847-3.977-4.893-3.977h-2.092zm0 2.699h2.092c.924 0 1.581.527 1.595 1.271-.014.714-.671 1.212-1.595 1.212h-2.092v-2.483z"
              transform="matrix(.90248 0 0 .90248 -684.356 -458.122) rotate(52.756 982.742 560.091)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Image;