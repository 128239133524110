import { useCallback } from "react";
import create from "zustand";
// import createContext from "zustand/context";
import { getDeepKeyValue } from "../shed";
import produce from "immer";
import { cloneDeep, initial, merge } from "lodash";

// export const { Provider, useStore } = createContext();

const useStore = create((set) => ({
    update: (props) => set((state) => ({ ...state, ...props })),
    createSlice: (slice, initialValue={}) =>
        set(produce(state => {
            state[slice] = {
                ...initialValue,
                update: (props) =>
                set(produce(state => {
                    // console.log("--------------------");
                    /* if (slice === "post"){
                    console.log(`update slice '${slice}'`);
                    console.log("current slice:", state[slice]);
                    console.log("update with:", props);} */
                    // const stateSlice = cloneDeep(state[slice]);
                    // const merged = merge(stateSlice, props);
                    // console.log("merged:", merged);
                    state[slice] = {...state[slice], ...props }
                    
                    /* return {
                        ...state,
                        [slice]: { ...state[slice], ...props }
                    }; */
                })),
                reset: (initialValue={}) =>
                set(produce(state => {
                    state[slice] = {
                        ...initialValue,
                        update: state[slice].update,
                        reset: state[slice].reset
                    };
                }))

            };
        })),

    ru: {
        status: "initial",
        update: (props) =>
            set(produce(state => { state.ru = {...state.ru, ...props }; })),
        newBubbleReset: () =>
            set((state) => ({
                ...state,
                ru: {
                    ...state.ru,
                    status: "initial",
                    user: null,
                    bubble: null,
                },
            })),
    },
    lv: {
        status: "initial",
        update: (props) =>
            set(produce(state => { state.lv = {...state.lv, ...props }; })),
        newBubbleReset: () =>
            set(
                produce((state) => {
                    state.lv = {
                        ...state.lv,
                        status: "initial",
                        user: null,
                        bubble: null,
                    };
                })
            ),
    },
    elements: {
        update: (props) =>
            set(produce(state => { state.elements = {...state.elements, ...props }; })),
    },
}));

export const useS = (key, comp) => {
    const callback = useCallback((state) => getDeepKeyValue(state, key), [key]);
    const res = useStore(callback, comp);
    return res;
};

export const useSet = (key) => {
    const update = useStore((state) => state.update);
    return (value) => {
        update({ [key]: value });
    };
};

export const useSlice = (slice, key, comp) => {
    const callback = useCallback(
        (state) => state[slice] && getDeepKeyValue(state[slice], key),
        [key, slice]
    );
    const res = useStore(callback, comp);
    return res;
};

export const useSliceSet = (slice, key) => {
    const update = useStore((state) => {
        // console.log(state[slice]);
        return state[slice] && state[slice].update
    });
    return (value) => {
        update({ [key]: value });
    };
};

export default useStore;
