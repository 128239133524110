import { useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { post2server } from "../../api";
import { getAccessKey } from "../../cms/session";
import { dev, entities2unicode, generateId } from "../../shed";
import Button from "../../ui/Button";
import { useSlice, useSliceSet, useS } from "../../store/store";
import { Lang } from "../reader/Page";
import { updateUser } from "../common/users";

const post = (data) => {
    const request = {
        command: "insert",
        table: dev() ? "dev_foam" : "public_foam",
        access_key: getAccessKey(),
        data,
    };
    // console.log(request);
    return post2server(request)
};

export const PostButton = () => {
    const lang = useContext(Lang);
    const label = useS(`labels._said.${lang}.ui.post`);
    const user = useSlice(lang, "user");
    const bubble = useSlice(lang, "bubble");
    const setBubble = useSliceSet(lang, "bubble");
    const setUser = useSliceSet(lang, "user");
    const setStatus = useSliceSet(lang, "status");
    const commentsAnchor = useSlice(lang, "commentsAnchor");
    const setPostButton = useSliceSet(lang, "postButton");

    const [node, setNode] = useState();

    useEffect(() => {
        if (node) setPostButton(node);
    }, [setPostButton, node]);
 
    // const mutation0 = useMutateBubbles(object_id, setHate);

    const qc = useQueryClient();

    const userMutation = useMutation(updateUser, {
        onSuccess: () => {
            qc.invalidateQueries("users");
        }
    });

    const mutation = useMutation(post, {
        onSuccess: (_, variables) => {
            qc.invalidateQueries("bubbles");
            if (!user) return;
            userMutation.mutate({
                id: user.id,
                created: user.created.toISOString(),
                last_active: variables.created,
                bubble_types: JSON.stringify(user.bubble_types)
            });    

        }
    });

    if (!user) return null;

    const onClick = () => {
        // console.log("post button click");
        const bubbleReady = {
            ...bubble,
            id: generateId(),
            created: new Date().toISOString(),
        }
        // console.log("post bubble:", bubbleReady);
        mutation.mutate(bubbleReady);
        if (commentsAnchor) {
            commentsAnchor.scrollIntoView({ behavior: "smooth" });
        }
        setBubble(null);
        setUser(null);
        setStatus("initial");
    };

    return (<>
        {/* <Button>test test</Button>
        <Button>test test</Button> */}
        
        <Button id={node=>setNode(node)} action submit {...{onClick}} disabled={!bubble}>{entities2unicode(label)}</Button>
        
    </>)
};
