import { cl } from "../shed";
import "./Nav.scss";
import { Link, NavLink, useLocation, useRouteMatch } from "react-router-dom";
import { memo } from "react";
import { Schauspiel } from "./Schauspiel";


const Lesen = ({ pathname }) => {

    return (
        <div className={cl("navbar")}>

            <div className="ein">
                { /^\/ein\/?$/.test(pathname)
                    ? <span className="active">ein.</span>
                    : <Link to="/ein">ein.</Link>
                }
            </div>

            <div className="romochka">
                { /^\/romochka\/?$/.test(pathname)
                    ? <span className="active">romochka</span>
                    : <Link to="/romochka">romochka</Link>
                }
            </div>

            <div className="text">
                { /^\/lesen\/?/.test(pathname)
                    ? <span className="active">T</span>
                    : <Link to="/lesen">T</Link>
                }
            </div>

        </div>
    )
};

export const Navbar = memo(() => {

    const { pathname } = useLocation();

    // console.log(pathname);

    const folder = pathname.split("/").filter(s=>!!s)[0];

    // console.log("folder:", folder);

    switch (folder) {
        case "lesen":
        case "romochka":
        case "ein": {
            return <Lesen {...{ pathname }} />
        }
        case "schauspiel": {
            return <Schauspiel {...{ pathname }} />
        }
        default: {
            return null;
        }
    }
});
