export function getRect(element) {
    const {
        left,
        top,
        right,
        bottom,
        width,
        height,
        x,
        y,
    } = element.getBoundingClientRect();
    return { left, top, right, bottom, width, height, x, y };
}

const inView = (element) => {
    const {
        offsetTop,
        offsetHeight,
        parentNode: {
            offsetTop: parentOffsetTop,
            scrollTop,
            offsetHeight: parentOffsetHeight,
        },
    } = element;
    // console.log("offsetTop:", offsetTop);
    // console.log("height:", offsetHeight);
    // console.log("parent offsetTop:", parentOffsetTop);
    // console.log("parent scrollTop:", scrollTop);
    // console.log("parent height:", parentOffsetHeight);

    if (offsetTop < scrollTop + parentOffsetTop) {
        // element.scrollIntoView(true);
        return true;
    }
    if (
        offsetTop + offsetHeight >
        parentOffsetTop + parentOffsetHeight + scrollTop
    ) {
        // element.scrollIntoView(false);
        return false;
    }
    return null;
};

export const scrollIntoView = (element, add) => {
    const little = add || element.offsetHeight / 2.5;
    const scrollTo = inView(element);
    if (scrollTo === null) return;
    // element.scrollIntoView(scrollTo);

    // console.log("scrollIntoView");

    if (scrollTo) {
        // console.log("element is in the top");
        // const scrollTop = element.parentNode.scrollTop;
        // element.parentNode.scrollTop = scrollTop - little;
    } else {
        // console.log("element is in the bottom");
        // const scrollTop = element.parentNode.scrollTop;
        // element.parentNode.scrollTop = scrollTop + little;
    }

    // console.log("element:", element);
    // console.log("element.offsetTop:", element.offsetTop);
    // console.log("element.offsetHeight:", element.offsetHeight);
    // console.log("element.offsetParent:", element.offsetParent);
    // console.log("parent:", element.parentNode);
    // console.log("parent scrollTop:", element.parentNode.scrollTop);
    // console.log("parent offsetTop:", element.parentNode.offsetTop);
    // console.log("parent offsetHeight:", element.parentNode.offsetHeight);

    const {offsetTop, offsetHeight } = element;
    const { offsetTop: parentOffsetTop, offsetHeight: parentOffsetHeight, scrollTop } = element.parentNode;

    let diff = 0;
    if (parentOffsetTop + parentOffsetHeight + scrollTop < offsetTop + offsetHeight) {
        diff = (
            (offsetTop + offsetHeight) - (parentOffsetTop + parentOffsetHeight + scrollTop) + little
        );
    }
    if (scrollTop + parentOffsetTop > offsetTop) {
        diff = - ((scrollTop + parentOffsetTop) - offsetTop) - little;
    }

    // console.log("scroll to:", diff);
    element.parentNode.scrollTop = scrollTop + diff;

};

const addEventToBody = () => {

};