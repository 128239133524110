import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIsMutating, useMutation, useQueryClient } from "react-query";
import { cookieUserId, sessionUser, useUserFromCookie } from "../features/common/users";
import { api_action } from "../features/queries/queries";
import { generateId } from "../shed";
import { useS, useSet, useSliceSet } from "../store/store";

import "./User.scss";

const DeleteUser = ({ cms, set }) => {
    const onClick = useCallback(() => {
        if (cms.user) set({...cms.user, remove: true });
    }, [set, cms]);

    return (
        <button {...{ onClick }} disabled={!cms.user}>Delete db user</button>
    )
};

const CreateDbUser = () => {
    const user_id = cookieUserId.get();



    return (
        <button disabled={!user_id}>Create db user</button>
    )
};

const DeleteCookieUser = ({ cms, set }) => {
    
    const onClick = useCallback(() => {
        set(null);
    }, [set]);

    return (
        <button {...{ onClick }} disabled={!cms.cookieUser}>Delete user cookie</button>
    )
};

const CreateCookieUser = ({ cms, set }) => {

    const [value, setValue] = useState(cms.cookieUser||"");

    const onClick = useCallback(() => {
        set(value);
    }, [value, set]);

    const onInput = useCallback(({ target }) => {
        setValue(target.value||"");
    }, []);

    return (
        <div className="form">
            <input {...{ value }} {...{ onInput }} />
            <button onClick={()=>{ setValue(generateId()) }}>Generate</button><br />
            <button {...{ onClick }} disabled={!value || cms.cookieUser===value}>Create user cookie</button>
        </div>
    )
}

const UserInfo = ({ cms }) => {

    const { id, first, second } = cms.user || {};

    // let cookie_user_id = useRef();
    // let last_session = useRef();

    /* useEffect(() => {
        console.log("UserInfo rendered");
        cookie_user_id.current = cookieUserId.get();
        last_session.current = sessionUser.get();
    }); */

    return (
        <div className="info">
            <div>ein_user cookie id: {cms.cookieUser || "–"}</div>
            <div>last_session: {(new Date(cms.sessionUser).toString()) || "–"}</div>
            <h1>name: { (first && second && `${first} ${second}`) || "–" }</h1>
            <div>user.id: { id || "–" }</div>
        </div>
    )
};

const Deleted = () => {
    const [value, setValue] = useState();

};

export const useUserStatus = () => {
    const user = useS("user");
    
};

export const User = () => {

    useUserFromCookie();
    const user = useS("user");
    const setUser = useSet("user");
    const createSlice = useS("createSlice");
    const cms = useS("cms");
    const setCMSUser = useSliceSet("cms", "user");
    const setCookie = useSliceSet("cms", "cookieUser");
    const setSession = useSliceSet("cms", "sessionUser");

    const qc = useQueryClient();

    const mDeleteOptions = useMemo(() => (cms?.user?.id && {
        mutationKey: ["user", cms.user.id, "delete"],
        onSuccess: () => {
            console.log(`user ${cms.user.id} deleted`);
            setUser({...user, remove: true });
            qc.invalidateQueries("ein_user");
        }
    }), [cms, qc, setUser, user]);

    const mDelete = useMutation(api_action("delete", "public_users"), mDeleteOptions);
    const isMutating = useIsMutating({ mutationKey: ["user", cms?.user?.id] });

    useEffect(() => {
        if (!cms) createSlice("cms", {
            user, cookieUser: cookieUserId.get(),
            sessionUser: sessionUser.get()
        });
    }, [user, cms, createSlice]);

 
    useEffect(() => {
        if (!cms) return;
        if (user && !user.remove && !cms.user) {
            console.log("user exists, no cms.user, set cms.user to user");
            setCMSUser(user);
            return;
        }
        if (cms.user && user===null) {
            console.log("cms.user exists, user is null, set cms.user to null");
            setCMSUser(null);
            return;
        }
        if (cms.user && user && !user.remove && cms.user.id !== user.id) {
            console.log("user and cms.user exist, different ids, set cms.user to user");
            setCMSUser(user);
        }
        if (user && user.remove && cms.user) {
            setCMSUser(null);
        }
    }, [user, cms, setCMSUser, setUser]);

    useEffect(() => {
        if (!cms) return;
        if (cms.cookieUser && cms.cookieUser !== cookieUserId.get()) {
            cookieUserId.set(cms.cookieUser);
        }
        if (cms.cookieUser === null && cookieUserId.get()) {
            cookieUserId.remove();
        }
    }, [cms]);

    useEffect(() => {
        if (!cms) return;
        if (!cms.user) return;
        if (user && !user.remove && cms.user.remove && !isMutating) {
            console.log("DELETE user");
            mDelete.mutate({ id: cms.user.id });
        }
    }, [cms, user, isMutating, mDelete])

    if (!cms) return null;

    return (
        <div className="cms user">
            <UserInfo {...{ cms }} />
            <div className="tools">
                <CreateCookieUser {...{ cms, set: setCookie }} /><br />
                <DeleteCookieUser {...{ cms, set: setCookie }} />
                <DeleteUser {...{ cms, set: setCMSUser }} />
                <Check {...{ cms, user }} />
            </div>
        </div>
    )
    
};

const Check = ({ cms, user }) => {
    useEffect(() => {
        console.log("%ccms:", "color: orange", cms);
    }, [cms]);
    useEffect(() => {
        console.log("%cuser:", "color: green", user);
    }, [user]);

    return null;
}