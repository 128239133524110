import { memo, useMemo } from "react";
import { entities2unicode, objType } from "../../shed";
import { getComponents, parse, useComponentValue } from "./express";
import { useExpressionStore } from "./Expression";
import { rx } from "./rx";

const meets = (object, condition) => {

    // console.log("meets");
    // console.log("meets object", object);
    // console.log("meets condition", condition);

    if (Array.isArray(condition)) {
        return condition.some(c => meets(object, c));
    }
    switch (objType(condition)) {
        case "string": {
            return object.value === condition;
        }
        case "number": {
            if (Array.isArray(object.value)) {
                return object.index === condition;
            }
            return object.value === condition;
        }
        case "object": {
            return Object.entries(condition).every(([key, val]) => (
                object.params[key] === val
            ));
        }
        case "boolean": {
            // console.log("any");
            return condition;
        }
        default: return object.value === condition;
    }
};

const CCond = memo(({ invisible, template, cname, expressionId, expressionString }) => {

    // console.log(origin);
    const [slice, set] = useExpressionStore(expressionId);

    const name = useMemo(() => rx.get.CCond.name(template), [template]);
    const string = useMemo(() => rx.get.CCond.string(template), [template]);


    const def = slice.defs[name];
    // console.log("--COND----------------------------");

    // console.log("def:", def);

    const object = rx.getObject(string);

    // console.log("def name:", name);
    // console.log("cond string:", string);
    // console.log("condition templates:");
    // object.value.forEach(v => { console.log(v) });

    const conditions = object.value.map(pair => {
        // console.log("condition pair:", pair);
        const valueObj = rx.getObject(pair[0]);
        const ifValue = valueObj?.value;
        // if (ifValue===true) console.log("if value object:", valueObj);
        const thenValue = rx.getObject(pair[1])?.value;
        return [ifValue, thenValue];
    });

    // console.log("conditions:");
    // conditions.forEach(pair => { console.log(pair) });

    const condition = def && conditions.find(c => meets(def, c[0]));

    // console.log("condition true:", condition);

    const content = {
        value: condition && condition[1],
        render: condition
        ? <span className="cond">{entities2unicode(condition[1])}</span>
        : null
    };

    let { value, render } = content;

    if (/%C\d+/.test(value)) {
        // console.log("--NESTED----------------------------");
        const arr = value.split(/(%C\d+)/).filter(s=>s!=="");
        // console.log(arr);
        const res = arr.map((item, i) => {
            if (/^%C\d+$/.test(item)) {
                const { components } = parse(expressionString);
                // console.log(components);
                const component = components.find(c => c.name === item.substr(1));
                // console.log(component);
                const template = component?.template;
                // console.log(template);
                const Component = getComponents(template);
                // console.log(Component);
                return <Component key={`comp${i}`} cname={component.name} {...{ template, expressionId, expressionString }} />;
            } else {
                return <span className="cond" key={`text${i}`}>{item}</span>
            }
        });
        // console.log(res);
        render = res;
    }

    useComponentValue(expressionId, cname, value);

    if (!def) return null;
    if (!condition) return null;
    if (invisible) return null;

    return (
        render
    )
});

export default CCond;
