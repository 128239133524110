import { isEqual } from "lodash";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { entities2unicode } from "../../shed";
import PopupSelect from "../../ui/bui/select/PopupSelect";
import { useComponentValue } from "./express";
import { useExpressionStore } from "./Expression";
import { getDefValue } from "./lang";
import { rx } from "./rx";

const copyCase = (from, to) => {
    // if (from.toUpperCase()===from) return to.toUpperCase();
    // if (from.toLowerCase()===from) return to.toLowerCase();
    if (from[0].toUpperCase()===from[0] && from.substr(1).toLowerCase() === from.substr(1)) return `${to[0].toUpperCase()}${to.substr(1)}`;
    return to;
};

const Def = memo(({ object, cname, expressionId }) => {

    const { name, value: { name: defName, params } } = object;

    const [slice, set] = useExpressionStore(expressionId);

    const def = slice.defs[defName.toLowerCase()];

    const evaluated = useMemo(() => {
        // console.log(def);
        // console.log(params);
        const v = getDefValue(def, params);
        v.value = copyCase(defName, v.value);
        // console.log("def value:", v);
        return v;
    }, [def, params, defName]);

    useEffect(() => {
        if (!isEqual(slice.defs[name], evaluated)) {
            set({ defs: {...slice.defs, [name]: evaluated } })
        }
    }, [name, set, slice.defs, evaluated]);

    useComponentValue(expressionId, cname,
        object.type !== "def invisible" && evaluated.value);

    if (object.type === "def invisible") return null;

    return (
        <span className="def value">{evaluated.value}</span>
    )
});

const DefSelect = memo(({ object, cname, expressionId }) => {

    const { name, value } = object;

    const [slice, set] = useExpressionStore(expressionId);

    const [index, setIndex] = useState(0);

    const options = useMemo(() => {
        return value.map((option, i) => (
            { value: i, label: entities2unicode(option) }
        ))
    }, [value]);

    const handleChange = useCallback((value) => {
        // console.log(value);
        // console.log(objType(value))
        setIndex(parseInt(value));
    }, []);

    useEffect(() => {
        const evaluated = { value, index };
        if (!isEqual(slice.defs[name], evaluated)) {
            set({ defs: {...slice.defs, [name]: evaluated } })
        }
    }, [index, name, set, slice.defs, value]);

    useComponentValue(expressionId, cname, value[index]);

    return (
        <PopupSelect {...{ data: { list: options, selected: index }, handleChange, size: 10 }} />

        // <Select {...{ options, value: index, onSelectChange }} />

    )
});

const DefString = memo(({ object, cname, expressionId }) => {

    // console.log("------ DefString ------------------- ");

    const { name, value } = object;

    const [slice, set] = useExpressionStore(expressionId);

    useEffect(() => {
        if (slice.defs[name]!==value) {
            set({ defs: {...slice.defs, [name]: value } })
        }
    }, [name, set, slice.defs, value]);

    useComponentValue(expressionId, cname, object.value);

    return (
        <span className="def string">{object.value}</span>
    )
});

const components = {
    "string": DefString,
    "array": DefSelect,
    "def": Def,
    "def invisible": Def,
};

const CDef = memo(({ invisible, template, cname, expressionId }) => {

    // const breaker = useRef(0);

    // const [slice, set] = useExpressionStore(expressionId); 

    // console.log(template);
    // console.log(slice);
    // console.log("---CDEF--------------------");
    // console.log(cname);
    const name = useMemo(() => rx.get.CDef.name(template), [template]);
    // console.log(name);

    const object = useMemo(() => {
        const string = rx.get.CDef.string(template);
        // console.log(string);
        const res = rx.getObject(string);
        // console.log(res);
        return {...res, name};
    }, [template, name]);

    const content = useMemo(() => {

        const Component = components[object.type];

        return Component
        ? <Component {...{ object, cname, expressionId }} />
        : null

    }, [object, expressionId, cname]);

    /* useEffect(() => {
        if (name && object && slice.defs[name]!==value) {
            // console.log("%cshould set", "color:red");
            // breaker.current += 1;
            // console.log(`%cbreaker ${breaker.current}`, "color:red");
            // set({ defs: { ...slice.defs, [name]: value }});
        }
    }, [object, name, set, slice.defs]); */
 

    // console.log(`value:`, value);

    if (invisible) return null;

    return (
        content ? <span className="def">{ content }</span> : null
    )
});

export default CDef;
