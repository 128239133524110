import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { required } from ".";
import Routes from "./Routes";
import { simpleActions as a } from "./features/slicer/asyncSlicer";
import { useCallback, useEffect, useState } from "react";
import { Navbar } from "./nav/Nav";
import "./App.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { isEqual } from "lodash";
import { useRequired as useReq } from "./features/queries/queries";

import Spinner from "./ui/Spinner";
import { useS } from "./store/store";
import { useUser, useUserFromCookie } from "./features/common/users";
import { dev, useTraceUpdate } from "./shed";
import Auth, { getAccessKey } from "./cms/session";

const useRequired = () => {
  const statuses = useSelector(state =>
    required.map(slice => state[slice].status)
  );
  return statuses.every(status => status === "succeeded");
};

const useCookies = () => {
  const loaded = useRequired();
  const config = useSelector(state => state.config);
  const updated = useSelector(state => state.config.status === "updated");
  const dispatch = useDispatch();

  useEffect(() => {
    if (loaded && !updated) {
      // console.log(config.value);
      // console.log("copy config from cookie or defaults");
      ["langs", "lang", "reader"].forEach(key => {
        if (!config.value[key]) {
          dispatch(
            a.config.update({
              [key]:
                (JSON.parse(localStorage.getItem("ein")) || {})[key] ||
                config.value.defaults[key],
            })
          );
        }
      });
    }
  }, [dispatch, config, loaded, updated]);

  return updated;
};

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function AppDev() {
  const ready = useCookies();

  const random = useSelector(state => state.random.value.css);
  const css = useSelector(state => state.css.value, isEqual);

  const reqStatus = useReq(["labels"]);

  useUserFromCookie();

  const user = useS("user", isEqual);

  // useTraceUpdate({ ready, random, css, reqStatus, user }, "App");

  /* useEffect(()=>{
        if (user) {
            console.log("user:", user);
        }
    }, [user]); */

  if (!user) return getAccessKey() ? null : <Auth />;

  if (!reqStatus === "success") return null;

  if (!ready) return null;

  return (
    <Router>
      <Spinner init />
      <Navbar />
      {css && <link href={`${css.href}?${random}`} rel="stylesheet" />}
      <div className="app">
        <ScrollToTop />
        <Routes />
      </div>
      <ReactQueryDevtools initialIsOpen={false} />
    </Router>
  );
}

function App() {
  const ready = useCookies();

  const random = useSelector(state => state.random.value.css);
  const css = useSelector(state => state.css.value, isEqual);

  const reqStatus = useReq(["labels"]);

  // const labels = useS("labels");

  /* useEffect(() => {
        console.log(reqStatus);
        if (reqStatus === "success") {
            console.log(labels);
        }
    }, [reqStatus, labels]) */

  // console.log(process.env.NODE_ENV);

  /* useEffect(()=>{
        console.log(css);
    }, [css]); */
  // const [status, set] = useState("idle");

  // console.log(`App ready:`, ready);

  /* useEffect(()=>{
        set("loading");
        getKey({})
        .then(response => {
            // console.log(response);
            const { access_name, access_key, session_id } = response;
            sessionStorage.setItem("access_name", access_name);
            sessionStorage.setItem("access_key", access_key);
            sessionStorage.setItem("session_id", session_id);
            set("succeeded");
        })
        .catch(() => {
            set("failed");
        });
    }, []); */

  if (!reqStatus === "success") return null;

  if (!ready) return null;

  // console.log("привет, че, консоль читаешь?")
  // <Spinner />;
  // if (status !== "succeeded") return <div>....</div>;

  return (
    <Router>
      <Spinner init />
      <Navbar />
      {css && <link href={`${css.href}?${random}`} rel="stylesheet" />}
      <div className="app">
        <ScrollToTop />
        <Routes />
      </div>
      <ReactQueryDevtools initialIsOpen={false} />
    </Router>
  );
}

const QC = () => {
  const queryClient = new QueryClient();

  // console.log("what?");

  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <App />
      {/* {dev() ? <AppDev /> : <App />} */}
    </QueryClientProvider>
  );
};

export default QC;
