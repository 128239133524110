import { useState } from "react";
import { useSelector } from "react-redux";
import { cl, dev } from "../../shed";
import Lang from "./Lang";
import Page from "./Page";
import Pager from "./Pager";

import "./reader.scss";
import Copyright from "./Copyright";
import Render from "./Render";
import TOC from "./TOC";
import { Sometime } from "../common/Sometime";
import { Scribble } from "../scribbles/Scribble";

const Spread = ({ content, langs, lang }) => {
  const props =
    lang === "both"
      ? [
          { left: true, visible: true, lang: "ru" },
          { right: true, visible: true, lang: "lv" },
        ]
      : langs.map((l, i) => ({ visible: l === lang, lang: langs[i] }));

  // console.log(props);

  return (
    <>
      <Page {...props[0]} content={content} />
      <Page {...props[1]} content={content} />
    </>
  );
};

const Reader = ({ content }) => {
  // console.log(content);

  // console.log(!!window.location.search);

  const queryLang =
    window.location.search && window.location.search.substring(1);

  // console.log("query lang?", !!queryLang);
  // if (queryLang) console.log(queryLang);

  // const location = useLocation();

  // console.log(location);

  const langs = useSelector(state => state.config.value.langs);
  // const lang = useSelector(state => state.config.value.lang);
  const [lang, setLang] = useState(
    /^latviski/.test(queryLang)
      ? "lv"
      : sessionStorage.getItem("lesen lang") || "both"
  );

  const onChange = value => {
    setLang(value);
    sessionStorage.setItem("lesen lang", value);
  };

  const showToc = content.texts || content.toc.length > 4;

  return (
    <>
      <div className={cl("reader")}>
        {/* <Lang lang={lang} onChange={onChange} /> */}
        {content.texts ? (
          <TOC {...{ content, lang: "lv" }} />
        ) : (
          <Spread {...{ content, langs, lang: "lv" }} />
        )}
      </div>
      <Pager {...{ content, showToc }} />

      <Copyright />
      {dev() && <Render {...{ content }} />}
    </>
  );
};

export default Reader;
