import Button from "../../ui/Button";
import { Emoji } from "./emoji";
import {
    cl,
    dev,
    entities2unicode,
    exact,
    nodeInViewHeight,
} from "../../shed";
import {
    memo,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Blob, BlobContext } from "../blob/Blob";
import { useDeepCompareEffect, usePrevious } from "react-use";
import smoothscroll from "smoothscroll-polyfill";
import useStore, { useS, useSet, useSlice, useSliceSet } from "../../store/store";
import { PostButton } from "./PostButton";
import { Lang } from "../reader/Page";
import Anchor from "../../ui/Anchor";
import { useDescriptors } from "./useBubbles";
import { useUsers } from "../queries/queries";
smoothscroll.polyfill();

const User = ({ user }) => {
    const { w } = useContext(BlobContext);
    const lang = useContext(Lang);
    const labels = useS(`labels._said.${lang}.bubbles`);
    const selectedUser = useSlice(lang, "user");
    const setUser = useSliceSet(lang, "user");
    const setBubble = useSliceSet(lang, "bubble");
    const selected = useMemo(
        () => user.id === selectedUser?.id,
        [user, selectedUser]
    );

    const onClick = async () => {
        if (!selected) {
            setUser(user);
            setBubble(null);
        }
    };

    const config = useRef("mass=1, tension=180, friction=10");

    return (
        <div
            onClick={selected ? null : w(onClick)}
            className={cl(
                "user",
                { selected },
                { unselected: selectedUser && !selected }
            )}
        >
            <Emoji
                {...{
                    ["data-users" + lang]: `${user.id}:size:${config.current}`,
                }}
                emotion={user.type}
            />
            {entities2unicode(labels[user.type])}
            {/* {bubbleDescriptors.find(bd=>user.bubble_types.includes(bd.type)).name[lang]} */}
        </div>
    );
};

const Users = () => {
    const lang = useContext(Lang);
    const status = useSlice(lang, "status");
    const users = useSlice(lang, "users", exact);
    const user = useSlice(lang, "user", exact);
    const prevUser = usePrevious(user);
    const [node, setNode] = useState();

    useEffect(() => {
        if (!prevUser && node) node.scrollIntoView({ behavior: "smooth" });
    }, [user, prevUser, node]);

    if (status === "initial") return null;

    // console.log(users);

    return (
        <>
            <Anchor id={(node) => setNode(node)} top="-30px" />
            <Blob name={`users${lang}`}>
                <div className="users">
                    {users.map((user) => (
                        <User user={user} key={user.id} />
                    ))}
                </div>
            </Blob>
        </>
    );
};

const Bubble = ({ descriptor }) => {
    const lang = useContext(Lang);
    const selectedBubble = useSlice(lang, "bubble");
    const setBubble = useSliceSet(lang, "bubble");
    const user = useSlice(lang, "user");
    const object_id = useSlice(lang, "object_id");
    const object_desc = useSlice(lang, "text");
    const selected = useMemo(
        () => descriptor.id === selectedBubble?.bubble_id,
        [descriptor, selectedBubble]
    );

    const onClick = () => {
        if (!selected)
            setBubble({
                bubble_id: descriptor.id,
                user_id: user.id,
                object_id,
                object_desc,
                lang,
            });
    };

    return (
        <div {...{ onClick }} className={cl("bubble", { selected })}>
            {entities2unicode(descriptor.name[lang])}
        </div>
    );
};

const Bubbles = () => {
    const lang = useContext(Lang);
    const user = useSlice(lang, "user");
    const bubble = useSlice(lang, "bubble");
    const labels = useS(`labels._said.${lang}.bubbles`);
    const postButton = useSlice(lang, "postButton");

    const prevBubble = usePrevious(bubble);

    // console.log(labels);

    const descriptors = useSlice(lang, "descriptors", exact);

    const [node, setNode] = useState();

    useEffect(() => {
        if (!prevBubble && bubble && node) {
            // console.log(node.getBoundingClientRect().top);
            if (postButton) {
                // console.log(nodeInViewHeight(postButton));
                if (nodeInViewHeight(postButton) === 1) return;
            }

            if (node.getBoundingClientRect().top >= 0) {
                node.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [prevBubble, bubble, node, postButton]);

    if (!user) return null;
    // if (status === "initial") return null;

    return (
        <>
            <Anchor id={(node) => setNode(node)} />
            <Blob name={`bubbles${lang}`}>
                <div className="bubbles">
                    <h2>{entities2unicode(labels[`select ${user.type}`])}:</h2>
                    {
                        (user.bubble_types === "any"
                        ? descriptors
                        : descriptors
                        .filter((bd) => {
                            // console.log(user);
                            return user.bubble_types.includes(bd.type)
                        }))
                        .map((descriptor) => (
                            <Bubble
                                key={descriptor.id}
                                {...{ descriptor }}
                            />
                        ))
                    }
                </div>
            </Blob>
        </>
    );
};

const AddButton = () => {
    const lang = useContext(Lang);
    const label = useS(`labels._said.${lang}.ui.add comment`);
    const status = useSlice(lang, "status");
    const setStatus = useSliceSet(lang, "status");
    const onClick = useCallback(() => {
        // console.log("add button click");
        setStatus("select user");
    }, [setStatus]);

    return (
        <Button action {...{ onClick }} disabled={status !== "initial"}>
            {label}
        </Button>
    );
};

const CancelButton = () => {
    const lang = useContext(Lang);
    const label = useS(`labels._said.${lang}.ui.cancel`);
    const status = useSlice(lang, "status");
    const setStatus = useSliceSet(lang, "status");
    const setUser = useSliceSet(lang, "user");

    if (status === "initial") return null;

    const onClick = () => {
        setStatus("initial");
        setUser(null);
    };

    return (
        <Button action cancel {...{ onClick }}>
            {entities2unicode(label)}
        </Button>
    );
};

const NewBubble = () => {
    const lang = useContext(Lang);
    const object_id = useSlice(lang, "object_id");
    const user = useSlice(lang, "user");
    const reset = useSlice(lang, "newBubbleReset");

    const prev_object_id = usePrevious(object_id);

    useEffect(() => {
        if (prev_object_id && object_id !== prev_object_id) {
            reset();
        }
    }, [object_id, prev_object_id, user, reset]);

    return (
        <div className="newBubble">
            <AddButton />
            <Users />
            <Bubbles />
            <div className="buttons">
                <PostButton />
                <CancelButton />
            </div>
        </div>
    );
};

const Updated = memo((props) => {
    const lang = useContext(Lang);
    const update = useSlice(lang, "update");
    // const object_id = useSlice(lang, "object_id");

    const checkState = useStore(state => Object.keys(props).every(key=>state[lang].hasOwnProperty(key)));

    useDeepCompareEffect(() => {
        // console.log(props);
        update(props);
    }, [update, props]);

    // if (!object_id) return null;

    if (!checkState) return null;

    return <NewBubble />;
});

const Provided = ({ object_id, text }) => {

    const lang = useContext(Lang);
    const { status: bdStatus, data: bDescriptors } = useDescriptors({ text });
    const { status: usersStatus, data: users } = useUsers();

    /* useEffect(() => {
        if (users) {
            console.log(users);
        }
    }, [users]) */


    if ([usersStatus, bdStatus].some((status) => status !== "success")) {
        return <div>...</div>;
    }

    const descriptors = bDescriptors.filter(d => d.name.hasOwnProperty(lang));

    // console.log(descriptors);

    return (
        <Updated {...{ object_id, text, users, descriptors }} />
    );
};

export default Provided;
