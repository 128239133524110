import { useEffect, memo, useState } from "react";
import { getDir } from "../api";

const Dir = memo(({ path, regexp, mapper, set }) => {

    // console.log(`Dir path:`, path);

    const [status, setStatus] = useState();
    const [error, setError] = useState();
    const [data, setData] = useState();

    useEffect(() => {
        setStatus("idle");
    }, [path]);

    useEffect(() => {
        if (status === "idle") {
            getDir({ path, regexp, md5: true })
            .then(response => {
                // console.log(response);
                setData(response);
                if (set) { set(response); }
                setStatus("succeeded");
            })
            .catch(err => {
                setError(err);
                setStatus("failed");
            });
        }
    }, [status, path, regexp, set]);

    let content = "...";

    if (status === "succeeded") {
        content = data.map(mapper);
    } else if (status === "failed") {
        content = error;
    }

    return content;
});


export default Dir;
