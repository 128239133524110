import { memo, useCallback, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { post2server } from "../../../api";
import { getAccessKey } from "../../../cms/session";
import { dev } from "../../../shed";
import Button from "../../../ui/Button";
import { useLabel } from "../useLabel";

const deletePost = (data) => {
    const request = {
        command: "delete",
        table: `${dev() ? "dev" : "public"}_posts`,
        access_key: getAccessKey(),
        data
    };
    // return Promise.resolve({});
    return post2server(request);
};

export const DeleteButton = memo(({ postId }) => {

    const [executing, setExecuting] = useState(false);

    const qc = useQueryClient();

    const mutation = useMutation(deletePost, {
        onSuccess: () => {
            // setExecuting(false);
            // console.log(`${postId} deleted`);
            qc.invalidateQueries("posts");
        }
    });

    const yes = useLabel("feed.yes");
    const no = useLabel("feed.no");
    const _delete = useLabel("feed.delete post", true);

    const onClick = useCallback(() => {
        setExecuting(true);
        // console.log(`deleting ${postId}`);
        mutation.mutate({ id: postId });
    }, [mutation, postId]);

    if (!postId) return null;

    return (
        <Button small confirm={{yes, no}} {...{ onClick, className: "delete" }}
        disabled={executing}>{_delete}</Button>
    )

});