
import "./emoji.scss";

const face = {
    approve: (
      <g id="approve">
        <circle
          id="face"
          cx="100"
          cy="100"
          r="94.3"
          fill="var(--bg)"
          stroke="var(--fg)"
          strokeWidth="7.42"
        ></circle>
        <path
          id="mouth"
          fill="none"
          stroke="var(--fg)"
          strokeWidth="8.17"
          d="M147.548 119.233c-7.612 18.784-26.043 32.05-47.548 32.05-21.358 0-39.684-13.085-47.393-31.671"
        ></path>
        <circle
          id="eye-L"
          cx="59.453"
          cy="75.302"
          r="24.698"
          fill="var(--bg)"
          stroke="var(--fg)"
          strokeWidth="6.5"
        ></circle>
        <circle fill="var(--fg)" id="pupil-L" cx="59.453" cy="75.302" r="6.957"></circle>
        <circle
          id="eye-R"
          cx="140.547"
          cy="75.302"
          r="24.698"
          fill="var(--bg)"
          stroke="var(--fg)"
          strokeWidth="6.5"
        ></circle>
        <circle fill="var(--fg)" id="pupil-R" cx="140.547" cy="75.302" r="6.957"></circle>
      </g>
    ),
    disapprove: (
        <g id="disapprove">
        <circle
          id="face"
          cx="100"
          cy="100"
          r="94.3"
          fill="var(--bg)"
          stroke="var(--fg)"
          strokeWidth="7.42"
        ></circle>
        <path
          id="mouth"
          fill="none"
          stroke="var(--fg)"
          strokeWidth="8.17"
          d="M67.184 135.944s28.334-2.729 58.324 1.866c18.711 2.867 27.951 8.786 27.951 8.786"
        ></path>
        <path
          id="eyebag-L"
          fill="none"
          stroke="var(--fg)"
          strokeWidth="7.42"
          d="M76.914 92.763C72.443 97.233 66.269 100 59.453 100c-6.815 0-12.99-2.767-17.46-7.237"
        ></path>
        <path
          id="eyebag-R"
          fill="none"
          stroke="var(--fg)"
          strokeWidth="7.42"
          d="M158.007 92.763c-4.47 4.47-10.645 7.237-17.46 7.237-6.816 0-12.99-2.767-17.461-7.237"
        ></path>
        <circle fill="var(--fg)" id="pupil-L" cx="59.453" cy="75.302" r="10.206"></circle>
        <circle fill="var(--fg)" id="pupil-R" cx="140.547" cy="75.302" r="10.206"></circle>
      </g>
    ),
    irrelevant: (
        <g id="irrelevant">
        <circle
          id="face"
          cx="100"
          cy="100"
          r="94.3"
          fill="var(--bg)"
          stroke="var(--fg)"
          strokeWidth="7.42"
        ></circle>
        <path
          id="mouth"
          fill="none"
          stroke="var(--fg)"
          strokeWidth="7.42"
          d="M128.777 145.683s-8.452 2.945-14.191 1.763c-9.528-1.962-9.373-8.9-18.357-9.221-8.544-.305-11.489 6.914-18.713 7.381-9.462.612-10.875-6.501-17.511-8.114-7.13-1.733-14.561 1.796-17.682 8.471"
        ></path>
        <circle fill="var(--fg)" name="pupil-L" cx="42.748" cy="66.022" r="7.677"></circle>
        <circle
          cx="46.46"
          cy="75.302"
          r="24.698"
          fill="none"
          stroke="var(--fg)"
          strokeWidth="6.5"
        ></circle>
        <circle fill="var(--fg)" name="pupil-R" cx="115.489" cy="66.022" r="7.677"></circle>
        <circle
          cx="121.057"
          cy="75.302"
          r="24.698"
          fill="none"
          stroke="var(--fg)"
          strokeWidth="6.5"
        ></circle>
      </g>
    ),
    offence: (
        <g id="offence">
        <circle
          id="face"
          cx="100"
          cy="100"
          r="94.3"
          fill="var(--bg)"
          stroke="var(--fg)"
          strokeWidth="7.42"
        ></circle>
        <circle fill="var(--fg)" id="pupil-L" cx="59.453" cy="75.302" r="10.206"></circle>
        <circle fill="var(--fg)" id="pupil-R" cx="140.547" cy="75.302" r="10.206"></circle>
        <path
          id="eyebag-L"
          fill="none"
          stroke="var(--fg)"
          strokeWidth="7.42"
          d="M76.914 92.763C72.443 97.233 66.269 100 59.453 100c-6.815 0-12.99-2.767-17.46-7.237"
        ></path>
        <path
          id="eyebag-R"
          fill="none"
          stroke="var(--fg)"
          strokeWidth="7.42"
          d="M158.007 92.763c-4.47 4.47-10.645 7.237-17.46 7.237-6.816 0-12.99-2.767-17.461-7.237"
        ></path>
        <g id="hitler-moustache" fill="var(--fg)">
          <path d="M90.678 130.02a6.862 6.862 0 00-6.859-6.858 6.861 6.861 0 00-6.858 6.858v16.298a6.862 6.862 0 006.858 6.859 6.862 6.862 0 006.859-6.859V130.02z"></path>
          <path d="M103.426 129.052a6.861 6.861 0 00-6.858-6.858 6.862 6.862 0 00-6.859 6.858v19.203a6.862 6.862 0 006.859 6.858 6.861 6.861 0 006.858-6.858v-19.203z"></path>
          <path d="M115.367 130.988a6.861 6.861 0 00-6.858-6.858 6.861 6.861 0 00-6.858 6.858v16.299a6.861 6.861 0 006.858 6.858 6.861 6.861 0 006.858-6.858v-16.299z"></path>
        </g>
        <path
          fill="var(--fg)"
          id="hitler-hair"
          d="M190.117 71.996C178.18 33.608 142.35 5.7 100.052 5.7a94.238 94.238 0 00-24.82 3.307c11.937 38.387 47.767 66.295 90.065 66.295a94.28 94.28 0 0024.82-3.306z"
        ></path>
      </g>
    ),
    neutral: (
      <g id="neutral">
      <circle
        data-name="face"
        cx="100"
        cy="100"
        r="94.3"
        fill="var(--bg)"
        stroke="var(--fg)"
        strokeWidth="7.42"
      ></circle>
      <circle fill="var(--fg)" data-name="pupil-L" cx="62.453" cy="77" r="12"></circle>
      <circle fill="var(--fg)" data-name="pupil-R" cx="137.547" cy="77" r="12"></circle>
    </g>
  )
};

export const Emoji = ({ emotion, ...props }) => {
    return (
        <svg {...props} className="emoji" viewBox="0 0 200 200">
            {face[emotion]}
        </svg>
    )    
};
