import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { getTable, post2server } from "../api"
import { getAccessKey } from "./session";

const authorsAdapter = createEntityAdapter(
    /* {
    sortComparer: (a, b) => b.date.localeCompare(a.date),
} */
);

const initialState = authorsAdapter.getInitialState({
    status: 'idle',
    error: null,
});

export const fetchAllAuthors = createAsyncThunk(
    'authors/fetchAuthors',
    () => getTable({ table: "public_authors", access_key: getAccessKey() }),
);

const authorsSlice = createSlice({
    name: "authors",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchAllAuthors.pending]: state => {
            state.status = "loading";
        },
        [fetchAllAuthors.fulfilled]: (state, action) => {
            state.status = "succeeded";
            authorsAdapter.upsertMany(state, action.payload);
        },
        [fetchAllAuthors.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        }
    }
});

export default authorsSlice.reducer;

export const {
    selectById: selectAuthorById,
    selectAll: selectAllAuthors,
} = authorsAdapter.getSelectors(state => state.authors);
