import { isEqual } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useQueries, useQuery } from "react-query";
import { useDeepCompareEffect, usePrevious } from "react-use";
import { getJSON, getSession, getTable, post2server } from "../../api";
import { getAccessKey } from "../../cms/session";
import { dev, entities2unicode, say, str2val } from "../../shed";
import useStore from "../../store/store";

export const api_action = (command, tableNamePart="foam") => (data) => {
    const table = /^(public|dev)_/.test(tableNamePart)
    ? tableNamePart
    : dev() ? `dev_${tableNamePart}` : `public_${tableNamePart}`;
    const request = {
        command,
        table,
        access_key: getAccessKey(),
        data,
    };
    return post2server(request);
};

export const useToc = (tocFile) => useQuery(tocFile,
    () => getJSON(tocFile).then(response => {
        // console.log(response.filter(entry => entry.public));
        return dev() ? response : response.filter(entry => entry.public);
    }),
    {
        refetchOnWindowFocus: false,
    }
);

export const parseResponseDataArray = (data) => data.map((record) => {
    const mapped = {
        ...Object.entries(record).reduce(
            (obj, [key, value]) => ({
                ...obj,
                [key]: str2val(value),
            }),
            {}
        )
    };

    return mapped;
})

export const useUsers = (universe="system") => {
    const fetcher = () =>
        getTable({
            table: "public_users",
            where: { universe },
            access_key: getAccessKey(),
        }).then((response) => {
            const res = parseResponseDataArray(response);
            // console.log(res);
            return res;
        });

    return useQuery("users", fetcher);
};

/* export const useSession = () => {
    return useQuery("token", getSession, { refetchOnWindowFocus: false });
}; */

const mapper = (labels) => ({
    ...labels,
    _said: Object.entries(labels)
        .reduce((obj, [key, value]) => ({
            ...obj,
            [key]: Object.entries(value)
                .reduce((obj, [key, value]) => ({
                    ...obj,
                    [key]: Object.entries(value)
                        .reduce((obj, [key, value]) => ({
                            ...obj,
                            [key]: entities2unicode(say(value))
                        }), {})
                }), {})
        }), {})
});

const mappers = {
    labels: mapper,
}

export const useRequired = (required) => {

    const queries = required
    .map(item => ({
        queryKey: item,
        queryFn: () => getJSON(`/json/${item}.json`),
        refetchOnWindowFocus: false
    }));

    const res = useQueries(queries);

    const queryStatus = res.find(r=>r.status!=="success")?.status || "success";

    const data = useMemo(() => queryStatus==="success" && res
    .map(q=>q.data), [queryStatus, res]);

    const prevData = usePrevious(data);

    const setState = useStore(useCallback(state=>state.update, []));

    const [status, setStatus] = useState("idle");

    const updatedState = useStore(useCallback(state=>required
        .reduce((obj, key)=>({...obj, [key]: state[key]}),{}), [required]));

    const [updated, setUpdated] = useState();

    useDeepCompareEffect(() => {
        if (data) {
            if (isEqual(data, prevData)) return;
            setUpdated(
                required.reduce((obj, key, index) => (
                    {
                        ...obj,
                        [key]: mappers[key] ? mappers[key](data[index]) : data[index]
                    }
                ), {})
            );
        }
    }, [data, prevData, required]);

    useDeepCompareEffect(() => {
        // console.log("is updatedState equal to updated?", isEqual(updated, updatedState));
        if (updated) {
            if (isEqual(updated, updatedState)) {
                setStatus("success");
                return;
            }
            setState(updated);
        }
    }, [updated, updatedState]);

    return status;

};
