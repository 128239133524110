import { matchAll } from "../../shed";
import { getParams } from "./lang";

const match = (str, regex, index=1) => {
    const m = str.match(regex);
    return m && m[index];
};

const getDefName = template => match(template, /^\[\$(\w+):/);

export const rx = {

    params: /^\w\d+(?:\w\d+)*$/,

    components: [
        {
            rx: /^\[\w+:[^\]]+\]$/,
            name: "CDef"
        },
        {
            rx: /^\[\$\w+:[^>]+>/,
            name: "CCond"
        },
        {
            rx: /^\[\$\w+(?::[\w\d]+)?\]$/,
            name: "CValue"
        },
        {
            rx: /^\[[^\],]+(?:,\s*[^\],]+)+\]$/,
            name: "CSelect"
        }
    ],

    get: {
        CDef: {
            name: template => match(template, /^\[(\w+):/),
            string: template => match(template, /^\[\w+:([^\]]+)/),
        },
        CCond: {
            name: getDefName,
            string: template => match(template, /^\[\$\w+:([^\]]+)/),
        },
        CSelect: {
            string: template => match(template, /^\[(.+)\]$/),
        },
        CValue: {
            name: getDefName,
            string: template => match(template, /^\[(.+)\]$/),
        },
    },

    getObject: (template) => {
        // if (/^\s+/.test(template)) console.log(template);
        const t = tests.find(t => t.rx.test(template.trim()));
        if (t) return { type: t.type, value: t.value(template) }
    }

};

const tests = [
    {
        rx: /^\s*([^>',]+|'[^']+')\s*>/,
        type: "condition",
        value: str => {
            // console.log("condition value");
            const matches = matchAll(str, /\s*([^>',]+|'[^']+')/g);
            // console.log(matches);
            const conditions = matches
            .reduce((arr, next, i) => {
                if (i % 2 === 0) {
                    // console.log(`${next} is even`);
                    return [...arr, [next]]
                } else {
                    // console.log(`${next} is odd`);
                    const clone = arr.slice(0);
                    const last = clone.pop();
                    return [...clone, [...last, next]];
                }
                // return [...arr, next];
            }, []);

            return conditions;
        }
    },
    {
        rx: /^'[^']+'$/,
        type: "string",
        value: str => str.match(/^'([^']+)'$/)[1],
    },
    {
        rx: /^'[^']+'(?:,\s*'[^']+')+$/,
        type: "array",
        value: str => (
            matchAll(str, /'([^']+)'/g)
            // [...str.matchAll(/'([^']+)'/g)]
            // /'([^']+)'/g.exec(str)
        )
    },
    {
        rx: /^\$\w+(?::[\w\d]+)?$/,
        type: "def",
        value: str => {
            const name = str.match(/^\$(\w+)/)[1];
            const paramsMatch = str.match(/:([\w\d]+)$/);
            return {
                name, params: paramsMatch ? paramsMatch[1] : null
            }
            // name: str.match(/^\$(\w+)/)[1],
            // params: str.match(/:([\w\d]+)$/)[1]
        }
    },
    {
        rx: /^\$\w+:\$[\w\d]+$/,
        type: "def invisible",
        value: str => {
            const name = str.match(/^\$(\w+)/)[1];
            const params = str.match(/:\$([\w\d]+)$/)[1];
            return {
                name, params
            }
            // name: str.match(/^\$(\w+)/)[1],
            // params: str.match(/:([\w\d]+)$/)[1]
        }
    },
    {
        rx: /^\w\d+(?:\w\d+)*$/,
        type: "params",
        value: str => getParams(str)
    },
    {
        rx: /^\d+$/,
        type: "number",
        value: str => parseInt(str),
    },
    {
        rx: /^\*$/,
        type: "any",
        value: () => true,
    },
    {
        rx: /^[^\|]+\|[^\|]+/,
        type: "or array",
        value: str => str.split("|").map(s => rx.getObject(s)?.value),
    }
];
