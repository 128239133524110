import { useCallback, useMemo, useState } from "react";
import { entities2unicode } from "../../shed";
import useStore from "../../store/store";
import Select from "../../ui/Select";
import PopupSelect from "../../ui/bui/select/PopupSelect";
import { replaceCValues, useComponentValue } from "./express";
import { rx } from "./rx";


const CSelect = ({ template, expressionId, cname }) => {

    // console.log("--SELECT----------------------------");

    // const sliceDefs = useStore(state => state[expressionId] && state[expressionId].defs);
    const sliceValues = useStore(state => state[expressionId] && state[expressionId].values);

    const [index, setIndex] = useState(0);

    const value = useMemo(() => 
        rx.getObject(rx.get.CSelect.string(template))?.value,
    [template]);

    // console.log(value);

    // console.log(sliceValues);

    const options = useMemo(() => {
        return value.map((option, i) => (
            {
                value: i,
                label: replaceCValues(option, sliceValues||{})
            }
        ))
    }, [value, sliceValues]);

    const handleChange = useCallback((value) => {
        setIndex(parseInt(value));
    }, []);

    // const v = value[index];
    // console.log(v);

    useComponentValue(expressionId, cname, value[index]);


    return (
        <PopupSelect {...{ data: { list: options, selected: index }, handleChange, size: 10 }} />
        // <Select {...{ options, value: index, onSelectChange }} />
    )
};

export default CSelect;
