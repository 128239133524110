import { memo, useCallback, useMemo } from "react";
import { cl, entities2unicode, filterTree, findChild, findDescendants, mutateTree } from "../../../shed";
import useStore, { useSlice, useSliceSet } from "../../../store/store";
import { parse } from "../express";
import { getEntityValuesAndForm, getParams } from "../lang";
import { rx } from "../rx";
import { useLabel } from "../useLabel";


export const Item = memo(({ item, l=0 }) => {

    // console.log(`Item:`, item);

    const selected = useSlice("post", item.key);
    const setSelected = useSliceSet("post", item.key);

    const onClick = useCallback(() => {
        if (item.view !== "link") return;
        if (selected && selected.id === item.id) return;
        setSelected(item);
    }, [item, selected, setSelected]);

    if (!item.view) return null;

    const classList = [
        "item", {
            label: item.view === "label",
            selected: item.id === selected?.id
        }
    ]

    const text = getEntityValuesAndForm(item, item.params||{ declension: 0 }).form;

    // const params = item

    return (<>
        <div {...{onClick}} className={cl(...classList)}>{text}</div>
        { item.entities && <Items items={item.entities} l={l+1} /> }
    </>)
});

export const Items = memo(({ items, l=0 }) => {

    return (
        <div className={cl("items", `level${l}`)}>{
            items.map((item, i) => (
                <Item key={i} {...{ item, l } } />
            ))
        }</div>

    )
});
