import { cloneDeep } from "lodash";

export const getSyncQueue = ({ files: originalFiles, texts }) => {

    let files = cloneDeep(originalFiles);

    console.log(`getSyncQueue:`);
    console.log("texts:", texts);
    console.log("files:", files);

    let sync = [];

    const redundantFiles = texts
        .filter(text => !files.find(file =>
            file.name.toLowerCase()===text.html_file_name.toLowerCase()
        ));

    if (redundantFiles.length > 0) {
        sync = sync.concat(redundantFiles.map(text => ({ name: text.html_file_name, command: "delete", reason: "redundant" })));
    }

    const missingFiles = files.filter(file =>
        !texts.find(text =>
            text.html_file_name.toLowerCase()===file.name.toLowerCase()
        )
    );

    // console.log(`files not in the table`, missingFiles);

    if (missingFiles.length > 0) {
        // console.log(missingFiles.map(file => ({...file, operation: "insert" })));
        sync = sync
            .concat(missingFiles.map(file => (
                {...file, command: "insert", reason: "missing" }
            )));
        files = files
            .filter(file =>
                !missingFiles.find(missingFile => missingFile.name === file.name)
            );
    }

    // console.log(`rest of files:`, files);
    // console.log(`texts:`, texts);

    const updatedFiles = files
        .filter(file => {
            const text = texts.find(text => text.html_file_name.toLowerCase()===file.name.toLowerCase());
            return text.md5 !== file.md5;
        });

    if (updatedFiles.length > 0) {
        // console.log(missingFiles.map(file => ({...file, operation: "insert" })));
        sync = sync.concat(updatedFiles.map(file => ({...file, command: "replace", reason: "updated" })));
        files = files.filter(file => !updatedFiles.find(updatedFile => updatedFile.name === file.name));
    }

    console.log(`sync:`, sync);

    return sync.length > 0 && sync;
    
};
