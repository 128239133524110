import { useMemo } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getJSON } from "../../api";
import { entities2unicode, getRandomValue } from "../../shed";
import { useS } from "../../store/store";
import { Counter, Reaction } from "../foam/Like";
import "./Copyright.scss";

const Copyright = () => {
  const { status, data: copyrights } = useQuery(
    "copyright",
    () => getJSON("/json/copyright.json"),
    {
      refetchOnWindowFocus: false,
    }
  );

  const label = useS(`labels.ru.bubbles.rate copyright`);

  const copyright = useMemo(
    () => status === "success" && getRandomValue(copyrights),
    [copyrights, status]
  );

  if (status !== "success") return null;

  // console.log(copyrights);

  // console.log(copyright);

  return (
    <div className="copyright">
      <div className="container">
        <div className="content">
          {/* <div className="c">&copy;</div> */}
          <div className="text">{entities2unicode(copyright.ru)}</div>
          {/* <div className="year">{new Date().getFullYear()}</div> */}
        </div>
        <div className="reactions">
          <div className="chart">
            <Link to="/topzhop">
              <span className="top">TOP</span>
              <span className="z">Z</span>
              <span className="h">H</span>
              <span className="o">O</span>
              <span className="p">P</span>
            </Link>
          </div>
          <div className="likes">
            <Reaction
              {...{
                type: "dislike",
                object_id: copyright.id,
                object_desc: "copyright zhopa",
              }}
            />
            <Counter
              {...{
                type: "dislike",
                object_id: copyright.id,
                placeholder: `0`,
              }}
            />
            <Reaction
              {...{
                type: "like",
                object_id: copyright.id,
                object_desc: "copyright roza",
              }}
            />
            <Counter
              {...{ type: "like", object_id: copyright.id, placeholder: `0` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CopyrightSimple = () => (
  <div className="copyright">
    &copy; Romāns Voroņežskis, teksts; Einārs Pelšs, atdzejojums
  </div>
);

export default CopyrightSimple;
