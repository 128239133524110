import { memo, useState } from "react";
import { getText } from "../../api";
import parse from "html-react-parser";
import { useQuery } from "react-query";
import "./scribbles.scss";
import { round } from "../../shed";

const getSvg = (file) => () => getText(`/assets/img/scribbles/${file}.svg`)
.then(response => {
    // console.log(response);
    if (/<html[\s>]/m.test(response)) {
        console.log("not an svg file");
        return null;
    }
    // const parsed = parse(response);
    const parser = new DOMParser();
    const parsed = parser.parseFromString(response, "image/svg+xml");
    const svg = parsed.querySelector("svg");
    // console.log(svg);
    const viewBox = svg.getAttribute("viewBox");
    const pathElements = [...svg.querySelectorAll("path")];
    const paths = pathElements.map(path => (
        path.getAttribute("d")
    ));
    const strokeWidths = pathElements.map(path => {
        const match = path.getAttribute("style")
        .match(/stroke-width:([\d\.]+)px/);
        if (match) return parseFloat(match[1]);
        return null;
    }).filter(w => w !== null && !isNaN(w));
    console.log("strokeWidths:", strokeWidths);
    const strokeWidth = round(strokeWidths.reduce((a, b) => a + b) / strokeWidths.length, 2);
    console.log(strokeWidth);
    // console.log(paths);
    // console.log(res);
    return { viewBox, paths, strokeWidth };
});

export const Extract = memo(({ file }) => {

    const { status, data: content } = useQuery(["svg", file],
        getSvg(file),
        { refetchOnWindowFocus: false, enabled: !!file }
    )

    if (status !== "success") return null;

    return (
        <div>extracted:
            <Content {...{ content }} />
        </div>
    )
});

export const Content = ({ content }) => {
    console.log(content);
    return (
        <div>
            <div>
                scribble viewBox:
                <input defaultValue={ content.viewBox } />
            </div>
            <div>
                scribble strokeWidth:
                <input defaultValue={ content.strokeWidth + "px" } />
            </div>
            scribble paths:
            <textarea defaultValue={`${JSON.stringify(content.paths)}`} />
        </div>
    )
};

export const Create = () => {

    const [value, set] = useState("boring-song");

    const [file, setFile] = useState()
;
    const onInput = ({ target }) => {
        set(target.value);
    };

    const onClick = () => {
        setFile(value);
    };

    return (
        <div className="scribble create">create from file:<br />
            <input {...{ onInput, value }} />.svg
            <button {...{ onClick }}>try</button>
            <Extract {...{ file }} />
        </div>

    )
};