import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
    isDesktop,
    isMobile,
    isTablet,
    withOrientationChange,
} from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useDeepCompareEffect, usePrevious } from "react-use";
import { cl } from "../../shed";
import Radio from "../../ui/Radio";
import { Vradio } from "../../ui/Vradio";
import { simpleActions as a } from "../slicer/asyncSlicer";

const LangSelect0 = ({ bothAllowed }) => {

    const dispatch = useDispatch();
    const labels = useSelector(state => state.labels.value._said);
    const lang = useSelector(state => state.config.value.lang);
    const langs = useSelector(state => state.config.value.langs);

    const prevLang = useRef();

    useEffect(() => {
        if (!bothAllowed && lang === "both") {
            // console.log("Lang: not bothAllowed and lang is both");
            // console.log("Lang: dispatch primary lang:", langs[0])
            dispatch(a.config.update({ lang: langs[0] }));
            prevLang.current = "both";
            return;
        }
        if (bothAllowed && lang !== "both") {
            // console.log("Lang: bothAllowed and lang is not both");
            // console.log("Lang: prev lang:", prevLang.current);
            if (prevLang.current === "both") {
                // console.log("Lang: dispatch lang both")
                dispatch(a.config.update({ lang: "both" }));
                prevLang.current = lang;
            }
        }
    }, [bothAllowed, dispatch, lang, prevLang, langs])

    const options = useMemo(() => {
        // console.log("Lang: create new options");
        let options = [
            { value: "ru", label: labels.lv.ui["in russian"] },
            { value: "lv", label: labels.ru.ui["in latvian"] }
        ];
        if (bothAllowed) {
            options.push({ value: "both", label: labels.lv.ui["both langs"] });
        }
        options = options.map(option => (
            option.value === lang
            ? {...option, checked: true }
            : option
        ));

        return options;
    }, [bothAllowed, labels, lang]);

    const onChange = useCallback((value) => {
        // console.log("Lang: dispatch lang:", value);
        const updated = { lang: value };
        /* if (value !== "both") {
            updated.langs = value==="lv"
            ? ["lv", "ru"]
            : ["ru", "lv"];
            // console.log("Lang: dispatch langs:", updated.langs);
        } else {
            updated.langs = ["ru", "lv"];
        } */
        dispatch(a.config.update(updated));
        prevLang.current = value;
    }, [dispatch]);

    return (
        <div className={cl("lang")}>
            <Radio {...{ options, onChange }} />
        </div>
    );
};

const LangSelect = ({ bothAllowed, lang, onChange }) => {

    const labels = useSelector(state => state.labels.value._said);
    const langs = useSelector(state => state.config.value.langs);

    const prevLang = useRef();

    useEffect(() => {
        if (!bothAllowed && lang === "both") {
            // console.log("Lang: not bothAllowed and lang is both");
            // console.log("Lang: dispatch primary lang:", langs[0])
            onChange(langs[0]);
            prevLang.current = "both";
            return;
        }
        if (bothAllowed && lang !== "both") {
            // console.log("Lang: bothAllowed and lang is not both");
            // console.log("Lang: prev lang:", prevLang.current);
            if (prevLang.current === "both") {
                // console.log("Lang: dispatch lang both")
                onChange("both");
                prevLang.current = lang;
            }
        }
    }, [bothAllowed, lang, prevLang, langs, onChange])

    const options = useMemo(() => {
        // console.log("Lang: create new options");
        let options = [
            { value: "ru", label: labels.lv.ui["in russian"] },
            { value: "lv", label: labels.ru.ui["in latvian"] }
        ];
        if (bothAllowed) {
            options.push({
                value: "both",
                label: labels.lv.ui["both langs"],
                multiple: true
            });
        }
        options = options.map(option => (
            option.value === lang
            ? {...option, checked: true }
            : option
        ));

        return options;
    }, [bothAllowed, labels, lang]);

    const onRadioChange = useCallback((value) => {
        // console.log("Lang: dispatch lang:", value);
        onChange(value);
        prevLang.current = value;
    }, [onChange]);

    return (
        <div className={cl("lang")}>
            <Vradio {...{ options, onChange: onRadioChange }} />
        </div>
    );
};


const Lang = ({ isLandscape, isPortrait, lang, onChange }) => {
    if (!isLandscape && !isPortrait && !isDesktop && !isMobile) return null;
    const bothAllowed = (isTablet && isLandscape) || isDesktop;

    return (
        <LangSelect {...{ bothAllowed, lang, onChange }} />
    );
};


const Wrapped = isTablet ? withOrientationChange(Lang) : Lang;

export default Wrapped;
