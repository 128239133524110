import { cloneDeep } from "lodash";
import { useState } from "react";
import { memo } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDeepCompareEffect, usePrevious, useShallowCompareEffect } from "react-use";
import { getDir, post2server } from "../api";
import { Entities } from "../app/entities";
import { cl, entities2unicode, generateId, removeTags } from "../shed";
import { fetchAllAuthors, selectAllAuthors } from "./authorsSlice";

import "./cms.scss";
import Dir from "./Dir";
import { fetchTextFromHTMLFile } from "./fetchHTML";
import { getSyncQueue } from "./queue";
import Auth, { getAccessKey, getSessionKey } from "./session";
import { fetchTextsBy, selectAllTexts } from "./textsSlice";
import { fetchAllWorks, selectAllWorks, selectWorksByAuthorId } from "./worksSlice";


const Authors = ({ set, selectedId }) => {
    const mapper = (author) => (
        <div className="select" key={author.id}>
            <div
                className={cl("option", { selected: author.id === selectedId })}
                onClick={() => {
                    set(author);
                }}
            >
                {author.id}: {author.name_lat}
            </div>
        </div>
    );

    const authors = {
        name: "authors",
        fetcher: fetchAllAuthors,
        selector: selectAllAuthors,
        mapper,
    };

    return (
        <div>
            <h2>Authors</h2>
            <Entities {...authors} />
        </div>
    );
};

const Works = ({ authorId, set, selectedId }) => {
    const mapper = (work) => (
        <div className="select" key={work.id}>
            <div
                className={cl("option", { selected: work.id === selectedId })}
                onClick={() => {
                    set(work);
                }}
            >
                {work.id}: {work.name}
            </div>
        </div>
    );
    const works = {
        name: "works",
        fetcher: fetchAllWorks,
        selector: state => selectWorksByAuthorId(state, authorId),
        mapper,
    };

    return (
        <div>
            <h2>Works</h2>
            <Entities {...works} />
        </div>
    );
};

const Lang = ({ selectedLang, set }) => {

    return (
        <div className="select">
            { ["ru", "lv"].map((lang, i) => (
                <div
                    key={i}
                    className={cl("option", { selected: lang===selectedLang })}
                    onClick={ () => { set(lang) } }
                >{lang}</div>
            )) }
        </div>
    )
};

const Directory = ({ path, lang, set: parentSet }) => {

    // console.log(`HtmlDirectory path:`, path);

    const set = useCallback((files) => {
        parentSet(files.map(file => ({...file, lang })));
    }, [lang, parentSet]);

    const mapper = useCallback((file, i) => (
        <div key={i}>{file.name}</div>
    ), []);

    // const regexp = "/u|d/";

    return (
        <div>
            <h2>{path}</h2>
            <Dir {...{ path, mapper, set }} />
        </div>
    )
};

const Sync = ({ files, workId, authorId, authorFolder, workFolder, lang }) => {

    const dispatch = useDispatch();
    // const texts = useSelector(state => selectTextsByWorkId(state, workId));
    const texts = useSelector(selectAllTexts);

    const textsStatus = useSelector(state => state.texts.status);
    // const [status, setStatus] = useState("idle");
    const error = useSelector((state) => state.texts.error);

    const [queue, setQueue] = useState();
    const [syncing, setSyncing] = useState();

    const prevLang = usePrevious(lang);
    const prevWorkId = usePrevious(workId);

    useEffect(() => {
        if (textsStatus === "idle") {
            console.log("texts first time fetching");
            dispatch(fetchTextsBy({ work_id: workId, lang }));
            return;
        }
        if (textsStatus === "succeeded") {
            console.log("Sync fetched texts:", texts);
            // setStatus("ready");
            if ((prevLang !== lang) || (prevWorkId !== workId)) {
                console.log("texts re-fetching");
                dispatch(fetchTextsBy({ work_id: workId, lang }));
            }
        }
    }, [textsStatus, dispatch, texts, files, workId, prevWorkId, lang, prevLang]);


    /* useEffect(() => {
        if (status === "synced") {
            console.log("re-fetch texts");
            dispatch(fetchTextsByWorkId(workId));
        }
    }, [dispatch, status, workId]); */

    const sync = useCallback(() => {

        if (queue) {

            // console.log(queue);
            // return;

            // setStatus("syncing");
            // console.log(queue);
            // console.log(texts);

            const table = "public_texts";

            const requests = queue.map(file => {

                let id;

                const command = file.command;

                if (command === "replace" || command === "delete") {
                    const relatedTexts = texts.filter(text => text.html_file_name === file.name);
                    // console.log(`related texts:`, relatedTexts);
                    if (relatedTexts.length === 0) {
                        return Promise.reject(`no file ${file.lang}/${file.name} in this work`);
                    }
                    if (relatedTexts.length > 1) {
                        return Promise.reject(`more than one file ${file.lang}/${file.name} in this work`);
                    }
                    id = relatedTexts[0].id;
                    console.log(`${file.command} record ${file.name}: existing id ${id}`);
                }
                if (command === "insert") {
                    id = generateId();
                    console.log(`${command} record ${file.name}: new id ${id}`);
                }

                if (command === "delete") {
                    return post2server({
                        command,
                        table,
                        access_key: getAccessKey(),
                        // session_key: getSessionKey(),
                        data: { id }
                    });
                }

                return fetchTextFromHTMLFile({...file, preserve: ["title", "date", "tracknumber"]})
                    .then(res => {
                        let { text, title, date, tracknumber } = res;
                        console.log("tracknumber:", tracknumber);
                        if (date && /^\d\d[^\d]\d\d[^\d]\d\d\d\d$/.test(date)) {
                            date = date.replace(/^(\d\d)[^\d](\d\d)[^\d](\d\d\d\d)$/, "$3-$2-$1")
                        }
                        return post2server(
                            {
                                command,
                                table,
                                access_key: getAccessKey(),
                                // session_key: getSessionKey(),
                                data: {
                                    id,
                                    html_file_name: file.name,
                                    object_desc: `${authorFolder}/${workFolder}/${file.name}`,
                                    lang,
                                    md5: file.md5,
                                    text, title, date,
                                    track: parseInt(tracknumber),
                                    work_id: workId,
                                    author_id: authorId
                                }
                            }
                        );
                    })
            });

            Promise.all(requests)
                .then(() => {
                    console.log("Promise.all resolved");
                    // setStatus("synced");
                })
                .catch(err => {
                    console.log("Promise.all rejected with reason:", err);
                    // setStatus("failed");
                });

        }

    }, [queue, texts, workId, authorId, authorFolder, workFolder, lang]);


    if (textsStatus === "loading") {
        return <div>loading...</div>
    } else if (textsStatus === "failed") {
        return <div>{error}</div>
    }

    return (
        <div>
            <button onClick={
                ()=>{ setQueue(getSyncQueue({ files, texts })) }
            }>Get queue</button>
            <button onClick={sync} disabled={syncing}>Sync</button>
        </div>
    )
};

const SyncTexts = () => {
    const [author, setAuthor] = useState({});
    const [work, setWork] = useState({});
    const [lang, setLang] = useState("ru");
    const [path, setPath] = useState();
    const [files, setFiles] = useState();

    const onAuthorClicked = (value) => setAuthor(value);
    const onWorkClicked = (value) => setWork(value);
    const onLangClicked = (value) => setLang(value);
    const onFilesLoaded = useCallback(files => {
        setFiles(files)
    }, []);

    useEffect(() => {
        if (author.id) {
            // console.log(author);
            setWork({});
        } else { console.log("no author selected") }
    }, [author]);

    useEffect(() => {
        if (work.id) {
            // console.log(work);
        } else { console.log("no work selected") }
    }, [work]);

    useEffect(() => {
        if (author.id && work.id && lang) {
            setPath(`/html/authors/${author.html_folder_name}/${work.html_folder_name}/${lang}`);
        } else {
            if (path) setPath(null);
        }
    }, [author, work, lang, path]);

    useEffect(() => {
        if (path) {
            console.log(path);
        } else { console.log("no path selected") }
    }, [path]);
    
    useEffect(() => {
        if (files) {
            console.log("files:", files);
        } else { console.log("no files loaded") }
    }, [files]);

    return (
        <div className="cms">
            <Auth />
            <h1>Texts sync</h1>
            <Authors set={onAuthorClicked} selectedId={author.id} />
            {author.id && <Works authorId={author.id} set={onWorkClicked} selectedId={work.id} />}
            {work.id && <Lang selectedLang={lang} set={onLangClicked} />}
            {path && <Directory path={path} lang={lang} set={onFilesLoaded} />}
            {author.id && work.id && files &&
                <Sync
                workId={work.id} authorId={author.id}
                authorFolder={author.html_folder_name}
                workFolder={work.html_folder_name}
                {...{ files, lang }} />
            }
        </div>
    );
};

export default SyncTexts;
