import { Link } from "react-router-dom";
import Button from "../../ui/Button"
import "./Featured.scss";
import Image from "./Image";

export const Featured = () => {

    return (
        <Link to="/schauspiel">
            <div className="featured">
                <Button action>не добавить комментарий</Button>
                <Image />
            </div>
        </Link>
    )
};