
import { isMobile } from "react-device-detect";
import { Top10logo } from "./graphics";

const Head = ({ children }) => {
    return (
        <div className="head">{ children }</div>
    )
};

const List = ({ children }) => {
    return (
        <div className="list">
            {children}
        </div>
    )
};

const Legend = ({ children }) => {
    return (
        <div className="legend">
            { children }
        </div>
    )
};

const Top = ({ children, label, legend }) => {
    return (
        <div className="top rating">
            <div className="content">
                <div className="label">{label}</div>
                <Top10logo />
                <Legend>{legend}</Legend>
                <div className="table">{ children }</div>
            </div>
        </div>
    )
};

export const Chart = ({ label, entries, head, legend, n }) => {

    return (
        <Top {...{ label, legend }}>{
            
            isMobile ?

            <div className="chart">
                <Head>{head}</Head>
                <List>{entries.slice(0, n)}</List>
            </div>

            :
            <>
            <div className="chart">
                <Head>{head}</Head>
                <List>{entries.slice(0, n / 2)}</List>
            </div>
            <div className="chart">
                <Head>{head}</Head>
                <List>{entries.slice(n / 2, n)}</List>
            </div>
            </>

        }</Top>
    )
};
