import { isEqual } from "lodash";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { getJSON, getText } from "../../api";
import { entities2unicode, findBranch, findChild  } from "../../shed";
import useStore, { useS, useSlice, useSliceSet } from "../../store/store";
import { getComponents, parse } from "./express";
import { usePrevious } from "react-use";

import "./feed.scss";
import { getEmotions, getSubjects, useQueryOrData } from "./subjects";
import Select from "../../ui/Select";
import { useLabel } from "./useLabel";
import { Preview } from "./create/Preview";
import LineSelect from "../../ui/bui/select/LineSelect";

export const useExpressionStore = (expressionId) => {
    const createSlice = useS("createSlice");
    const slice = useS(expressionId);
    const set = useStore(state => state[expressionId]?.update);
    useEffect(() => {
        if (!slice) {
            // console.log("should create slice");
            createSlice(expressionId);
        }
    }, [slice, createSlice, expressionId]);
    if (!slice || !set) return [];
    return [slice, set];
};

const Editor = memo(({ children }) => {
    // useEffect(() => { console.log("Content rendered") });

    const prompt = useLabel("feed.post text");

    return (
        <div className="editor">
            <h2>{prompt}</h2>
            <div className="text">{children}</div>
        </div>
    )
});

const Render = memo(({ expressionString, expressionId, template, components }) => {

    // useTraceUpdate({ defs, expressionString, expressionId, template, components }, "Render");

    // console.log("RENDER: components:", components);

    const C = useMemo(() => components.map(c => ({
            ...c,
            component: getComponents(c.template)
    })), [components]);

    // console.log(C);

    const content = useMemo(() => {
        // console.log("creating content");

        // console.log(template);

        const invisibleComponents = C.filter(c => template.indexOf(`%${c.name}`) < 0);

        const invisibles = invisibleComponents.map(c => {
            const Component = c.component;
            return <Component invisible key={c.name} cname={c.name} template={c.template} {...{ expressionId, expressionString, origin }} />
        });

        // console.log("Invisibles:", invisibles);

        const visibles = template.map((item, i) => {
            if (/^%C\d+$/.test(item)) {
                // console.log(item);
                const v = item.match(/^%(C\d+)$/)[1];
                // console.log(v);
                const c = C.find(c => c.name===v);
                const Component = c.component;
                // console.log(c.name);
                // console.log(c.component);
                return <Component key={c.name} cname={c.name} template={c.template} {...{ expressionId, expressionString }} />
            } else {
                return /^\s+$/.test(item)
                ? " "
                : <span className="text" key={`text${i}`}>{entities2unicode(item)}</span>
            }
        });

        return [...invisibles, ...visibles];

        // return "";

    }, [C, expressionId, expressionString, template]);

    if (!content) return null;

    return (       
        <Editor>{content}</Editor> 
    )
});

export const Expression = ({ expression, ...defs }) => {

    // console.log(`Expression: defs: person entity:`, defs.person.entity);

    const [slice, set] = useExpressionStore(expression.id);

    const prevDefs = usePrevious(defs);
    const prevId = usePrevious(expression.id);

    const sliceDefs = useStore(state => state[expression.id] && state[expression.id].defs);
    const sliceValues = useStore(state => state[expression.id] && state[expression.id].values);

    const stringQuery = useQueryOrData({
        key: [expression.id, "text"],
        fn: () => getText(expression.file)
        .then(response => {
            const clean = response
            .replace(/\[(\s+)/g, "[")
            .replace(/(\s+)\]/g, "]")
            .replace(/\s\s+/g, " ");
            // console.log(clean);
            return clean;
        }),
        options: { refetchOnWindowFocus: false }
    }, expression.string);

    const string = stringQuery.status === "success" && (
        entities2unicode(stringQuery.data)
    );

    // console.log("Expression: string:", string);

    const { template, components } = useMemo(() => {
        // console.log("EXPRESSION: string:", string);
        const res = string ? parse(string) : {};
        // console.log(res);
        return res;
    }, [string]);

    useEffect(() => {
        // console.log(defs);
        if (!set) return;
        // console.log("current defs:", slice.defs);

        if (slice.defs) {
            if (isEqual(defs, prevDefs)) {
                if (prevId && expression.id === prevId) {
                    return;
                } else {
                    // console.log(`expression id changed, should I reset defs?`);
                }
                if (isEqual(slice.defs, defs)) {
                    // console.log(`defs of this expressions are equal to received defs, so no, you shouldn't`);
                    return;
                }
            }
        }
        // console.log(set);
        // console.log(slice);
        
        // console.log("set defs");
        set({ defs });
    }, [slice, prevId, expression.id, set, defs, prevDefs]);

    useEffect(() => {
        // console.log("EXPRESSION: set text: template:", template);
        // console.log("EXPRESSION: set text: sliceValues:", sliceValues);
        if (!set) return;
        if (!template) return;
        if (template.length===1 && !sliceValues) {
            // no components in template, single line of text
            set({ text: entities2unicode(template[0].trim()) });
            return;
        }
        if (!sliceValues) return;
        // console.log(template);
        const text = entities2unicode(template.map(item => {
            let breaker = 100;
            let res = item;
            while(/%C\d+/.test(res) && breaker > 0) {
                const key = res.match(/%(C\d+)/)[1];
                const val = sliceValues[key];
                res = res.replace(`%${key}`, val || "");
                breaker--;
            }
            return res;
        }).filter(s => !!s).join("").replace(/\s([\s\.,:;!\?])/g,"$1")).trim();
        // console.log(text);
        set({ text })
    }, [sliceValues, template, set]);

    if (!sliceDefs) return null;

    if (!string) return null;

    // console.log(sliceDefs);

    // console.log(string);

    // console.log(template);
    // console.log(components);

    return (
        <div className="expression">
            <Render {...{
                expressionString: string,
                expressionId: expression.id,
                template, components,
            }} />
            <Preview />
            {/* <Check {...{ expressionId: expression.id }} /> */}
        </div>
    )

};

const Check = ({ expressionId }) => {
    const [slice] = useExpressionStore(expressionId);
    useEffect(() => {
        // console.log(`%cexpression text:`, "color: orange", slice.text);
        console.log(`%cexpression slice:`, "color: orange", slice);
    }, [slice]);
    return null;
};


export const Expressions = memo(({ sliceKey="post" }) => {

    // const slice = useS(sliceKey);

    const setSliceExpressionId = useSliceSet(sliceKey, "expressionId");

    const { person, emotion, subject, expressionId } = useStore(state => state[sliceKey], isEqual);

    const data = useSlice(sliceKey, "data");
    // console.log(`Expressions: data:`, data);

    const prompt = useLabel("feed.expression variants");

    const { subjects, templates } = data;
    // const person = slice.person;
    // console.log(status);

    const [selected, setSelected] = useState();

    const handleChange = (id) => {
        setSelected(id);
    };

    const expressions = useMemo(() => {
        // console.log("EXPRESSIONS: create expressions");
        // console.log("EXPRESSIONS: slice emotion, subject:", slice.emotion.entity, slice.subject.entity);
        return templates.filter(expressionGroup => {
        // console.log("EXPRESSIONS: create expressions");

        const { emotions: xemotions, subjects: xsubjects } = expressionGroup;
        // console.log(xsubjects);
        if (xemotions) {
            if (!xemotions.includes(emotion.entity)) return false;
        }
        // console.log("search:", slice.subject.entity);
        if (xsubjects) {
            if (xsubjects.includes(subject.entity)) return true;
            if (xsubjects.includes("=" + subject.entity)) return true;
            const path = findBranch(subjects, "entity", subject.entity, "entities");
            // console.log(`parents of ${slice.subject.entity}:`, path);
            if (!path.some(s => xsubjects.includes(s))) {
                // console.log("none of parents is in", xsubjects);
                return false;
            }
        }
        return true;
    }).map(group => group.expressions).flat(1).filter(x => !x.disabled)}, [templates, subjects, emotion, subject]);

    const options = useMemo(() => {
        // console.log("EXPRESSIONS: create options: expressions:", expressions);
        if (!expressions) return;
        if (expressions.length < 1) return;
        return expressions && expressions.map(item => ({
            value: item.id,
            label: item.name,
        }));
    }, [expressions]);

    useEffect(() => {
        if (options && (!selected || !options.some(option => option.value===selected))) {
            // console.log("options:", options);
            setSelected(options[0].value);
            return;
        }
        if (!options && selected) {
            setSelected(null);
        }
    }, [selected, options]);

    useEffect(() => {
        // console.log("EXPRESSION: selected:", selected);
        // console.log("EXPRESSION: options:", options);
        if (options && selected && options.some(option => option.value===selected) && expressionId !== selected) {
            setSliceExpressionId(selected);
            return;
        }
        if (!selected && expressionId) {
            // console.log("remove old expressionId");
            setSliceExpressionId(null);
        }
    }, [selected, options, setSliceExpressionId, expressionId]);

    if (!person) return null;

    if (!options) return null;

    if (!selected || !options.some(option => option.value===selected)) return null;

    // console.log(expressions);
    // console.log(selected);

    // console.log("expressions:", filtered);

    // console.log(slice.subject);
    // console.log(`Expressions: person entity:`, person.entity.entity);
    
    return (<>
        <div className="expressions">
            { options.length > 1 && (
            <div className="variants">
                <h2>{prompt}</h2>
                <LineSelect {...{ data: { list: options, selected }, handleChange }} />
            </div>
            )}
            <Expression {...{ expression: expressions.find(e=>e.id===selected), person: person.entity, subject }} />
        </div>

    </>)
});
