import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getJSON } from "../../api";
import { useSet } from "../../App";
import { cl } from "../../shed";
import Button from "../../ui/Button";
import { Lang } from "../reader/Page";
import Bubbles from "./Bubbles";

import "./foam.scss";

// import create from "zustand";
// import createContext from "zustand/context";

import NewBubble from "./NewBubble";

const Foam = ({ object_id, object_desc, text }) => {
    // if (status !== "success") return <div>...</div>;

    // console.log(text, lang);

    const lang = sessionStorage.getItem("lesen lang");

    // console.log(lang);

    return (
        <div className={cl("foam")}>
            <Bubbles {...{ object_id, text }} />
            <NewBubble {...{ object_id, object_desc, text }} />
            {/* {
                lang==="ru" &&
                <Link className="top10" to="/top">ТОП 10 КОММЕНТАРИЕВ</Link>
            } */}
        </div>
    );
};


export default Foam;
