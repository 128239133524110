import { isEqual } from "lodash";
import { memo, useEffect, useMemo } from "react";
import { useComponentValue } from "./express";
import { useExpressionStore } from "./Expression";
import { getDefValue } from "./lang";
import { rx } from "./rx";


const CValue = memo(({ template, expressionId, cname }) => {

    // console.log("--CVALUE------------------");

    // console.log(template);

    const [slice, set] = useExpressionStore(expressionId);

    const value = useMemo(() => {

        const string = rx.get.CValue.string(template);

        // console.log(string);
    
        const object = rx.getObject(string);
    
        // console.log(object);

        // console.log(slice.defs);
    
        const def = slice.defs[object.value.name];

        // console.log(def);
        if (!def) return null;
    
        const value = getDefValue(def, object.value.params)?.value;
    
        // console.log(value);

        return value;

    }, [slice.defs, template]);

    useComponentValue(expressionId, cname, value);


    return (
        value ? <span className="value">{value}</span> : null
    )
});

export default CValue;
