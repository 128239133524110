import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit"
import { getTable } from "../api"
import { getExtraReducers } from "../features/slicer/extraReducers";
import { getAccessKey } from "./session";

const textsAdapter = createEntityAdapter(
    /* {
    sortComparer: (a, b) => b.date.localeCompare(a.date),
} */
);

const initialState = textsAdapter.getInitialState({
    status: 'idle',
    error: null,
});

export const fetchAllTexts = createAsyncThunk(
    'texts/fetchAllTexts',
    () => getTable({ table: "public_texts", access_key: getAccessKey() }),
);

export const fetchTextsByWorkId = createAsyncThunk(
    'texts/fetchTextsByWorkId',
    (workId) => getTable({ table: "public_texts", where: { work_id: workId }, access_key: getAccessKey() }),
);

export const fetchTextsBy = createAsyncThunk(
    'texts/fetchTextsBy',
    (where) => getTable({ table: "public_texts", where, access_key: getAccessKey() }),
);

const textsSlice = createSlice({
    name: "texts",
    initialState,
    reducers: {},
    extraReducers: getExtraReducers([
        fetchAllTexts,
        fetchTextsByWorkId,
        fetchTextsBy
    ], textsAdapter),
/*     extraReducers: {
        [fetchAllTexts.pending]: state => {
            state.status = "loading";
        },
        [fetchAllTexts.fulfilled]: (state, action) => {
            state.status = "succeeded";
            textsAdapter.setAll(state, action.payload);
        },
        [fetchAllTexts.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        },
        [fetchTextsByWorkId.pending]: state => {
            state.status = "loading";
        },
        [fetchTextsByWorkId.fulfilled]: (state, action) => {
            state.status = "succeeded";
            textsAdapter.setAll(state, action.payload);
        },
        [fetchTextsByWorkId.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        },
        [fetchTextsBy.pending]: state => {
            state.status = "loading";
        },
        [fetchTextsBy.fulfilled]: (state, action) => {
            state.status = "succeeded";
            textsAdapter.setAll(state, action.payload);
        },
        [fetchTextsBy.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        }
    } */
});

export default textsSlice.reducer;

export const {
    selectById: selectTextById,
    selectAll: selectAllTexts,
} = textsAdapter.getSelectors(state => state.texts);

export const selectTextsByWorkId = createSelector(
    [selectAllTexts, (_, workId) => workId],
    (texts, workId) => texts.filter((text) => text.work_id === workId)
);
