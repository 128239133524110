import { memo, useCallback, useEffect, useRef } from "react";
import { useQueryClient } from "react-query";
import { generateId } from "../../../shed";
import { useS, useSlice, useSliceSet } from "../../../store/store";
import Button from "../../../ui/Button";
import { useUserFromCookie } from "../../common/users";
import { Expressions } from "../Expression";
import { Lang } from "../Feed";
import { usePostCheck } from "../posts";
import { useLabel } from "../useLabel";
import { AddButton } from "./AddButton"
import { Menu } from "./Menu2";
import { Person } from "./person/persons";
import { PostButton } from "./PostButton";

const Message = ({ children }) => {
    return (
        <div className="message">{children}</div>
    )
};

const CancelButton = memo(() => {

    const post = useS("post");
    // const status = useSlice("post", "status");
    // const setStatus = useSliceSet("post", "status");

    const label = useLabel("feed.cancel post");


    const onClick = useCallback(() => {
        post.reset({ id: generateId(), status: "initial" });
    }, [post]);

    // console.log("PostButton: post status:", status);

    return (
        <Button {...{ onClick }} cancel>{label}</Button>
    )

});

const NewPost = memo(() => {

    useUserFromCookie();

    const createSlice = useS("createSlice");
    const post = useS("post");
    // const user = useS("user");
    const status = useSlice("post", "status");
    const setStatus = useSliceSet("post", "status");

    const { current: id } = useRef(generateId());

    const { status: checkStatus, msg: checkMessage } = usePostCheck(8);

    // const qc = useQueryClient();
    // console.log(`NewPost: user_id:`, user_id);

    /* useEffect(() => {
        if (user) {
            console.log(`NewPost: user: ${user.id}, name: ${user.first} ${user.second}`)
        } else {
            console.log(`NewPost: no user exist`);
        }
    }, [user]); */

    useEffect(() => {
        if (!post) createSlice("post", { id });
        // return () => { if (post && post.status && post.statues !== "initial") { console.log("%cNewPost unmount", "color:red", post) } }
    }, [post, createSlice, id]);

    useEffect(() => {
        if (!post) return;
        // console.log(setStatus);
        if (!status) setStatus("initial");
    }, [post, status, setStatus]);

    /* useEffect(() => {
        if (post && post.emotion && post.subject) {
            // console.log(`invalidate posts ${user_id}`);
            qc.invalidateQueries(["posts", user_id]);
        }
    }, [post, user_id, qc]); */

    useEffect(() => {
        if (!post) return;
        // if (status === "ready") return;
        if (status !== "ready" && checkStatus === "positive") {
            setStatus("ready");
            return;
        }
        // if (checkMessage) console.log(checkMessage);
        if (status === "ready" && checkStatus !== "positive") {
            setStatus("create");
        }
    }, [post, status, setStatus, checkStatus, checkMessage]);

    if (!post) return null;
    if (!status) return null;

    // console.log(`NewPost check status:`, checkStatus, "msg:", checkMessage);

    // console.log(`%cNewPost: status: ${status}`, "color:red");

    return (
        <div className="new post">
            <AddButton />
            { ["create", "ready"].includes(status) && <Menu /> }
            { post.emotion && post.subject && <Person /> }
            { post.emotion && post.subject && <Expressions /> }
            { ["create", "ready"].includes(status) && <div className="buttons"><PostButton /><CancelButton /></div> }
            { status !== "ready" && checkMessage && <Message>{checkMessage}</Message> }
            {/* <Check /> */}
        </div>
    )
});

const Check = () => {
    const post = useS("post");
    useEffect(()=>{
        console.log("%cpost", "color:orange", post);
    }, [post])
    return null;
};

export default NewPost;
