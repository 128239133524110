import { useCallback, useEffect, useRef, useState } from "react";
import "./Select.scss";
import { List, minItemHeight, sumItemHeights } from "./List";
import { cl, generateId } from "../../../shed";
import { getRect, scrollIntoView } from "../../../dom";
import { isEqual } from "lodash";
import { usePrevious } from "react-use";

const alignPopupWithButton = ({ button, popup }) => {
    
};

const PopupSelect = ({ id, data, handleChange, className, size, button, close }) => {
    // console.log("Select render");

    // console.log(id);

    const { list, selected: defaultSelected } = data;

    const [selected, setSelected] = useState(defaultSelected);
    const prev = usePrevious(selected);

    const [popup, set] = useState();
    const [style, setStyle] = useState({});

    const [clicked, setClicked] = useState();

    const selectedRef = useRef();
    const listRef = useRef();

    // const req = useRef();

    const handleItemClick = useCallback(
        (value) => () => {
            // console.log(`${value} clicked`);
            setSelected(value);
            setClicked(true);
        },
        []
    );

    useEffect(() => {
        if (popup && button && listRef.current) {
            // const { x, y } = button.getBoundingClientRect();
            const x = button.offsetLeft;
            const y = button.offsetTop;
            const updatedStyle = {
                ...style,
                position: "absolute",
                zIndex: 1009,
                left: x, top: y,
            };
            if (!isEqual(style, updatedStyle)) {
                // console.log("set style");
                setStyle(updatedStyle);
            }
        }
    }, [button, popup, style]);

    useEffect(() => {
        if (listRef.current) {
            // console.log("List selectedRef:", selectedRef);
            // selectedRef.current.scrollIntoView();
            const t = setTimeout(() => {
                if (selectedRef.current) scrollIntoView(selectedRef.current);                
            }, 0);
            return () => clearTimeout(t);
        }
    }, []);

    useEffect(() => {
        if (listRef.current) {
            const desiredHeight = size * minItemHeight(listRef.current);
            const realHeight = sumItemHeights(listRef.current);
            const height = desiredHeight > realHeight
            ? realHeight : desiredHeight;
            listRef.current.style.height = `${height}px`;
        }
    }, [size]);

    useEffect(() => {
        if (selected !== prev) handleChange(selected);
    }, [selected, prev, handleChange]);

    useEffect(() => {
        if (clicked) {
            close();
        }
    }, [close, clicked]);

    return (
        <div ref={node=>set(node)} className={cl("bui popup select", className)} style={style}>
            <List
                onItemClick={handleItemClick}
                {...{ id, list, selected, selectedRef, listRef }}
            />
        </div>
    );
};

const Popup = ({ data, handleChange, className, size }) => {

    const { current: id } = useRef(generateId());

    const label = data.list.find(item => item.value===data.selected).label;

    const [button, set] = useState();

    const [open, setOpen] = useState();
    
    const close = useCallback(() => setOpen(false), []);

    const clickOutside = useCallback((e) => {
        if (open) {
            const clickedElement = e.target;
            const clickedId = clickedElement.parentNode.getAttribute("id");
            if (clickedId !== id) {
                // console.log("should close");
                close();
            }
        }
    }, [open, id, close]);

    const onClick = useCallback(() => {
        if (!open) setOpen(true);
    }, [open]);

    useEffect(() => {
        if (open) {
            document.body.addEventListener("click", clickOutside, true);
        }
        return () => document.body.removeEventListener("click", clickOutside, true);
    }, [open, clickOutside]);

    return (<>
        <div data-value={data.selected} {...{ onClick }} ref={node=>set(node)} className="bui popup button">{ label }</div>
        { open && <PopupSelect {...{ id, data, handleChange, className, size, button, close }} /> }
    </>)
};

const Container = ({ data, handleChange, className, size=2 }) => {
    // console.log("Container render");

    return <Popup {...{ data, handleChange, className, size }} />;
};

export default Container;
