import { config } from "react-spring";
import { camelCase } from "../../shed";

export function getConfigFromAttr(attr) {

    const parts = attr.split(":");
    if (parts.length < 3) return config.default;
    const configStr = parts[2];
    if (/^[a-z]+$/.test(configStr)) return config[configStr];
    if (!/=/.test(configStr)) throw new Error("config must be in a=b,c=d format");
    const objStr = `{${configStr
        .split(",")
        .map(entry => entry.trim().replace(/([^=]+)=([^=]+)/g, `"$1": $2`))
        .join(", ")}}`;
    // console.log(objStr);
    // console.log(JSON.parse(objStr));
    return JSON.parse(objStr);
}

export const getStyles = (node, what) => {

    let rules = [];
    let coords = [];

    switch (what) {
        case "main": {
            rules = [
                "color", "background-color",
                "width", "height",
                "border-bottom-left-radius",
                "border-top-left-radius",
                "border-top-right-radius",
                "border-bottom-right-radius",
            ];
            break;
        }
        case "size": {
            rules = [
                "width", "height"
            ];
            break;
        }
        case "styledPosition": {
            rules = [
                "width", "height", "left", "top"
            ];
            break;
        }
        case "position": {
            // console.log("set position");
            coords = [
                "left", "top", "width", "height"
            ];
            break;
        }
        default: return {}
    }

    const compStyle = getComputedStyle(node);

    let res = {};

    if (rules.length > 0) res = rules.reduce((style, rule) => ({
        ...style,
        [camelCase(rule)]: compStyle.getPropertyValue(rule)
    }), {});

    if (coords.length > 0) {
        const rect = node.getBoundingClientRect();
        res.position = "absolute";
        res = coords.reduce((style, rule) => ({
            ...style,
            [camelCase(rule)]: rect[rule]
        }), res);
        // console.log(node, res);
    }

    // console.log(res);

    return res;

};

export const clean = (spring) => {
    let cleanSpring = { from: {}, to: {} };
    Object.keys(spring.to).forEach(key => {
        // console.log(key, spring.to[key]);
        if (spring.from[key] !== spring.to[key] || key==="top") {
            cleanSpring.from[key] = spring.from[key];
            cleanSpring.to[key] = spring.to[key];
        }
    });
    // console.log(cleanSpring);
    return Object.keys(cleanSpring.to).length > 0 ? cleanSpring : null;
};
