import { useQuery } from "react-query";
import { getJSON } from "../../api";


const dataFilter = record => !record.disabled;

export const getSubjects = () => getJSON("/json/feed/subjects.json").then(response => {
    // console.log(Object.values(response));
    return Object.values(response).filter(dataFilter);
});

export const getEmotions = () => getJSON("/json/feed/emotions.json").then(response => {
    // console.log(response);
    return response.filter(dataFilter);
});

export const useQueryOrData = (query, data) => {
    const { key="dummy", fn=()=>Promise.resolve(), options={} } = query;
    const response = useQuery(key, fn,
        {...options, enabled: !data, refetchOnWindowFocus: false }
    );
    return data ? {
        status: "success",
        data: Array.isArray(data)
            ? data.filter(dataFilter)
            : dataFilter(data) && data
        } : response;
};
