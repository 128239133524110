import { cloneDeep, isEqual } from "lodash";
import { useEffect, useRef } from "react";

export function objType(obj) {
    return /^\[object (\w+)]$/
        .exec(Object.prototype.toString.call(obj))[1]
        .toLowerCase();
}

export function generateId() {
    function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return (
        S4() +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        S4() +
        S4()
    );
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomValue(array) {
    const arr = array.map((e) =>
        objType(e) === "object"
            ? e.hasOwnProperty("chance")
                ? e
                : { ...e, chance: 1 }
            : { value: e, chance: 1 }
    );

    // console.log(arr);

    const sum = arr.reduce((acc, e) => acc + e.chance, 0);
    //console.log(sum);
    const random = getRandomInt(0, sum - 1);
    //console.log(random);
    let acc = 0;

    for (let i = 0; i < arr.length; i++) {
        const min = acc;
        const max = arr[i].chance + acc - 1;
        //console.log(`for ${arr[i].str}: ${min}-${max}`);
        acc += arr[i].chance;
        if (random >= min && random <= max) {
            return arr[i].hasOwnProperty("value") ? arr[i].value : arr[i];
        }
    }
    return null;
}

export const say = (obj) => {
    // console.log("saying", obj);
    switch (objType(obj)) {
        case "string": {
            return obj;
        }
        case "array": {
            return getRandomValue(obj);
        }

        default:
            return obj;
    }
};

export const falsy2text = (b) =>
b === false
    ? "@false"
    : b === null
    ? "@null"
    : b === undefined
    ? "@undefined"
    : b === ""
    ? "@empty"
    : b;


export function cl() {
    const classList = Array.prototype.slice
        .call(arguments)
        .filter((c) => c)
        .map((c) => {
            if (objType(c) === "object") {
                return Object.keys(c)
                    .map((key) => (c[key] === true ? key : false))
                    .filter((c) => c);
            }
            return c;
        })
        .flat(Infinity);

    // console.log(classList);

    return classList.length > 0 ? classList.filter((c) => c).join(" ") : null;
}

export function entities2unicode(str) {
    if (objType(str) !== "string") {
        // console.log("entities2unicode: type mismatch:", str);
        // throw new Error(str);
        return str;
    }
    let res = str;
    [
        { e: "#9312", u: "\u2460" },
        { e: "#9313", u: "\u2461" },
        { e: "#9314", u: "\u2462" },
        { e: "#9315", u: "\u2463" },
        { e: "#9316", u: "\u2464" },
        { e: "#9317", u: "\u2465" },
        { e: "#9318", u: "\u2466" },
        { e: "#9319", u: "\u2467" },
        { e: "#9320", u: "\u2468" },

        { e: "times", u: "\u00D7" },
        { e: "thinsp", u: "\u2009" },
        { e: "copy", u: "\u00A9" },
        { e: "nbsp", u: "\u00A0" },
        { e: "mdash", u: "\u2014" },
        { e: "ndash", u: "\u2013" },
        { e: "shy", u: "\u00AD" },
        { e: "bdquo", u: "\u201E" },
        { e: "ldquo", u: "\u201C" },
        { e: "laquo", u: "\u00AB" },
        { e: "raquo", u: "\u00BB" },
    ].forEach((entity) => {
        res = res.replace(new RegExp(`&${entity.e};`, "g"), entity.u);
    });
    return res;
}

export function removeTags(str) {
    return str.replace(/<[^>]*>/g, "");
}

export const cap = (string) => string[0].toUpperCase() + string.substring(1);

export function useTraceUpdate(props, title) {
    // https://stackoverflow.com/questions/41004631/trace-why-a-react-component-is-re-rendering
    const prev = useRef(props);
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
                ps[k] = [prev.current[k], v];
            }
            return ps;
        }, {});
        if (Object.keys(changedProps).length > 0) {
            const eq = isEqual(prev.current, props);
            console.log(
                `%cTrace: ${title} props changed ${
                    eq ? "only by reference" : ""
                }`,
                eq ? "color:orange" : "color:green",
                changedProps
            );
        }
        prev.current = props;
    });
}

export function camelCase(str) {
    const attrs = {
        class: "className",
        rowspan: "rowSpan",
        colspan: "colSpan",
        srcset: "srcSet",
        strokewidth: "strokeWidth",
        viewbox: "viewBox",
    };

    if (Array.isArray(str)) return str.map((s) => camelCase(s));

    if (objType(str) !== "string") return str;

    if (attrs.hasOwnProperty(str)) return attrs[str];

    if (Object.values(attrs).includes(str)) return str;

    // https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case/37041217
    return str
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

export function shuffleArray(array) {
    const newArray = [...array];
    // https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
    for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
}

export function single(arr) {
    if (!Array.isArray(arr)) throw new Error("Not an array");
    if (arr.length !== 1) throw new Error("Not a single");
    return arr[0];
}

export function inViewHeight(node, percentage) {
    const viewportHeight = window.innerHeight;
    const { top, height } = node.getBoundingClientRect();
    console.log(
        `viewport: ${viewportHeight}, node top: ${top}, node top+${percentage}*${height}: ${
            top + height * percentage
        }`
    );
    return top + height * percentage <= viewportHeight;
}

export function nodeInViewHeight(node) {
    const viewportHeight = window.innerHeight;
    const { top, height, bottom } = node.getBoundingClientRect();
    if (height === 0) return;
    let visibleHeight = height;
    if (top < 0) visibleHeight += top;
    if (bottom > viewportHeight) visibleHeight -= bottom - viewportHeight;
    return visibleHeight / height;
}

export function getDeepKeyValue(obj, keyString) {
    if (!/\./.test(keyString)) return obj[keyString];
    const keys = keyString.split(".");
    let res = obj;
    for (let i = 0; i < keys.length; i++) {
        res = res[keys[i]];
        if (res === undefined) break;
    }
    return res;
}

export const exact = (prev, next) => isEqual(prev, next);

export const eq = (value, strValue) => {
    if (objType(value) === "string") return value === strValue;
    if (objType(value) === "number") return value === parseFloat(strValue);
    if (objType(value) === "object") {
        try {
            const obj = JSON.parse(strValue);
            return isEqual(value, obj);
        } catch {
            return false;
        }
    }
};

export const str2val = (str) => {
    if (objType(str) !== "string") return str;
    try {
        const obj = JSON.parse(str);
        return obj;
    } catch {
        if (/^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d\.\d{3}Z$/.test(str)) {
            // console.log("date found", new Date(str));
            return new Date(str);
        }
        return str;
    }
};

export const convertDatesToISOStrings = (obj) => {
    if (Array.isArray(obj)) {
        return (obj.map(o => convertDatesToISOStrings(o)));
    }
    // console.log("obj:", obj);
    if (objType(obj) === "date") {
        return obj.toISOString();
    }
    if (objType(obj) !== "object") return obj;
    return Object.entries(obj).reduce((res, [key, val]) => {
        return {...res, [key]: convertDatesToISOStrings(val) }
    }, {});
};

export const convertStringsToValues = (obj) => {
    if (Array.isArray(obj)) {
        return (obj.map(o => convertStringsToValues(o)));
    }
    if (objType(obj) === "string") return str2val(obj);
    if (objType(obj) !== "object") return obj;
    return Object.entries(obj).reduce((res, [key, val]) => (
        {...res, [key]: convertStringsToValues(val) }
    ), {});
};

export const stringifyValues = (obj) => {

    if (Array.isArray(obj)) return obj.map(o => stringifyValues(o));

    const stringified = Object.entries(obj).reduce((updated, [key, val]) => ({
        ...updated,
        [key]: ["array", "object"].includes(objType(val)) ? JSON.stringify(val) : val,
    }), {});

    return stringified;

}

export const dev = () => process.env.NODE_ENV === "development";

export const findChild = (obj, condition) => {
    if (Array.isArray(obj)) {
        for (let i = 0, l = obj.length; i < l; i++) {
            const res = findChild(obj[i], condition);
            if (res !== null) return res;
        }
        return null;
    }
    if (condition(obj)) return obj;
    if (objType(obj) === "object") {
        const keys = Object.keys(obj);
        for (let i = 0, l = keys.length; i < l; i++) {
            const res = findChild(obj[keys[i]], condition);
            if (res !== null) return res;
        }
    }
    return null;
};

export function findParentOfValue(obj, levelKey, key, val) {
    //console.log(obj);
    let res = false;
    if (Array.isArray(obj)) {
        for (let i=0, len=obj.length; i<len; i++) {
            res = findParentOfValue(obj[i], levelKey, key, val);
            if (res !== false) return res;
        }
    } else {
        if (obj.hasOwnProperty(key)) {
            if (obj[key]===val) return obj;
        }
        if (!obj.hasOwnProperty(levelKey)) {
            return false;
        }
        res = findParentOfValue(obj[levelKey], levelKey, key, val);
    }
    return res;
}

export function matchAll(string, regex, index=1) {
    // https://stackoverflow.com/questions/432493/how-do-you-access-the-matched-groups-in-a-javascript-regular-expression
    // index || (index = 1);
    // default to the first capturing group
    const matches = [];
    let match;
    while ((match = regex.exec(string))) {
        matches.push(match[index]);
    }
    return matches;
}

export const collectAll = (obj, key, collection=[]) => {
    
    let res = [];

    // console.log("parse obj:", obj);
    // console.log("obj keys:", Object.keys(obj));

    if (Array.isArray(obj)) {
        res = obj.reduce((acc, e) => (collectAll(e, key, acc) || acc), collection);
    } else {

        if (objType(obj) === "object") {

            res = [...collection];

            if (obj.hasOwnProperty(key)) res.push(obj[key]);

            const keys = Object.keys(obj);
            keys.forEach(okey => {
                if (okey !== key) {
                    if (objType(obj[okey]) === "object" || objType(obj[okey]) === "array") {
                        res.push(collectAll(obj[okey], key, collection));
                    }
                }
            });

        }

    }

    res = res.flat(Infinity)

    if (res.length > 1) {
        res = res.reduce((acc, e) => acc.some(obj => isEqual(obj, e)) ? acc : [...acc, e], []);
    }

    return res.flat(Infinity);

};

export const menuer = ({
    obj, key, name=key, keys=[], accs=[], values=[], separator="", tag, collection
}) => {

    // key - key to traverse, name - its new name, keys - keys to copy/true=all
    // accs - keys to accumulate values
    // tag - tag key name, collection - tag collection key name

    let res;

    if (Array.isArray(obj)) {
        res = obj.map(e => (menuer(e, key, name, keys, accs, values, separator, tag, collection)))
    } else {
        const origin = cloneDeep(obj);
        const updatedValues = cloneDeep(values);
        // console.log(Object.keys(obj));
        res = Object.keys(obj)
            .reduce((acc, key)=> {

                // console.log("key:", key, "val:", origin[key]);

                if (Array.isArray(keys) && keys.includes(key)) return {...acc, [key]: origin[key] }
                if (accs.includes(key)) {
                    const index = accs.indexOf(key);
                    if (values.length===accs.length) {
                        updatedValues[index] = values[index] + origin[key] + separator;
                        return {...acc, [key]: updatedValues[index] }
                    }
                }
                if (keys===true) 
                    if (!accs.includes(key)) 
                        return {...acc, [key]: origin[key] }
                    
                return acc;
            }, {});

        if (obj.hasOwnProperty(key)) {
            res[name] = menuer(obj[key], key, name, keys, accs, updatedValues, separator, tag, collection);

            if (tag && collection) {
                
                res[collection] = collectAll(obj[key], tag).unique().sort((a, b) => a.localeCompare(b));
            }
        }
    }
    return res;
}

export const findBranch = (
     obj, key, value, traverseKey, acc=[]
) => {

    if (Array.isArray(obj)) {
        // console.log("array");
        for (let i=0, l=obj.length; i<l; i++) {
            const res = findBranch(obj[i], key, value, traverseKey, acc);
            if (res) return res;
        }
        return false;
    }

    // console.log(obj);

    // console.log(`${key}: ${obj[key]}`);


    if (obj[key] === value) {
        // console.log("finished");
        return acc;
    }

    if (obj.hasOwnProperty(traverseKey)) {

        if (obj.hasOwnProperty(key)) {
            acc.push(obj[key]);
        } else {
            acc.push("unknown");
        }
        // console.log("node saved", acc);

        const res = findBranch(obj[traverseKey], key, value, traverseKey, acc);

        if (res) return acc;

        acc.pop();

    }

    // console.log("end of branch, not found");

    // console.log("acc:", acc);

    // acc.pop();

    // while (acc.length > 0) { acc.pop(); }

    return false;

};

// https://gist.github.com/djD-REK/068cba3d430cf7abfddfd32a5d7903c3
export const round = (number, decimalPlaces) =>
    Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    
export const filterObject = (obj, condition) => {
    if (Array.isArray(obj)) {
        const res = obj.map(e => filterObject(e, condition)).filter(e=>e!==false);
        return res.length > 0 ? res : false;
    }

    if (objType(obj) === "object") {

        let res = {};

        const fields = Object.entries(obj).reduce((acc, [key, val]) => (
            !["object", "array"].includes(objType(val))
            ? { ...acc, [key]: val } : acc       
        ), {});
        
        const arrays =  Object.entries(obj).reduce((acc, [key, val]) => (
            objType(val) === "array" && filterObject(val, condition)
            ? { ...acc, [key]: filterObject(val, condition) } : acc            
        ), {});

        const objects =  Object.entries(obj).reduce((acc, [key, val]) => (
            objType(val) === "object" && filterObject(val, condition)
            ? { ...acc, [key]: filterObject(val, condition) } : acc            
        ), {});

        if (Object.keys(arrays).length > 0 || Object.keys(objects).length > 0 || condition(obj)) {
            res = {...fields, ...arrays, ...objects }
        }

        return (Object.keys(res).length > 0) && res;
    }

    if (condition(obj)) return obj;
    
    return false;
};

export const filterTree = (obj, traverseKey, condition) => {
    // console.log("obj:", obj);
    if (Array.isArray(obj)) {
        // console.log("obj:", obj);
        // console.log("is array");
        const res = obj.map(e => filterTree(e, traverseKey, condition)).filter(e => e!==false);
        // console.log("mapped array:", res);
        return res.length > 0 ? res : false;
    }
    let children, res;

    if (objType(obj) === "object") {
        if (obj.hasOwnProperty(traverseKey)) {
            // console.log("has", traverseKey);
            // console.log(`obj.${traverseKey}:`, obj[traverseKey]);
            children = filterTree(obj[traverseKey], traverseKey, condition);
            // console.log("children filtered:", children);
            if (children) return {...obj, [traverseKey]: children };
            res = {...obj };
            delete res[traverseKey];
            return condition(res) && res;
        }
    }
    return condition(obj) && obj;
};

export const mutateTree = (obj, traverseKey, condition) => {
    // console.log("obj:", obj);
    if (Array.isArray(obj)) {
        // console.log("obj:", obj);
        // console.log("is array");
        const res = obj.map(e => mutateTree(e, traverseKey, condition));
        // console.log("mapped array:", res);
        // return res.length > 0 ? res : false;
        return res;
    }

    let res = cloneDeep(obj);

    if (objType(res) === "object") {
        if (res.hasOwnProperty(traverseKey)) {
            // console.log("has", traverseKey);
            // console.log(`obj.${traverseKey}:`, obj[traverseKey]);
            const children = mutateTree(res[traverseKey], traverseKey, condition);
            // console.log("children filtered:", children);
            /* return {
                ...((res)),
                [traverseKey]: children
            }; */
            res[traverseKey] = children;
        }
    }
    const mutator = condition(res);
    return mutator ? mutator(res) : res;
};

export const findDescendants = (obj, traverseKey, key, acc=[]) => {
    if (Array.isArray(obj)) {
        return obj.map(e => findDescendants(e, traverseKey, key, [])).flat(1);
    }

    if (objType(obj) === "object") {
        if (obj.hasOwnProperty(key)) {
            acc.push(obj[key]);
        }
        if (obj.hasOwnProperty(traverseKey)) {
            const res = findDescendants(obj[traverseKey], traverseKey, key, []);
            acc.push(res.flat(1));
        }
    }
    return acc.flat(1);
};

export const obj2keyval = (obj) => {

    // converts object/array to { key, value }/[{ key, value }]

    if (objType(obj) === "array") {
        return obj.map(item => obj2keyval(item));
    }

    if (objType(obj) !== "object") {
        console.log("Warning: not an object nor array", obj);
        return obj;
    }

    const entries = Object.entries(obj);

    if (obj.hasOwnProperty("key") && obj.hasOwnProperty("value")) {
        if (entries.length > 2) {
            console.log("Warning: obj has key/value and extra keys");
        }
        return obj;
    }

    if (entries.length === 1) {
        const [key, value] = entries[0];
        return { key, value };
    }

    return entries.map(([key, value]) => (
        { key, value }
    ));
    
};
