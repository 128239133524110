import { useContext } from "react";
import { useQuery } from "react-query";
import { getTable, getTableS } from "../../api";
import { getAccessKey } from "../../cms/session";
import { convertStringsToValues, dev, round, str2val } from "../../shed";
import { useS, useSlice } from "../../store/store";
import { Lang } from "./Feed";
import { template2text } from "./lang";
import { useLabel } from "./useLabel";

export const getPosts = ({ where, what, order, limit }) => {
    const request = {
        table: dev() ? "dev_posts" : "public_posts",
        access_key: getAccessKey(),
    };
    if (where) request.where = where;
    if (what) request.what = what;
    if (order) request.order = order;
    if (limit) request.limit = limit;
    // console.log(request);
    return getTableS(request)
    .then(response => convertStringsToValues(response));
};

export const usePostCheck = (minHoursSincePostWithThisExpressionEmotionSubject) => {

    const user_id = useSlice("user", "id");
    const emotion = useSlice("post", "emotion");
    const subject = useSlice("post", "subject");
    const expression_id = useSlice("post", "expressionId");

    // console.log(`usePostCheck: ${emotion?.entity} ${subject?.entity}`);
    
    const enabled = !!(user_id && expression_id);

    const label_recently = useLabel("feed.posted recently", true);
    const label_just = useLabel("feed.just posted", true);

    const { status: queryStatus, data } = useQuery(["posts", user_id, expression_id],
        () => getPosts({ where: { user_id, expression_id }}),
        { refetchOnWindowFocus: false, enabled }
    );

    if (emotion && subject && expression_id && !user_id) {
        return {
            status: "positive",
            msg: "no user"
        }
    }

    if (queryStatus !== "success") return { status: queryStatus };
    if (!emotion || !subject) return { status: "not ready" };


    if (data.length===0) {
        // console.log("no posts with this expression");
        return {
            status: "positive",
            msg: `no post with expression_id: ${expression_id}, user_id: ${user_id}`
        };
    }

    const posts = convertStringsToValues(data)
    .filter(post => (
        post.expression_state.emotion === emotion.entity &&
        post.expression_state.subject === subject.entity
    ));

    if (posts.length===0) {
        // console.log("no posts with this expression, emotion and subject");
        return {
            status: "positive",
            msg: `this kind of expression of this emotion on this subject was never posted`
        };
    }

    // console.log(`usePostCheck: found posts:`, posts);

    const date = new Date();

    const latestPost = posts.reduce((post, next) => {
        const postDate = post.updated || post.created;
        const nextDate = next.updated || next.created;
        if (date - nextDate < date - postDate) return next;
        return post;
    });

    const diff = date - (latestPost.updated || latestPost.created);
    const minMs = minHoursSincePostWithThisExpressionEmotionSubject * 60 * 60 * 1000;


    const label = diff/3600000 < 1
    ? label_just
    : label_recently

    let res;

    if (diff < minMs) {
        res = {
            status: "negative",
            msg: template2text({ emotion, subject }, label),
            system_msg: `last time this kind of expression of this emotion on this subject was posted ${round(diff/3600000, 2)} hours ago, which is later than ${minHoursSincePostWithThisExpressionEmotionSubject}`
        }
    } else {
        res = {
            status: "positive",
            system_msg: `last time this kind of expression of this emotion on this subject was posted ${round(diff/3600000, 2)} hours ago`
        }
    }

    // console.log(`usePostCheck: status ${res.status}`);

    return res;

};

export const getPost = (id) => {
    return getPosts({ where: { id }})
    .then(response => {
        // console.log(response);
        if (response.length===0) return Promise.reject("not found");
        if (response.length!==1) return Promise.reject("error");
        return response[0];
    });
};

export const postExists = (postId, resolve, reject) => (
    getPost(postId)
    .then(res => {
        console.log("posts exists");
        resolve(res);
    })
    .catch(res => {
        if (res === "not found") {
            reject();
            return;
        }
        console.log(res);
    })
);
