import { memo, useCallback, useEffect, useState } from "react";
import { usePrevious } from "react-use";
import { cl, eq } from "../shed";

const checkedValue = (options) => {
    // console.log("Radio options:", options);
    return (options.find((option) => option.checked) || {}).value
};

const V = () => (
    <svg viewBox="0 0 32 32">
        <path d="M8,19.692L15.808,27.5L28.301,15.007"/>
    </svg>
);

const VV = () => (
    <svg viewBox="0 0 32 32">
        <path d="M4,11.463L9.175,16.638L17.456,8.357" />
        <path d="M13.845,22.402L19.02,27.577L27.301,19.296" />
    </svg>
);

const Voption = ({ value, multiple, checked, onClick, children }) => {

    const content = checked
    ? (
        <div className="option checked" data-value={value} onClick={onClick}>
            {multiple ? <VV /> : <V />}
            {children}
        </div>
    )
    : (
        <div className="option" data-value={value} onClick={onClick}>{children}</div>
    )

    return (
        content
    )
};

export const Vradio = memo(({ options, onChange }) => {
    // console.log("Radio options", options);

    const [value, setValue] = useState();

    const onValueChange = useCallback(({ target }) => {
        setValue(target.getAttribute("data-value"));
    }, []);

    const prevOptions = usePrevious(options);

    // useTraceUpdate({ options, onChange, value }, "Bradio");

    useEffect(() => {
        // console.log("Radio: received options checked:", checkedValue(options));
        setValue(checkedValue(options));
    }, [options]);

    useEffect(() => {
        // console.log("Radio: value:", value);
        // console.log("Radio: checked in options:", checkedValue(options));
        if (value === undefined) {
            // console.log("Radio: do nothing: value is undefined");
            return;
        }
        if (checkedValue(options) === undefined) {
            // console.log("Radio: do nothing: checked option is undefined");
            return;
        }
        if (options !== prevOptions) {
            // console.log("Radio: do nothing: new options received");
            return;
        }
        if (value === checkedValue(options)) {
            // console.log("Radio: do nothing: value and checked option is the same");
            return;
        }
        onChange(value);
    }, [value, options, prevOptions, onChange]);

    // console.log("value:", value);

    return (
        <div className={cl("vradio")}>
            {options.map((option, i) => {
                // console.log("option.value:", option.value);
                // console.log("eq?", eq(option.value, value));
                return (
                    <Voption
                        key={i}
                        value={option.value}
                        checked={eq(option.value, value)}
                        onClick={onValueChange}
                        multiple={option.multiple}
                        >
                        {option.label}
                    </Voption>
                );
            })}
        </div>
    );
});
