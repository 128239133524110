import { entities2unicode, objType, removeTags } from "../shed";
import parse, { domToReact } from "html-react-parser";

export const fetchTextFromHTMLFile = async (file) => {
    const res = await fetch(`${file.path}/${file.name}`, { cache: "no-store" })
        // return res;
        .then((response) => {
            // console.log(response);
            return response.text();
        })
        .then((response) => {
            // console.log(response);
            const text = removeTags(entities2unicode(response))
                .replace(/\s\s+/g, " ")
                .trim();
            // console.log(text);
            const res = { text };

            if (file.preserve) {
                const parser = new DOMParser();
                const html = parser.parseFromString(response, "text/html");
                // console.log(html);
                file.preserve.forEach((tag) => {
                    const nodes = [...html.querySelectorAll(tag)];
                    if (nodes.length > 0) {
                        console.log(nodes);
                        res[tag] =
                            nodes.length > 1
                                ? nodes.map((node) =>
                                      entities2unicode(
                                          removeTags(node.innerHTML)
                                      )
                                  )
                                : entities2unicode(
                                      removeTags(nodes[0].innerHTML)
                                  );
                    }
                });
            }

            return res;
        })
        .catch((err) => {
            console.log("error", err);
            return Promise.reject(err);
        });

    return res;
};

export const fetchHTML = async (pathname, attach) => {
    const res = await fetch(pathname, { cache: "no-store" })
        // return res;
        .then((response) => {
            // console.log(response);
            return response.text();
        })
        .then((response) => {
            const parser = new DOMParser();
            const html = parser.parseFromString(response, "text/html").body.innerHTML;
            
            const head = parser.parseFromString(response, "text/html").head;
            const cssLink = [...head.childNodes]
            .find(node => node.nodeName==="LINK" && node.getAttribute("rel")==="stylesheet");
            let css;
            if (cssLink) {
                const path = /\.html$/.test(pathname)
                ? pathname.split("/").slice(0, -1).join("/")
                : pathname.replace(/\/$/, "");
                // console.log("path:", path);
                css = `${path}/${cssLink.getAttribute("href")}`;
            }
            // console.log("head:", head.nodeName);
            // console.log("css:", css);
            const parsed = parse(html, {
                trim: true, replace: ({ type, name, children }) => {
                    if (type==="tag" && name==="tracknumber") {
                        return (<></>
                            // <div className="track">{domToReact(children)}</div>
                        )
                    }
                }
            });
            const res = {
                pathname,
                data: parsed,
                css
            };
            if (attach && objType(attach)==="object") {
                return {
                    ...res,
                    ...attach
                }
            }
            return res;
        })
        .catch((err) => {
            console.log("error", err);
            return Promise.reject(err);
        });

    return res;
};
