import { memo, useEffect, useState } from "react";
import { scrollIntoView } from "../../../dom";
import { MemoizedItem } from "./Item";

const calc = {
    max: arr => arr.reduce((a, b) => a > b ? a : b),
    min: arr => arr.reduce((a, b) => a < b ? a : b),
}
const itemHeights = element => [].slice.call(
    element.querySelectorAll(`.item`)
).map(item => item.offsetHeight);

export const maxItemHeight = (listElement) => calc.max(itemHeights(listElement));
export const minItemHeight = (listElement) => calc.min(itemHeights(listElement));

export const sumItemHeights = listElement => itemHeights(listElement).reduce((sum, next) => sum + next);

export const List = memo(
    ({
        id,
        list,
        selected: selectedValue,
        onItemClick,
        selectedRef,
        listRef,
        fillPockets,
        // handlePockets,
        handleScroll,
    }) => {
        // console.log("List render");

        const [element, setElement] = useState();
        const [viewport, setViewport] = useState();

        useEffect(() => {
            // console.log("useEffect with listRef", listRef);
            if (listRef.current) setViewport(listRef.current);
        }, [listRef]);

        useEffect(() => {
            // console.log("list rendered, selectedValue or selectedRef changed", selectedValue);
            if (selectedRef.current) setElement(selectedRef.current);
        }, [selectedValue, selectedRef, list]);

        useEffect(() => {
            if (element) {
                // console.log("List rendered, useEffect element, fillPockets");
                scrollIntoView(element);
                if (fillPockets) fillPockets(element);
            }
        }, [element, fillPockets]);

        return (
            <div
            id={id?id:null}
            className="list" ref={listRef}
            onScroll={
                handleScroll ? handleScroll(element) : null
            }>
                {list.map((item, i) => (
                    <MemoizedItem
                        key={i}
                        {...{ item }}
                        selected={item.value === selectedValue}
                        id={item.value === selectedValue ? selectedRef : null}
                        onClick={onItemClick}
                        onMount={
                            i === list.length - 1
                                ? () => {
                                      selectedRef.current.scrollIntoView();
                                  }
                                : null
                        }
                    />
                ))}
                {/* <Check obj={intersection && intersection.intersectionRatio} /> */}
            </div>
        );
    }
);

const Check = memo(({ obj }) => {
    // console.log("Check:", obj);

    return (
        <div style={{ position: "fixed", right: "10px", bottom: "10px" }}>
            check
        </div>
    );
});
