import { memo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const Entities = memo(({ name, selector, fetcher, mapper }) => {

    const dispatch = useDispatch();
    const entities = useSelector(selector);

    const status = useSelector(state => state[name].status);
    const error = useSelector((state) => state[name].error);

    useEffect(() => {
        if (status === "idle") {
            dispatch(fetcher());
        }
    }, [dispatch, fetcher, status]);

    let content = "";

    if (status === "loading") {
        content = "loading..."
    } else if (status === "succeeded") {
        content = entities.map(mapper);
    } else if (status === "failed") {
        content = error;
    }

    return (
        <div>{content}</div>
    )
});
