import { useEffect, useState } from "react";
import { fetchHTML } from "../../cms/fetchHTML";
import { useS } from "../../store/store";
import { useUserFromCookie } from "../common/users";
import { Scribble } from "../scribbles/Scribble";
import "./Person.scss";

export const Person = () => {

    // useUserFromCookie();

    return (
        <div className="person">
            <Scribble name="profile" />
        </div>
    )
};



export const Person0 = () => {

    useUserFromCookie();

    const [eula, set] = useState();

    const user = useS("user");
    
    useEffect(() => {
        fetchHTML("/html/eula.html")
        .then(response => {
            set(response.data);
        });
    }, []);
    
    if (!eula) return null;
    if (!user) return null;

    // console.log(intro);

    return (
        <div className="person">
            <div className="eula">
                <h1>Как тебе такое лицензионное соглашение, {user.first} {user.second}?</h1>
                {eula}
            </div>
        </div>
    )
};