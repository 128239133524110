import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { cl } from "../../shed";

const Pager = ({ content, showToc }) => {

    const { toc, root } = content;

    // console.log(toc.length);

    // const location = useLocation();

    const match = useRouteMatch(`${root}/---`);

    // console.log("match:", match);

    // console.log(location);

    // console.log(toc);

    if (toc.length < 2) return null;

    const pager = toc.slice(0).reverse().map((item, i) => (
        content.toc_text===item.text
            ? <div key={item.text} className={cl("page", "selected")}>
                { i+1 }
            </div>
            : <Link className="page" key={item.text} to={`${root}/${item.url}`}>
                { i+1 }
            </Link>
    ));

    if (showToc) {
        const toc = match
        ? <div key="TOC" className="page selected">{`\u2261`}</div>
        : (<Link key="TOC" className="page toc" to={`${root}/---`}>
            {`\u2261`}
        </Link>);
        pager.unshift(toc);
    }

    return (
        <div className="pager">{pager}</div>
    )
};

export default Pager;