import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useStore from "../store/store";
// import { entities2unicode, getRandomValue } from "../shed";

const InitSpinner = () => {
    const label = useSelector(state => state.labels.value._said.ru.ui.spinner);

    const set = useStore(state => state.update);
    // const spinner = useStore(state => state.spinner);

    const [node, setNode] = useState();

    // const [label, setLabel] = useState();
    
    /* useEffect(()=>{
        setLabel(entities2unicode(getRandomValue(labels)));
    }, [labels]); */

    const getFontSize = (str) => {
        if (!str) return "";
        const length = str.length;
        const fontSize = length < 9
            ? "15vw"
            : length < 13
                ? "13vw"
                : length < 26
                    ? "8vw"
                    : "7vw";
        return fontSize;
    };

    const style = useMemo(() => ({
        fontSize: getFontSize(label),
        zIndex: -10000,
        visibility: "hidden",
    }), [label]);

    useEffect(() => {
        if (node) set({ spinner: node });
    }, [node, set]);

    // console.log (label);

    return (<>
        <div ref={node=>setNode(node)} className="spinner" style={style}>{label}</div>
        {/* <div className="spinner">{labels.map((l, i)=>(
            <div style={{ fontSize: getFontSize(l) }} key={i}>{entities2unicode(l)}</div>
        ))}</div> */}
    </>)
};

const Spinner = ({ init }) => {

    const spinner = useStore(state => state.spinner);

    useLayoutEffect(()=>{
        if (!init && spinner) {
            spinner.style.zIndex = 1000;
            spinner.style.visibility = "visible";
        }
        return () => {
            if (!init && spinner) {
                spinner.style.zIndex = -1000;
                spinner.style.visibility = "hidden";
                /* setTimeout(()=>{
                    spinner.style.zIndex = -1000;
                    spinner.style.visibility = "hidden";
                }, 100); */
            }
        }
    }, [init, spinner]);

    return (
        init
        ? <InitSpinner />
        : <div></div>
    )


};

export default Spinner;
