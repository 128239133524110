import CDef from "./CDef";
import CCond from "./CCond";
import CSelect from "./CSelect";
import CValue from "./CValue";
import { rx } from "./rx";
import { useExpressionStore } from "./Expression";
import { useEffect } from "react";
import { cloneDeep, isEqual } from "lodash";

const Dummy = () => (<div>dummy</div>);

const components = {
    CDef, CCond, CSelect, CValue
}

export const getComponents = (template) => {

    const cname = rx.components.find(c => {
        // console.log(c.rx);
        // console.log(template);
        // console.log(`rx.test = `, c.rx.test(template));
        return c.rx.test(template);
    })?.name;

    // console.log(template);
    // console.log(cname);

    if (!cname) {
        console.log("template not recognized:", template);
    }

    return components[cname] || Dummy;
};

export const express = ({ expression, ...defs }) => {

    console.log(expression);

    const parsed = parse(expression);

    console.log(parsed);

    // console.log("---------------------\nconvert:", arr[6]);

    // console.log(convert2arr(arr[6]));

};

export const replaceCValues = (str, values) => {
    let res = str, breaker = 100;
    while(/%C\d+/.test(res) && breaker > 0) {
        const key = res.match(/%(C\d+)/)[1];
        const val = values[key];
        res = res.replace(`%${key}`, val || "");
        breaker--;
    }
    if (breaker === 0) console.log("breaker warning");
    return res;
}

export const parse = (expression) => {

    if (!/[\[\]]/.test(expression)) return { components: [], template: [expression] };

    // if (/^\[[^\[\]]+\]$/.test(expression)) return expression;

    let template = expression;

    let breaker = 0;

    const components = [];

    while (/\[[^\[\]]+\]/.test(template) && breaker < 100) {
        const arr = template.split(/(\[[^\[\]]+\])/).filter(s=>s!=="");
        const strings = [];
        // console.log(arr);
        arr.forEach(element => {
            if (/^\[[^\[\]]+\]$/.test(element)) {
                const name = `C${Object.keys(components).length+1}`;
                strings.push(`%${name}`);
                components.push({ name, template: element });
            } else {
                strings.push(element)
            }
        });
        template = strings.join("");
        breaker++;
    }

    // console.log(template);
    // console.log(components);

    const templateSplit = template.split(/(%C\d+)/).filter(s=>s!=="");

    // console.log(templateSplit);

    return { template: templateSplit, components };

};

export const useComponentValue = (expressionId, cname, value) => {

    const [slice, set] = useExpressionStore(expressionId);

    /* if (/%C\d+/.test(value)) {
        let res = value.split(/(%C\d+)/).filter(s=>s!==""); //, breaker = 0;

        console.log(res);

        const wv = res.map(item => {
            if (/^%C\d+$/.test(item)) {
                const cname = item.replace("%", "");
                console.log("cname:", cname);
                console.log(slice.values);
            }
            return (
            /^%C\d+$/.test(item)
            ? (slice.values && slice.values[item.replace("%", "")]) || "?"
            : item
        )});

        console.log(wv);
    } */

    useEffect(() => {
        if (value) {
            if (!slice.values) {
                set({ values: { [cname]: value } });
            } else {
                if (!isEqual(slice.values[cname], value)) {
                    set({ values: {...slice.values, [cname]: value } });
                }
            }
        } else {
            if (slice.values && slice.values[cname]) {
                const values = cloneDeep(slice.values);
                delete values[cname];
                set({ values });
            }
        }
    }, [cname, set, slice.values, value]);

};
