import { getRandomValue } from "./shed";

const timesNumbers = {
    "ru": [
        "ноль", "один", "два", "три",
        "четыре", "пять", "шесть", "семь",
        "восемь", "девять", "десять"
    ],
    "lv": [
        "nulle", "viena", "divas", "trīs",
        "četras", "piecas", "sešas", "septiņas",
        "astoņas", "deviņas", "desmit"
    ]
}

const declinate = (count, lang) => {
    const number = count < 11 ? timesNumbers[lang][count] : count;

    switch (lang) {
        case "ru": {
            if (count > 4 && count < 22) return `${number} раз`;
            const lastDigit = parseInt(count.toString()[count.toString().length - 1]);
            if ([0, 1, 5, 6, 7, 8, 9].includes(lastDigit)) return `${number} раз`;
            return `${number} раза`;
        }
        case "lv": {
            const lastDigit = parseInt(count.toString()[count.toString().length - 1]);
            if (lastDigit===1 && count !== 11) return `${number} reize`;
            return `${number} reizes`;
        }
        default: {
            return `${count}`;
        }
    }
};

export const times = (count, lang) => {

    const words = {
        ru: ["дважды", "трижды", "четырежды"],
        lv: ["divreiz", "trīsreiz", "četrreiz"]
    };

    if (count === 1) return null;

    if (count > 1 && count < 5) return getRandomValue(
        [words[lang][count - 2], declinate(count, lang)]
    );

    if (count > 99) return `\u00D7 ${count}`;

    return declinate(count, lang);
};
