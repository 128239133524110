import { useCallback, useEffect, useMemo, useState } from "react";
import {
    useIsMutating,
    useMutation,
    useQuery,
    useQueryClient,
} from "react-query";
import { getTable } from "../../api";
import { getAccessKey } from "../../cms/session";
import { cl, dev, generateId } from "../../shed";
import { useS, useSet } from "../../store/store";
import { useDescriptors } from "./useBubbles";

import "./Like.scss";
import { Icon } from "./reactions";
import { getUser, useUserFromCookie } from "../common/users";
import { api_action } from "../queries/queries";

const useReactions = (object_id) =>
    useQuery(["reactions", object_id], () =>
        getTable({
            table: dev() ? "dev_foam" : "public_foam",
            access_key: getAccessKey(),
            where: { object_id },
        })
    );

export const Counter = ({ type, object_id, placeholder }) => {
    const descriptors = useDescriptors();
    const reactions = useReactions(object_id);
    const [count, setCount] = useState(null);

    useEffect(() => {
        if (descriptors.status !== "success") return;
        if (reactions.status !== "success") return;
        const descriptor = descriptors.data.find((d) => d.type === type);
        const n = reactions.data.filter(
            (r) => r.bubble_id === descriptor.id
        ).length;
        setCount(n);
    }, [
        descriptors.status,
        descriptors.data,
        reactions.status,
        reactions.data,
        type,
    ]);

    return (
        !count && !placeholder ? null :
        <div className={cl("counter", type, { empty: !count })}>
            {count || placeholder}
        </div>
    );
};

export const Reaction = ({ type, object_id, object_desc, readOnly }) => {

    useUserFromCookie();

    const qc = useQueryClient();

    const user_id = useS("user.id");

    const setUser = useSet("user");

    const [state, set] = useState();

    const [loadingUser, setLoadingUser] = useState();

    const { status, data: reactions } = useReactions(object_id);

    const descriptors = useDescriptors();

    const getMutation = useCallback((action) => ({
        mutationKey: [object_id, "reaction", action],
        onSuccess: () => {
            qc.invalidateQueries(["reactions", object_id]);
            // console.log(`${type.toUpperCase()}: mutation: ${action}d`);
        },
    }), [qc, object_id]);

    const mutations = ({
        create: useMutation(api_action("insert", "foam"), getMutation("create")),
        update: useMutation(api_action("replace", "foam"), getMutation("update")),
        remove: useMutation(api_action("delete", "foam"), getMutation("remove")),
    });

    const isMutating = useIsMutating({ mutationKey: [object_id, "reaction"] });

    const descriptor = useMemo(
        () =>
            descriptors.status === "success"
                ? descriptors.data.find((d) => d.type === type)
                : {},
        [descriptors.status, descriptors.data, type]
    );

    const selected = useMemo(
        () => {
            return (
                descriptor.id &&
                status === "success" &&
                reactions.find((r) => r.user_id === user_id)?.bubble_id === descriptor.id
                )
            },
        [descriptor.id, status, reactions, user_id]
    );

    /* useEffect(() => {
        console.log(`${type.toUpperCase()}: user`, user_id);
    }, [user_id, type]); */

    useEffect(() => {
        if (!descriptor.id) return;
        if (status !== "success") return;
        const object_reaction = reactions.find((r) => r.user_id === user_id);
        if (object_reaction) {
            if (object_reaction.bubble_id === descriptor.id) {
                set({
                    operation: "remove",
                    reaction: { id: object_reaction.id },
                });
            } else {
                set({
                    operation: "update",
                    reaction: {
                        ...object_reaction,
                        bubble_id: descriptor.id,
                    },
                });
            }
        } else {
            set({
                operation: "create",
                reaction: {
                    id: generateId(),
                    object_id,
                    bubble_id: descriptor.id,
                    object_desc: object_desc || "",
                },
            });
        }
    }, [
        descriptor.id,
        status,
        type,
        reactions,
        user_id,
        object_id,
        object_desc,
    ]);

    /* useEffect(() => {
        if (!state) return;
        console.log(`${type.toUpperCase()}: state:`, state.operation, state.reaction);
    }, [state, type]); */

    /* useEffect(() => {
        console.log(
            `${type.toUpperCase()}: running mutations count:`,
            isMutating
        );
    }, [isMutating, type]); */

    const execute = useCallback((id) => {
        // console.log(`--------------------------\n${type.toUpperCase()}: EXECUTE`);

        // console.log(`user`, user_id);

        const { operation, reaction } = state;

        // console.log(`${type.toUpperCase()}: onClick: operation ${operation}, reaction:`, reaction.id);

        if (isMutating) {
            // console.log("some mutation is executing");
            return;
        }
        const bubbleReady = {
            ...reaction,
        };
        if (operation === "remove") {
            /* console.log(
                `${type.toUpperCase()}: onClick: remove reaction:`,
                bubbleReady.id
            ); */
            mutations.remove.mutate(bubbleReady);
        }
        if (operation === "update") {
            bubbleReady.updated = new Date().toISOString();
            /* console.log(
                `${type.toUpperCase()}: onClick: ready to ${operation}:`,
                bubbleReady.id
            ); */
            mutations.update.mutate(bubbleReady);
        }
        if (operation === "create") {
            bubbleReady.created = new Date().toISOString();
            bubbleReady.user_id = id;
            /* console.log(
                `${type.toUpperCase()}: onClick: ready to ${operation}:`,
                bubbleReady.id
            ); */
            mutations.create.mutate(bubbleReady);
        }
    }, [
        state,
        mutations.create, mutations.update, mutations.remove,
        // type,
        isMutating
    ]);

    const onClick = useCallback(() => {
        if (!state) return;
        if (!user_id) {
            setLoadingUser(true);
            getUser()
            .then(response => {
                setLoadingUser(false);
                setUser(response);
                console.log(response);
                // console.log(`got user ${response.id}, execute`);
                execute(response.id);
            });
            return;
        }
        // console.log(user_id);
        // console.log("user exists, execute");
        execute(user_id);
    }, [user_id, setUser, state, execute]);

    // const status = [reactions, descriptors].find(f=>f.status!=="success")?.status||"success";
    // console.log(status);

    if (!descriptor.id) return null;

    // const label  = descriptor.name.ru;

    return (
        <button
            className={cl("like", { selected })}
            {...{ onClick, disabled: readOnly || isMutating || status !== "success" || loadingUser }}
        >
            <Icon {...{ type, selected }} />
        </button>
    );
};
