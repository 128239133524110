import { memo } from "react";
import { cl, falsy2text } from "../../../shed";

export const Item = ({ item, selected, onClick, id, ...props }) => {
    // console.log(`Item ${item.key} render`);

    // useTraceUpdate({ item, selected, onClick, ...props }, `item ${item.key}`);

    return (
        <div
            className={cl("item", { selected })}
            data-selected={selected || null}
            data-value={falsy2text(item.value)}
            onClick={onClick(item.value)}
            ref={id||null}
        >
            {item.label}
        </div>
    );
};

export const MemoizedItem = memo(Item);
