import { useQuery } from "react-query";
import { getTable } from "../../api";
import { getAccessKey } from "../../cms/session";
// import { entities2unicode, say } from "../../shed";
import { Emoji } from "../foam/emoji";
import { useDescriptors } from "../foam/useBubbles";


import "./charts.scss";
import { LittleStar } from "./graphics";
import { Chart } from "./Chart";
import { Count, Name, Pos, Type } from "./List";

const types = {
    disapprove: "неодобрительный",
    approve: "одобрительный",
    offence: "экстремистский",
    irrelevant: "нерелевантный",
};

const countById = (bubbles) => {
    return bubbles.reduce(
        (obj, bubble) => ({
            ...obj,
            [bubble.bubble_id]: obj[bubble.bubble_id]
                ? obj[bubble.bubble_id] + 1
                : 1,
        }),
        {}
    );
};

const head = (<>
    <div className="pos">
        <LittleStar />
    </div>
    <div className="count">
        ОС-
        <br />
        ТАВ-
        <br />
        ЛЕН,
        <br />
        РАЗ&nbsp;
    </div>
    <div className="name">ТЕКСТУАЛ</div>
    <div className="type">ТИП</div>
</>);

const list = (entries) => (
    entries.map((entry, i) => (
        <div key={i} className="entry">
            <Pos num={i + 1} />
            <Count count={entry.count} />
            <Name name={entry.name.ru} />
            <Type type={entry.type} title={entry.title} />
        </div>
    ))
);

const Top10Comments = () => {
    const bubbles = useQuery(["charts", "foam"], () =>
        getTable({
            table: "public_foam",
            access_key: getAccessKey(),
        })
    );

    const descriptors = useDescriptors({});

    const status =
        [bubbles, descriptors].find((f) => f.status !== "success")
            ?.status || "success";

    if (status !== "success") return null;

    const comments = descriptors.data.filter(d =>
        ["disapprove", "approve", "offence", "irrelevant"].includes(d.type));

    const genericBubbles = bubbles.data.filter((bubble) =>
        comments.find((d) => d.id === bubble.bubble_id)
    );

    const ids = countById(genericBubbles);

    const entries = Object.entries(ids)
        .map(([id, count]) => {
            const descriptor = descriptors.data.find((d) => d.id === id);
            return {
                name: descriptor.name,
                count,
                type: descriptor.type,
                title: types[descriptor.type],
            };
        })
        .sort((a, b) => b.count - a.count);

    return (
        <Chart {...{ label: "Комментарии", head, entries: list(entries), legend, n: 10 }} />
    );
};

const legend = (<>
    <div>
        <div>
            <Emoji emotion="disapprove" />
            <span>{types.disapprove}</span>
        </div>
        <div>
            <Emoji emotion="approve" />
            <span>{types.approve}</span>
        </div>
    </div>
    <div>
        <div>
            <Emoji emotion="offence" />
            <span>{types.offence}</span>
        </div>
        <div>
            <Emoji emotion="irrelevant" />
            <span>{types.irrelevant}</span>
        </div>
    </div>
</>);


export default Top10Comments;
