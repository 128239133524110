import { isEqual } from "lodash";
import { memo, useCallback, useEffect } from "react";
import { useSlice, useSliceSet } from "../../../store/store";
import Button from "../../../ui/Button";
import { useLabel } from "../useLabel";
import { useData } from "./useData";


export const AddButton = memo(() => {

    // const post = useS("post");
    const status = useSlice("post", "status");
    const setStatus = useSliceSet("post", "status");
    const postData = useSlice("post", "data");
    const setPostData = useSliceSet("post", "data");
    const label = useLabel("ui.add post");

    const { status: dataStatus, data } = useData(status!=="initial");

    const onClick = useCallback(() => {
        setStatus("load");
    }, [setStatus]);

    useEffect(() => {
        if (status !== "load") return;
        if (dataStatus !== "success") return;
        if (isEqual(postData, data)) return;
        // console.log("should set postData");
        setPostData(data);
    }, [status, dataStatus, data, postData, setPostData]);

    useEffect(() => {
        if (status === "load" &&
        dataStatus === "success" &&
        isEqual(data, postData)) {
            // console.log("should set status to create");
            setStatus("create");
        }
    }, [status, setStatus, dataStatus, data, postData]);


    return (
        <Button action disabled={status!=="initial"} {...{ onClick }}>{label}</Button>
    )
});
