import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit"
import { getTable } from "../api"
import { getAccessKey } from "./session";

const worksAdapter = createEntityAdapter(
    /* {
    sortComparer: (a, b) => b.date.localeCompare(a.date),
} */
);

const initialState = worksAdapter.getInitialState({
    status: 'idle',
    error: null,
});

export const fetchAllWorks = createAsyncThunk(
    'works/fetchAllWorks',
    () => getTable({ table: "public_works", access_key: getAccessKey() }),
);

const worksSlice = createSlice({
    name: "works",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchAllWorks.pending]: state => {
            state.status = "loading";
        },
        [fetchAllWorks.fulfilled]: (state, action) => {
            state.status = "succeeded";
            worksAdapter.upsertMany(state, action.payload);
        },
        [fetchAllWorks.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        }
    }
});

export default worksSlice.reducer;

export const {
    selectById: selectWorkById,
    selectAll: selectAllWorks,
} = worksAdapter.getSelectors(state => state.works);

export const selectWorksByAuthorId = createSelector(
    [selectAllWorks, (_, authorId) => authorId],
    (works, authorId) => works.filter((work) => work.author_id === authorId)
);

