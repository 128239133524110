import { useQuery } from "react-query";
import { getJSON } from "../../../api";
import { filterTree, findBranch, findChild, findDescendants } from "../../../shed";
import { getEmotions, getSubjects } from "../subjects";

export const useData = (enabled) => {
    // console.log("useData: enabled:", enabled);
    const subjects = useQuery("subjects",
        getSubjects, { refetchOnWindowFocus: false, enabled }
    );
    const emotions = useQuery("emotions",
        getEmotions, { refetchOnWindowFocus: false, enabled }
    ); 
    const templates = useQuery("expressions",
        () => getJSON("/json/feed/expressions.json"),
        {
            enabled: emotions.isSuccess && subjects.isSuccess,
            refetchOnWindowFocus: false
        }
    );

    const status = [subjects, emotions, templates].find(q=>q.status!=="success")?.status||"success";

    if (status !== "success") return { status }

    const data = {
        subjects: subjects.data, emotions: emotions.data, templates: templates.data
    };

    return { status, data };

};



export const useFilteredData = (enabled) => {
    // console.log("useData: enabled:", enabled);
    const subjects = useQuery("subjects",
        getSubjects, { refetchOnWindowFocus: false, enabled }
    );
    const emotions = useQuery("emotions",
        getEmotions, { refetchOnWindowFocus: false, enabled }
    ); 
    const templates = useQuery("expressions",
        () => getJSON("/json/feed/expressions.json"),
        {
            enabled: emotions.isSuccess && subjects.isSuccess,
            refetchOnWindowFocus: false
        }
    );

    const status = [subjects, emotions, templates].find(q=>q.status!=="success")?.status||"success";

    if (status !== "success") return { status }

    const data = {
        subjects: subjects.data, emotions: emotions.data, templates: templates.data
    };

    const existingEntities = data.templates
    .reduce((res, template) => {
        // console.log("template subjects:", template.subjects);
        const subjects = template.subjects.map(subject => {
            if (/^=/.test(subject)) return subject.substr(1);
            const path = findBranch(data.subjects, "entity", subject, "entities");
            // console.log(`ancestors of ${subject}:`, path);
            const child = findChild(data.subjects, obj=>obj.entity===subject);
            const descendants = child && findDescendants(child.entities, "entities", "entity");
            // console.log(`descendants of ${subject}:`, descendants);
            const res = [subject];
            if (path) res.push(...path);
            if (descendants) res.push(...descendants);
            return res;
        }).flat(1);

        // console.log("subjects fit:", subjects);
        return {
            ...res,
            subjects: [...res.subjects, ...subjects.filter(s=>!res.subjects.includes(s))],
            emotions: [...res.emotions, ...template.emotions.filter(e=>!res.emotions.includes(e))]
        }

    }, { subjects: [], emotions: [] });

    // console.log("existing entities:", existingEntities);

    const dataFiltered = {
        emotions: filterTree(data.emotions, "entities", obj => existingEntities.emotions.includes(obj.entity) && !obj.disabled),
        subjects: filterTree(data.subjects, "entities", obj => existingEntities.subjects.includes(obj.entity) && !obj.disabled),
        templates: data.templates,
    };

    return { status, data: dataFiltered };

};
