import { useContext } from "react";
import { entities2unicode, getRandomValue, objType } from "../../shed";
import { useS } from "../../store/store";
import { Lang } from "./Feed";

export const useLabel = (prop="dummy", random) => {
    const lang = useContext(Lang);
    let label = useS(`labels${random?"":"._said"}.${lang}.${prop}`);
    // console.log(label);
    if (label && random) label = getRandomValue(label);
    return objType(label)==="string" ? entities2unicode(label) : label;
};
