import {
    createAsyncThunk,
    createSlice,
} from "@reduxjs/toolkit";
import { cap, entities2unicode, objType, say } from "../../shed";

const update = (state, action) => {
    const { payload } = action;
    // console.log(`update state with`, payload);
    state.status = "updated";
    state.value = {...state.value, ...payload };
};

const initialState = {
    status: "idle",
    error: null,
};

export const slices = [
    {
        name: "config",
        reducers: { update }
    },
    {
        name: "random",
        reducers: { update },
        async: false,
    },
    {
        name: "css",
        reducers: { update },
        async: false,
    },
    {
        name: "labels",
        mapper: (labels) => ({
            ...labels,
            _said: Object.entries(labels)
                .reduce((obj, [key, value]) => ({
                    ...obj,
                    [key]: Object.entries(value)
                        .reduce((obj, [key, value]) => ({
                            ...obj,
                            [key]: Object.entries(value)
                                .reduce((obj, [key, value]) => ({
                                    ...obj,
                                    [key]: entities2unicode(say(value))
                                }), {})
                        }), {})
                }), {})
        })
    }
];


const sliceFactory = (model) => {

    // console.log(model);

    const { name, mapper } = model;

    const fetcher = model.async !== false && createAsyncThunk(`${name}/fetch${cap(name)}`, async () => {
        // console.log(`fetch json for ${name}/fetch${cap(name)}`);
        const response = await fetch(`/json/${name}.json`, {
            cache: "no-store",
        });
        return response.json();
    });

    const template = {
        name,
        initialState,
        reducers: model.reducers||{}
    };

    if (fetcher) {
        template.extraReducers = {
            [fetcher.pending]: (state) => {
                state.status = "loading";
            },
            [fetcher.fulfilled]: (state, action) => {
                state.status = "succeeded";
                const payload = mapper
                        ? mapper(action.payload)
                        : action.payload;
                switch (objType(state.value)) {

                    case "object": {
                        state.value = {...state.value, ...payload }
                        break;
                    }
                    case "array": {
                        state.value = [...state.value, ...payload ]
                        break;
                    }
                    default: state.value = payload;
                }
            },
            [fetcher.rejected]: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            },
        }
    }

    const slice = createSlice(template);

    const simpleSelector = {
        [`select${cap(name)}`]: (state) => state[name].value
    }

    const selectors = model.selectors ? Object.entries(model.selectors).map(([key, fn]) => {
        const selectorName = `select${cap(name)}By${cap(key)}`;
        // console.log(selectorName);
        return {
            [selectorName]: (key) => (state) => {
                // console.log(state[name]);
                return fn(state[name].value, key)
            }
        }
    }).reduce((obj, keyVal) => ({...obj, ...keyVal}), simpleSelector) : simpleSelector;

    const reducer = slice.reducer;

    const actions = model.reducers && slice.actions;

    // console.log(actions);

    return {
        name, fetcher, reducer, selectors, actions
    }
    
};

const simpleSlices = slices.map(slice => sliceFactory(slice));

// console.log(simpleSlices);

export const simpleReducers = simpleSlices
    .reduce((reducers, slice) => ({
        ...reducers, [`${slice.name}Reducer`]: slice.reducer
    }), {});

export const simpleActions = simpleSlices
    .reduce((actions, slice) => ({
        ...actions, [slice.name]: slice.actions
    }), {});

export const simpleSelectors = simpleSlices
    .reduce((selectors, slice) => ({
        ...selectors, ...slice.selectors
    }), {});

export const simpleFetchers = simpleSlices
    .reduce((reducers, slice) => ({
        ...reducers, [slice.name]: slice.fetcher
    }), {});

// console.log(simpleSelectors);
