import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { isMobile, isMobileOnly, isMobileSafari, isSafari } from "react-device-detect";
import { useEffectOnce, useLockBodyScroll, useMount, usePrevious } from "react-use";
import smoothscroll from "smoothscroll-polyfill";
import { round } from "../../../shed";
import Button from "../../Button";
import "./Dialog.scss";
// import "../../../inobounce";
import iNoBounce from "../../../inobounce";

if (isMobileOnly && isSafari) iNoBounce.disable();

smoothscroll.polyfill();

const alignNode = ({ node, to, options }) => {

    const toRect = to
    ? to.getBoundingClientRect()
    : {
        x: 0, y: 0, left: 0, top: 0,
        width: window.innerWidth, height: window.innerHeight,
        right: window.innerWidth, bottom: window.innerHeight,
    }
    // console.log("to:", toRect);

    const nodeRect = node.getBoundingClientRect();

    const scroll = to ? { left: window.scrollX, top: window.scrollY } : { left: 0, top: 0 };

    const position = to ? "absolute" : "fixed";

    // console.log("node dialog w/h:", node, nodeRect.width, nodeRect.height);

    const getCenter = (vertical) => {
        if (vertical) {
            // console.log("to height:", toRect.height);
            // console.log("popup height:", nodeRect.height);
            // console.log("to top:", toRect.top);
            // console.log("toTop + half toHeight - half popupHeight:", (toRect.top + toRect.height / 2) - nodeRect.height / 2);
        }
        return (
            vertical
            ? round((toRect.top + toRect.height / 2) - nodeRect.height / 2 + scroll.top, 2) + "px"
            : round((toRect.left + toRect.width / 2) - nodeRect.width / 2 + scroll.left, 2) + "px"
        )
    };

    const alignment = /[^\s]+\s[^\s]+/.test(options)
    ? options.split(/\s/)
    : options;

    // console.log(options);

    const getStyle = (alignment, orientation) => {
        if (Array.isArray(alignment)) {
            return (
                alignment.slice(0, 2).map((a, i) => getStyle(a, i)).reduce((style, next) => ({
                    ...style,
                    ...next
                }), {})
            )
        };

        switch (alignment) {
            case "center": {
                if (orientation===undefined) {
                    console.log("no orientation");
                    return {
                        left: getCenter(0),
                        top: getCenter(1)
                    }
                } else {
                    return {
                        [orientation?"top":"left"]: getCenter(orientation)
                    }
                }
            }
            default: {
                if (orientation===undefined) {
                    return {
                        left: round(toRect.left + scroll.left, 2) + "px",
                        top: round(toRect.top + scroll.top, 2) + "px"
                    }
                } else {
                    const key = orientation ? "top" : "left";
                    return {
                        [key]: round(toRect[key] + scroll[key], 2) + "px"
                    }
                }
            }
        }

    };

    const style = {
        position,
        ...getStyle(alignment)
    };


    // console.log(style);

    Object.entries(style).forEach(([key, val]) => {
        node.style[key] = val;
    });

};

// const bgAccent = getComputedStyle(document.documentElement).getPropertyValue("--bgAccent");
// const bgDim = getComputedStyle(document.documentElement).getPropertyValue("--bgDim");

const Cover = ({ show, close }) => {

    const root = document.documentElement;
    const dialog = document.querySelector("div.dialog");

    const bgAccent = getComputedStyle(document.documentElement).getPropertyValue("--bgAccent");
    const bgDim = getComputedStyle(document.documentElement).getPropertyValue("--bgDim");

    useEffectOnce(() => {
        root.style.setProperty("--originalBgAccent", bgAccent);
    });

    useEffect(() => {
        if (show) {
            if (dialog) {
                dialog.style.setProperty("--bgAccent", getComputedStyle(root).getPropertyValue("--originalBgAccent"));
                root.style.setProperty("--bgAccent", bgDim);
            }
        } else {
            root.style.setProperty("--bgAccent", getComputedStyle(root).getPropertyValue("--originalBgAccent"));
        }
    }, [show, root, dialog, bgDim, bgAccent]);




    return (
        show
        ? <div onClick={close} className="cover">&nbsp;</div>
        : null
    )
};

const Popup = ({ children, id, isOpen, close, prompt }) => {

    useEffect(() => {
        if (!id.current) return;
        if (isOpen) {
            id.current.style.visibility = "visible";
        } else {
            id.current.style.visibility = "hidden";
            // setStyle(s => ({...s, visibility: "hidden"}));
        }
    }, [isOpen, id]);

    useEffect(() => {
        if (!id.current) return;
        if (!isMobile) return;
        if (isMobileOnly && isSafari) {
            if (isOpen) {
                iNoBounce.enable();
            } else {
                iNoBounce.disable();
            }
        }
    }, [isOpen, id]);

    return (
        <div ref={id} className="popup">
            <div className="popupContent">{ children }</div>
            <Button onClick={()=>{close()}}>Хорошо,&nbsp;{prompt}</Button>
        </div>
    )
};

const Prompt = memo(({ prompt, id, popup, open, close, isOpen }) => {

    const onClick = useCallback(() => {
        if (isOpen) { close(); return; }
        if (popup && id.current) {
            alignNode({ node: popup, to: !isMobile && id.current, options: isMobile ? "center" : "center top"});
            // if (isMobile) id.current.scrollIntoView({ block: "center", behavior: "smooth" });
            open();
        }
    }, [popup, open, close, id, isOpen]);

    useEffect(() => {
        if (popup && id.current) {
            alignNode({ node: popup, to: !isMobile && id.current, options: isMobile ? "center" : "center top"});
        }
        // return () => { clearAllBodyScrollLocks(); }
    }, [isOpen, popup, id]);

    useEffect(() => {
        if (!id.current) return;
        if (!isMobile) {
            id.current.scrollIntoView({ block: "start", behavior: "smooth" });
        }
    }, [id, isOpen]);

    return (
        <Button {...{ onClick, id }}>{ prompt }</Button>
    )
});

export const Dialog = ({ prompt, children }) => {

    const [isOpen, setOpen] = useState();

    // console.log(`isOpen:`, isOpen);
    // console.log(`isMobile:`, isMobile);
    // console.log(`isMobile && isOpen:`, !!(isMobile && isOpen));

    // console.log(`useLock?`, !!(isOpen && isMobile && !(isSafari && isMobileOnly)))
    useLockBodyScroll(!!(isOpen && isMobile && !(isSafari && isMobileOnly)));

    const nodePrompt = useRef();
    const nodePopup = useRef();

    const open = useCallback(() => { setOpen(true) }, []);
    const close = useCallback(() => { setOpen(false) }, []);

    const [anchor, setAnchor] = useState();

    /* useEffect(() => {

        if (isMobile && nodePopup.current) {

            const popup = nodePopup.current;

            if (isOpen) {
                // disableBodyScroll(popup);
                disableBodyScroll(popup, {
                    allowTouchMove: el => {
                        document.getElementById("msg").innerHTML = `${el.tagName} ${el.className}`;
                        return el.tagName === "DIV" && (
                            [
                                "list", "bui", "dialog",
                                "popup", "popupContent",
                                "item", "pocket"
                            ].some(className=>el.classList.contains(className))
                        );
                    }
                });
            } else {
                enableBodyScroll(popup);
            }
        }
    }, [isOpen]); */

    useEffect(() => {
        if (anchor) {
            anchor.scrollIntoView({ behavior: "smooth" });
        }
    }, [anchor, isOpen]);

    return (<div className="dialog">
        {/* <div id="msg" style={{
            position: "fixed", left: 10, top: 10, zIndex: 1003
        }}>message</div> */}
        <div style={{
            position: "relative",
            fontSize: "1px", lineHeight: "1px",
            opacity: 0, top: "-60px",
        }} ref={node=>setAnchor(node)}>anchor</div>
        { <Cover {...{ show: isOpen, close }} />}
        <Prompt {...{ prompt, id: nodePrompt, popup: nodePopup.current, open, close, isOpen }} />
        <Popup {...{ prompt, id: nodePopup, isOpen, close }}>{ children }</Popup>
    </div>)
};