import { useCallback, useState } from "react";
import { cl } from "../shed";
import "./ui.scss";

const Confirm = ({ confirm, onYes, onNo, className }) => {

    const { yes, no } = confirm;

    return (<div>
        <button onClick={onYes} className={cl(className, "confirm yes")}>
            <div>{yes}</div>
        </button>
        <button onClick={onNo} className={cl(className, "confirm no")}>
            <div>{no}</div>
        </button>
    </div>);
};

const Button = ({ children, className: addClassName, onClick=()=>{}, action, submit, small, confirm, cancel, id, ...rest }) => {

    const [confirmation, set] = useState();

    const handleClick = useCallback((e) => {
        if (confirm) {
            set(true);
        } else { onClick(e); }
    }, [confirm, onClick]);

    const handleYes = useCallback((e) => {
        onClick(e);
        set(false);
    }, [onClick]);
    const handleNo = useCallback(() => {
        set(false);
    }, []);

    const className = cl("button", {action, submit, cancel, small}, addClassName );

    return (
        confirmation ?
        <Confirm {...{ confirm, onYes: handleYes, onNo: handleNo, className }} /> :
        <button ref={id?id:null} {...rest} onClick={handleClick} className={className}>
            <div>{children}</div>
        </button>
    )
};

export default Button;