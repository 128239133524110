import { MD5 } from "crypto-js";
import { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useIsMutating, useMutation, useQueryClient } from "react-query";
import { generateId } from "../../../shed";
import { useS, useSet, useSlice, useSliceSet } from "../../../store/store";
import Button from "../../../ui/Button";
import { getUser } from "../../common/users";
import { api_action } from "../../queries/queries";
import { Lang } from "../Feed";
import { useLabel } from "../useLabel";

export const PostButton = memo(() => {
    const status = useSlice("post", "status");

    const label = useLabel("ui.post");

    // console.log("PostButton: post status:", status);

    return (
        status === "ready"
        ? <PostButtonAction />
        : <Button disabled submit>{label}</Button>
    )

});

const getPersonColumns = (person) => {
    const {
        first, second,
        first_entity, second_entity,
        params,
    } = person;
    const declension = person.entity.values[0].declension;
    return {
        firstname_cyr: first, name_cyr: second,
        first, second, first_entity, second_entity,
        params,
        declension,
    }
};

const PostButtonAction = memo(() => {
    // useUserFromCookie();
    const post = useS("post");
    const expression = useS(post.expressionId);
    const label = useLabel("ui.post");
    // const user = useS("user");
    const lang = useContext(Lang);

    const qc = useQueryClient();

    const [posting, setPosting] = useState();

    const getMutation = useCallback((action) => ({
        mutationKey: ["post", post.id, action],
        onSuccess: () => {
            // console.log(`insert post onSuccess`);
            qc.invalidateQueries("posts");
            qc.invalidateQueries("ein_user");
            // qc.invalidateQueries(["posts", user.id]);
            post.reset({ id: generateId(), status: "initial" });
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            // setPosting(false);
        },
    }), [qc, post]);

    const mutation = useMutation(api_action("insert", "posts"), getMutation("insert"));

    const isMutating = useIsMutating({ mutationKey: ["post", post.id] });

    const insertPost = useCallback(user => {
        if (isMutating) return;
        if (!user) return;

        const expression_state = {
            defs: expression.defs,
            values: expression.values,
            emotion: post.emotion.entity,
            subject: post.subject.entity,
        };
        // console.log(`PostButton: expression state to save:`, expression_state);

        const postReady = {
            id: post.id,
            user_id: user.id,
            expression_id: post.expressionId,
            expression_state: JSON.stringify(expression_state),
            text: expression.text,
            text_md5: MD5(expression.text).toString(),
            lang,
            created: new Date().toISOString(),
        };

        mutation.mutate(postReady);

    }, [isMutating, post, expression, mutation, lang]);

    const onClick = useCallback(() => {
        // console.log("click!");
        setPosting(true);
        getUser(getPersonColumns(post.person))
        .then(user => {
            insertPost(user);
        });
    }, [post, insertPost]);


    const enabled = (
        post.person && expression && !posting
    )

    return (
        <Button {...{ onClick }} submit action disabled={!enabled}>{label}</Button>
    )
});
