import { useQueries, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { getJSON, getTable } from "../../api";
import { useS } from "../../App";
import { getAccessKey } from "../../cms/session";
import { dev, entities2unicode } from "../../shed";
import { groupByCount } from "./Bubbles";

export const getBubbles0 = () => { throw new Error("getBubbles called"); }

export const getBubbles = ({ object_id, descriptors }) => () =>
    getTable({
        table: dev() ? "dev_foam" : "public_foam",
        where: { object_id },
        access_key: getAccessKey(),
    }).then((response) => {
        // console.log("bubbles for object_id", object_id);
        // console.log(response);
        const bubbles = response
            // .filter((bubble) => bubble.lang === lang)
            .map((bubble) => {
                const descriptor = descriptors.find(
                    (b) => b.id === bubble.bubble_id
                );
                return {
                    ...bubble,
                    type: descriptor.type,
                    label: descriptor.name,
                    user: descriptor.user
                };
            });

    const grouped = groupByCount(bubbles);

    // console.log(grouped);

    return grouped.sort((a, b) => {
        // console.log(a.created);
        // console.log(b.created);
        // console.log(b.created - a.created);
        return b.created - a.created;
    });
});

export const useDescriptors = (props) => {

    const { text } = props||{};

    const queries = [
        {
            queryKey: "descriptors", queryFn: () => getJSON("/json/bubbles.json"),
            refetchOnWindowFocus: false
        }
    ];

    if (text) queries.unshift({
        queryKey: ["specDescriptors", text],
        queryFn: () => getJSON("/json/bubbles_spec.json").then(r=>r[text]||[]),
        refetchOnWindowFocus: false
    });

    const res = useQueries(queries);

    const status = res.find(q=>q.status !== "success")?.status || "success";

    const data = status==="success"
    ? res.map(q=>q.data).flat(1)
    : [];

    return { status, data };
}; 

export const useBubbles = ({ object_id, text }) => {

    // console.log(text);

    const { status, data: descriptors } = useDescriptors({ text });

    const res = useQuery(
        ["bubbles", object_id],
        getBubbles({
            object_id,
            descriptors
        }),
        {
            enabled: status === "success"
        }
    );

    // console.log(descriptors);

    // console.log(res);

    return res;
};
