import { Children, cloneElement, createContext, createElement, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { animated, useSpring, config } from "react-spring";
import { camelCase, getRandomInt, shuffleArray } from "../../shed";
// import ReactDOM from "react-dom";
import parse, { domToReact } from "html-react-parser";
// import { render } from "react-dom";

import "./Blob.scss";
import "./test.scss";
import { animatedTag } from "./ani.tags";
import { clean, getConfigFromAttr, getStyles } from "./spring";

export const BlobContext = createContext();

let id;

export const Blob = memo(({ children, name: propName, ...rest }) => {

    const { name, dataAttr } = useMemo(() => {
        const name = (
            propName ||
            (Object.keys(rest).find(key=>rest[key]===true) || "blob")
        );
        const dataAttr = "data-" + name;
        return { name, dataAttr }
    }, [propName, rest]);

    // console.log(dataAttr);

    const ref = useRef();

    const [ghost, setGhost] = useState(null);

    const w2 = useCallback((callback) => (...args) => {
        // console.log("w");
        const source = ref.current;
        const nodes = [...source.querySelectorAll(`[${dataAttr}]`)];
        // console.log(nodes.map(node=>node.className));

        let springs = (() => {
            const res = {
                ...nodes.reduce((styles, node) => {
                    const [id, what] = node
                    .getAttribute(dataAttr)
                    .split(":");
                    return {
                        ...styles,
                        [id]: {
                            from: getStyles(node, what)
                        }
                    }
                }, {})
            };
            // console.log(res);
            return res;
        })();

        // const element = cloneElement(div);

        const element = parse(`<div id="ghost" class="ghost" style="z-index: -1">${source.innerHTML}</div>`);

        setGhost(element);

        const ghostNode = document.getElementById("ghost");

        // console.log(document.getElementById("ghost"));
        if (ghostNode) document.getElementById("ghost").style.visibility = "visible";

        source.style.visibility = "hidden";

        callback(...args)
        .then(() => {

            // console.log("then");

            const source = ref.current;
            const nodes = [...source.querySelectorAll(`[${dataAttr}]`)];
            // console.log(nodes.map(node=>node.className));

            // console.log("then nodes:", nodes);

            springs = (() => {
                // console.log(prev);
                const res = {
                    ...springs,
                    ...nodes.reduce((styles, node) => {
                        const [id, what] = node.getAttribute(dataAttr).split(":");
                        const subres = {
                            ...styles,
                            [id]: clean({
                                ...springs[id],
                                to: getStyles(node, what)
                            })
                        };
                        // console.log(subres[id]);
                        return subres;
                    }, {})
                };
                // console.log(res);
                return res;
            })();

            // console.log(springs);
            // source.style.zIndex = -1;

            const restore = () => {
                source.style.visibility = "visible";
                document.getElementById("ghost").style.visibility = "hidden";
            };

            if (Object.keys(springs).length===0) {
                // console.log("no springs");
                restore();
                return;
            }

            if (!Object.values(springs).some(value=>!!value)) {
                // console.log("no springs");
                restore();
                return;
            }

            const element = parse(`<div id="ghost" class="ghost" style="z-index: -1">${source.innerHTML}</div>`, {
                replace: ({ type, name, children, attribs }) => {
                    if (attribs?.hasOwnProperty(dataAttr)) {
                        const id = attribs[dataAttr].split(":")[0];
                        if (!springs[id]) {
                            // console.log("no spring");
                            return;
                        }

                        const attrs = Object.entries(attribs)
                        .reduce((obj, [key, val])=>{

                            if (key===dataAttr) return obj;

                            // console.log("key", key, "camelCase:", camelCase(key));
                            
                            return ({
                                ...obj,
                                [camelCase(key)]: val,
                            })
                        }, {});

                        // console.log(attrs["className"]);

                        /* if (name==="svg") {
                            console.log("SVG attrs:", attrs);
                        } */

                        const spring = {
                            ...springs[id],
                            config: getConfigFromAttr(attribs[dataAttr]),
                            onRest: restore,
                        };
                        // console.log(spring);

                        const A = () => {
                            const style = useSpring(spring);
                            const E = animatedTag[name];
                            return (
                                <E {...attrs} style={style}>{domToReact(children)}</E>
                            )
                        };

                        return <A />;

                    }
                }
            });

            setGhost(element);

            // source.style.visibility = "visible";

        });
    }, [dataAttr]);

    return (
        <BlobContext.Provider value={{ w: w2, name }}>
            <div className="blob">
                <div className="prince" style={{zIndex: 1}} ref={ref}>{children}</div>
                { ghost }
            </div>
        </BlobContext.Provider>
    )
});


const Component = () => {

    const [state, set] = useState("State A");
    const { w } = useContext(BlobContext);

    const onClick = useCallback(async () => {
        set(s => s==="State A"?"State B":"State A");
    }, []);

    return (<>
        <input value="INPUT!" readOnly />
        <div>div here and <span>span here</span></div>
        <button className={state==="State A"?"stateA":"stateB"} data-blob="button1:main" onClick={w(onClick)}>{state}</button>
        <div className="test">
            <div className={state==="State A"?"stateB":"stateA"} data-blob="div1:main">
                Some div
            </div>
        </div>
        </>
    );
};

const Blobtest = () => {

    return (
        <Blob blob>
            <Component />
        </Blob>
    )
};

export default Blobtest;
