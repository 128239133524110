import { Redirect, Route, Switch } from "react-router-dom";
import Dir from "./cms/Dir";
import SyncTexts from "./cms/SyncTexts";
import Tests from "./cms/tests";
import Blobtest from "./features/blob/Blob";
import Reader from "./features/reader/Reader";
import Texts from "./features/texts/Texts";
import Ein from "./features/authors/ein";
import Romochka from "./features/authors/romochka";
import Lesen from "./features/lesen/Lesen";
import Author from "./features/authors/author";
import Top10Comments from "./features/stats/top10comments";
import Feed from "./features/feed/Feed";
import Top10Zhop from "./features/stats/top10zhop";
import { Create } from "./features/scribbles/create";
import { User } from "./cms/User";
import { Person } from "./features/user/Person";


const Routes = () => (
    <Switch>
        <Route exact path="/">
            <Redirect to="/lesen" />
        </Route>
        <Route exact path="/sync">
            <SyncTexts />
        </Route>
        <Route exact path="/dir">
            <Dir />
        </Route>
        <Route exact path="/test">
            <Tests />
        </Route>
        <Route exact path="/bt">
            <Blobtest />
        </Route>
        <Route exact path="/text">
            <Redirect to="/lesen" />
        </Route>
        <Route exact path="/ein">
            <Author nick="ein" />
        </Route>
        <Route exact path="/romochka">
            <Author nick="romochka" />
        </Route>
        <Route path="/lesen">
            <Lesen root="/lesen" tocFile="/json/lesen.json" />
        </Route>
        <Route path="/schauspiel">
            <Feed />
        </Route>
        <Route path="/top">
            <Top10Comments />
        </Route>
        <Route path="/topzhop">
            <Top10Zhop />
        </Route>
        <Route path="/scribbles">
            <Create />
        </Route>
        <Route exact path="/cms/user">
            <User />
        </Route>
        <Route exact path="/person">
            <Person />
        </Route>
        <Route>
            <div>404</div>
        </Route>
    </Switch>
);

export default Routes;
