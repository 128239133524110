import { Link } from "react-router-dom";
import { useUserFromCookie } from "../features/common/users";
import { useS } from "../store/store";

const User = () => {
    useUserFromCookie();

    const user = useS("user");

    if (!user) return null;

    const { first, second } = user;

    if (!(first && second)) return null;

    return (
        <div className="user">
            <Link to="/person">
                <div className="first">{first}</div>&nbsp;
                <div className="second">{second}</div>
            </Link>
        </div>
    )
};

export const Schauspiel = () => {

    return (
        <div className="navbar">

            <User />

        </div>
    )
};
