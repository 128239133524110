import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./Select.scss";
import { List, maxItemHeight } from "./List";
import { MemoizedItem } from "./Item";
import { cl } from "../../../shed";
import { getRect, scrollIntoView } from "../../../dom";
import { useDeepCompareEffect } from "react-use";


const Nothing = ({ nothing, selected: selectedValue, onClick }) => {
    return (
        <div
            className={cl("nothing", nothing.value === selectedValue && "selected")}
        >
            <MemoizedItem
                item={nothing}
                selected={nothing.value === selectedValue}
                onClick={onClick}
            />
        </div>
    );
};

const Pocket = ({ placeholder, id, top, bottom }) => {
    return (
        <div ref={id} className={cl("pocket", { top, bottom })}>
            <div className="placeholder">{placeholder}</div>
        </div>
    );
};

const Select = ({ data, handleChange, className }) => {
    // console.log("Select render");

    const { list, title, selected: defaultSelected, nothing } = data;

    const [selected, setSelected] = useState(defaultSelected);

    const selectedRef = useRef();
    const listRef = useRef();
    const pocketTopRef = useRef();
    const pocketBottomRef = useRef();

    // const req = useRef();

    const handleItemClick = useCallback(
        (value) => () => {
            // console.log(`${value} clicked`);
            setSelected(value);
        },
        []
    );

    const fillPockets = useCallback((element) => {
        // console.log("fill pockets with", element);

        const pockets = [pocketTopRef.current, pocketBottomRef.current];

        // console.log("Pockets:", pockets);

        const { width, height } = getRect(element);
        // console.log(width);

        pockets.forEach((pocket, i) => {
            const clone = element.cloneNode(true);
            clone.style.width = `${width.toFixed(2)}px`;
            clone.addEventListener("click", (e) => {
                e.preventDefault();
                scrollIntoView(element);
            });
            clone.addEventListener("select", () => {
                console.log("onSelect fired");
            } );
            const rel = i === 0 ? "bottom" : "top";
            clone.style[rel] = `${-height}px`;
            // console.log(pocket.childNodes.length);
            if (pocket.childNodes.length > 1) {
                const nodeToReplace = pocket.childNodes[1];
                nodeToReplace.replaceWith(clone);
            } else {
                pocket.appendChild(clone);
            }
            // console.log(nodeToReplace);
            // replaceNodeOfSameClass(clone, pocket);
        });

    }, []);

    let ticking = useRef(false);

    const gap = 6;

    const handleScroll = useCallback((element) => ({ target }) => {
        if (!(pocketTopRef.current && pocketBottomRef.current &&
            pocketTopRef.current.childNodes.length > 1 &&
            pocketBottomRef.current.childNodes.length > 1)) {
                return;
        }

        if (!ticking.current) {
            window.requestAnimationFrame(() => {

                const pockets = [pocketTopRef.current, pocketBottomRef.current];
                const clones = [pockets[0].childNodes[1], pockets[1].childNodes[1]];

                pockets.forEach(pocket => {
                    if (pocket.offsetHeight < element.offsetHeight + gap) {
                        pocket.style.height = element.offsetHeight + gap + "px";
                    }
                });

                // console.log("element offsetWidth:", element.offsetWidth);
                clones.forEach(clone => {
                    // console.log("clone offsetWidth:", clone.offsetWidth);   
                    
                    if (clone.offsetWidth !== element.offsetWidth) {
                        // console.log("set width");
                        clone.style.width = element.offsetWidth + "px";
                    }
                });
                // console.log("list scroll", element, target);
                
                // console.log(pockets, clones);
                const selectedTop = element.offsetTop;
                const selectedHeight = element.offsetHeight;
                const listTop = target.offsetTop;
                const listHeight = target.offsetHeight;
                const scrollTop = target.scrollTop;

                // console.log(selectedTop);
                // console.log(listTop);
                // console.log(scrollTop);

                const dy = selectedTop - listTop - scrollTop;

                // console.log(dy);

                if (dy < 0 && Math.abs(dy) <= selectedHeight + gap) {
                    clones[0].style.bottom = `${-(selectedHeight + dy)}px`;
                }

                if (dy >= 0) {
                    clones[0].style.bottom = `${-selectedHeight-gap}px`;
                }

                if (dy < -selectedHeight - gap) {
                    clones[0].style.bottom = `${gap}px`;
                }

                if (dy > listHeight - selectedHeight && dy < listHeight + gap) {
                    clones[1].style.top = `${-(listHeight - dy)}px`;
                }

                if (dy >= listHeight + 6) {
                    clones[1].style.top = `${gap}px`;
                }

                if (dy <= listHeight - selectedHeight) {
                    clones[1].style.top = `${-selectedHeight-gap}px`;
                }

                

                ticking.current = false;
            });
            ticking.current = true;
        }
    }, []);

    useEffect(() => {
        if (listRef.current) {
            // console.log("List selectedRef:", selectedRef);

            // selectedRef.current.scrollIntoView();
            const t = setTimeout(() => {
                if (selectedRef.current) scrollIntoView(selectedRef.current);

                if (pocketTopRef.current && pocketBottomRef.current) {
                    // console.log("find max item height");
                    const height = maxItemHeight(listRef.current);
                    // console.log(`Max height:`, height);
                    pocketTopRef.current.style.minHeight = `${height + gap}px`;
                    pocketBottomRef.current.style.minHeight = `${height + gap}px`;
                }
            }, 0);
            return () => clearTimeout(t);
        }
    }, []);

    /* useDeepCompareEffect(() => {
        console.log(`BuiSelect: list changed to:`, list);
        return () => {
            console.log(`%cSelect unmount`, "color: yellow");
        }
    }, [list]) */

    useEffect(() => {
        handleChange(selected);
    }, [selected, handleChange]);

    return (
        <div className={cl("bui pocket select", className)}>
            <Pocket placeholder={title || `\xa0`} top id={pocketTopRef} />
            <List
                onItemClick={handleItemClick}
                {...{ list, selected, selectedRef, listRef, fillPockets, handleScroll }}
            />
            <Pocket placeholder={`\xa0`} bottom id={pocketBottomRef} />
            {nothing && (
                <Nothing {...{ nothing, selected, onClick: handleItemClick }} />
            )}
        </div>
    );
};

const Container = ({ data, handleChange, className }) => {
    // console.log("Container render");

    return <Select {...{ data, handleChange, className }} />;
};

export default Container;
